import { MyAccountMetadata } from '@/components/layouts/account-layout/type'
import { ProductionFilterState, SortFilterNames } from '@/components/pages/production/components/context/types'
import type { ListingsStateType } from '@/context/listings/types'
import { ComposedListing, PageType, Performer, Production, UserCurrency, UserData, Venue } from '@/types/app-types'
import type {
    DisplayCurrencyEvent,
    ErrorEvent,
    GeolocationEvent,
    HomePageEvent,
    HomePageGetTicketsEvent,
    SelectGeolocationEvent,
    Utm,
} from '@/utils/analytics/types'
import { CheckoutEvent } from '@/utils/analytics/types'
import { getPriceGroupId, getUserLocationCookieValue } from '@/utils/cookies'
import { daysUntil } from '@/utils/dates'
import { getMainPerformer } from '@/utils/production'

import { safelyTrack } from '../helpers'

import { createGAItem, createServerInfo, createUserInfo } from './common'
import {
    BRAZE_EVENT_NAME,
    GEOLOCATION_EVENT_NAME,
    ERROR_EVENT_NAME,
    FILTER_EVENT_NAME,
    HOME_PAGE_INTERACTION,
    HOME_PAGE_INTERACTION_CONTENT_TYPE,
    LANDING_PAGE_VIEW_EVENT_NAME,
    MARKETING_VISIT_EVENT_NAME,
    OPTIMIZELY_EVENT_NAME,
    SEARCH_EVENT_NAME,
    VIEW_PROMOTION_EVENT_NAME,
    DISPLAY_CURRENCY_EVENT_NAME,
} from './constants'
import {
    createCheckoutEvent,
    createCheckoutPurchaseAuthorizationEvent,
    createCheckoutPurchaseAuthorizationErrorEvent,
    createECommerceItem,
    createEmptyECommerceEvent,
    createSelectFocusSearchSuggestionEvent,
    createSelectListingEvent,
    createSelectProductionEvent,
    createSelectSearchSuggestionEvent,
    createViewProductionEvent,
    createViewProductionListEvent,
} from './ecommerce'
import type {
    CheckoutECommerceEvent,
    DataLayerEvent,
    FilterEvent,
    GAItem,
    GAOptimizelyExperimentEvent,
    LandingPageViewEvent,
    PageViewEvent,
    PerformerItem,
    ProductionItem,
    PurchaseAuthorizationEvent,
    VenueItem,
    ViewPromotionEvent,
} from './types'

const push = (event: DataLayerEvent): void => {
    if (typeof window !== undefined) {
        window?.dataLayer?.push(event)
    }
}

export const initializeGA4 = (): void => {
    // use our own data layer variable so we don't have to check winow all of the time
    if (typeof window !== undefined) {
        window.dataLayer = window.dataLayer || []
    }
    // ensure that the information is emitted before GTM is loaded so we can have the variables available for initialization
    push({
        server: createServerInfo(),
        user: createUserInfo(),
    })
}

export const trackUserLocation = (): void => {
    const userLocation = getUserLocationCookieValue()
    push({
        user: { ...createUserInfo(), user_location: userLocation },
    })
}

export const trackDisplayCurrency = (userCurrency: UserCurrency): void => {
    push({
        user: { ...createUserInfo(), display_currency: userCurrency },
    })
}

export const createGAUtm = (userData: UserData): Utm => {
    return {
        medium: userData.utmMedium,
        source: userData.utmSource,
        campaign: userData.utmCampaign,
        term: userData.utmTerm,
        adgroupId: userData.utmAdgroup,
        target: userData.utmTarget,
        keywordId: /kwd-(\d+)/.exec(userData.utmTarget || '')?.[1],
        content: userData.utmContent,
    }
}

export const trackMarketingVisit = (userData: UserData): void => {
    push({
        event: MARKETING_VISIT_EVENT_NAME,
        utm: createGAUtm(userData),
    })
}

export const createOptimizelyExperimentEvent = (experiment: string, variation: string): GAOptimizelyExperimentEvent => {
    return {
        event: OPTIMIZELY_EVENT_NAME,
        experiment: experiment,
        variation: variation,
    }
}

export const createPageViewObject = (
    pageType: string,
    pageData?: PerformerItem | ProductionItem | VenueItem,
): PageViewEvent => {
    return {
        event: 'pageview',
        url_path: window.location.pathname,
        page_type: pageType,
        page: pageData,
    }
}

export const createLandingPageViewObject = (): LandingPageViewEvent => {
    return {
        event: LANDING_PAGE_VIEW_EVENT_NAME,
        url_path: window.location.pathname,
    }
}

export const createCheckoutPageViewObject = (
    pageType: string,
    production: Production,
    urlPath?: string,
    item_list_name: string | null = null,
): PageViewEvent => {
    return {
        event: 'pageview',
        url_path: window.location.pathname + `/${urlPath}`,
        page_type: pageType,
        page: {
            ...createProductionItem(production),
            ...createGAItem({ production, item_list_name }),
        },
    }
}

export const createPerformerItem = (performers: Performer[] = []): PerformerItem => {
    const performerCategory =
        performers.length > 1 ? `${performers[0]?.name} vs. ${performers[1]?.name}` : performers[0]?.name
    return {
        performer: performers[0]?.name,
        ...(performers.length > 1 && { performer2: performers[1]?.name }),
        performer_id: performers[0]?.id.toString(),
        ...(performers.length > 1 && { performer_id_2: performers[1].id.toString() }),
        item_category: performers[0]?.category.name.endsWith('s')
            ? performers[0]?.category.name.toUpperCase().slice(0, -1)
            : performers[0]?.category.name.toUpperCase(),
        item_category2: performers[0]?.category.subCategories?.[0].name,
        item_category3: performerCategory,
    }
}

export const createPerformerPageViewObject = (pageType: string, performers: Performer[]): PageViewEvent => {
    return createPageViewObject(pageType, createPerformerItem(performers))
}

export const createProductionItem = (production: Production): ProductionItem => {
    const distance = production.distance ? Math.round(production.distance / 25) * 25 : undefined
    const performers = [getMainPerformer(production)]

    const performersData = createPerformerItem(performers)

    return {
        ...performersData,
        production: production.name,
        production_id: production.id.toString(),
        production_city: production.venue.city,
        production_state: production.venue.state,
        production_datetime: production.utcDate,
        distance_to_production: distance,
        total_inventory_available: production.listingCount,
        days_until_production: daysUntil(production.utcDate),
        price_group: getPriceGroupId(),
    }
}

export const createProductionPageViewObject = (
    page_type: string,
    production: Production,
    item_list_name: string | null = null,
): PageViewEvent => {
    return createPageViewObject(page_type, {
        ...createProductionItem(production),
        ...createGAItem({ production, item_list_name }),
    })
}

const createGAPerformerItem = ({ performer }: { performer: Performer }): GAItem => {
    return {
        item_name: performer.name,
        item_id: performer.id.toString(),
        item_list_name: 'Performer',
        item_type: 'Performer',
        item_category: performer.category.name.endsWith('s')
            ? performer.category.name.toUpperCase().slice(0, -1)
            : performer.category.name.toUpperCase(),
        item_category2: performer.category.subCategories?.find((c) => c)?.name,
        item_category3: performer.name,
    }
}

const createViewPromotionObject = ({
    item,
    id,
    creative,
    name,
    position,
}: {
    item: {
        production?: Production
        performer: Performer
    }
    id?: string
    creative?: string
    name?: string
    position?: string
}): ViewPromotionEvent => {
    const { performer, production } = item

    const eventItem = production
        ? createECommerceItem({ production, item_list_name: 'Production' })
        : createGAPerformerItem({ performer })

    return {
        event: VIEW_PROMOTION_EVENT_NAME,
        creative_name: creative,
        items: [eventItem],
        location_id: position,
        promotion_id: id,
        promotion_name: name,
    }
}

export const trackPageView = (pageType: string): void => {
    push(createPageViewObject(pageType))
}

export const trackPerformerPageView = (pageType: string, performers: Performer[]): void => {
    push(createPerformerPageViewObject(pageType, performers))
}

export const trackVenuePageView = (venue: Venue): void => {
    const venueItemPayload: VenueItem = { venue_id: `${venue.id}`, venue_name: venue.name }
    push(createPageViewObject(PageType.Venue, venueItemPayload))
}

export const trackProductionPageView = (production: Production): void => {
    push(createProductionPageViewObject('Production', production))
}

export const trackCheckoutPageView = (pageType: string, production: Production, urlPath: string): void => {
    push(createCheckoutPageViewObject(pageType, production, urlPath))
}

export const trackLandingPageView = (): void => {
    push(createLandingPageViewObject())
}

export const trackHomePageEvent = (eventInfo: HomePageEvent | HomePageGetTicketsEvent): void => {
    push({
        event: HOME_PAGE_INTERACTION,
        content_type: HOME_PAGE_INTERACTION_CONTENT_TYPE,
        ...eventInfo,
    })
}

export const trackMyAccountPageEvent = (eventInfo: MyAccountMetadata): void => {
    push({
        event: 'pageview',
        ...eventInfo,
    })
}

export const trackSearchEvent = (pageLocation?: string): void => {
    push({
        event: SEARCH_EVENT_NAME,
        page_location: pageLocation,
    })
}

export const trackFilterEvent = (eventInfo: FilterEvent): void => {
    push({ event: FILTER_EVENT_NAME, ...eventInfo })
}

export const trackGeolocationEvent = (info: GeolocationEvent | SelectGeolocationEvent): void => {
    push({
        event: GEOLOCATION_EVENT_NAME,
        page_type: PageType.Home,
        ...info,
    })
}

export const trackDisplayCurrencyEvent = (info: DisplayCurrencyEvent): void => {
    push({
        event: DISPLAY_CURRENCY_EVENT_NAME,
        ...info,
    })
}

export const trackProductionFilter = (filterState: ProductionFilterState, listingsState: ListingsStateType): void => {
    trackFilterEvent({
        page_type: 'production',
        filter: filterState,
        sort_by: SortFilterNames[filterState?.sortFilterState.name].toLowerCase(),
        item_count: listingsState?.data?.tickets.length,
    })
}

export const trackErrorEvent = (eventInfo: ErrorEvent): void => {
    push({
        event: ERROR_EVENT_NAME,
        ...eventInfo,
    })
}

export const trackViewPromotion = ({
    item,
    id,
    creative,
    name,
    position,
}: {
    item: {
        production?: Production
        performer: Performer
    }
    id?: string
    creative?: string
    name?: string
    position?: string
}): void => {
    push(createViewPromotionObject({ item, id, creative, name, position }))
}

export const trackViewProductionList = ({
    item_list_name = null,
    productions,
}: {
    item_list_name: string | null
    productions: Production[]
}): void => {
    push(createEmptyECommerceEvent())
    push(createViewProductionListEvent({ item_list_name, productions }))
}

export const trackSelectProduction = ({
    item_list_name = null,
    index,
    production,
}: {
    item_list_name: string | null
    index: number
    production: Production
}): void => {
    push(createEmptyECommerceEvent())
    push(createSelectProductionEvent({ item_list_name, index, productions: [production] }))
}

export const trackSelectSearchSuggestion = ({
    name,
    pageType,
    category,
    pageLocation,
    index,
}: {
    name: string
    pageType: string
    category?: string
    pageLocation: string
    index: number
}): void => {
    push(createEmptyECommerceEvent())
    push(createSelectSearchSuggestionEvent({ name, pageType, category, pageLocation, index }))
}

export const trackSelectFocusSearchSuggestion = ({
    title,
    focusGroup,
    pageLocation,
    index,
}: {
    title: string
    focusGroup: string
    pageLocation: string
    index: number
}): void => {
    push(createEmptyECommerceEvent())
    push(createSelectFocusSearchSuggestionEvent({ title, focusGroup, pageLocation, index }))
}

export const trackViewProduction = ({
    item_list_name = null,
    production,
}: {
    production: Production
    item_list_name: string | null
}): void => {
    push(createEmptyECommerceEvent())
    push(createViewProductionEvent({ production, item_list_name }))
}

export const trackSelectListing = (production: Production, listing: ComposedListing, quantity?: number): void => {
    push(createProductionPageViewObject('Listing Details', production))
    push(createEmptyECommerceEvent())
    push(createSelectListingEvent(production, listing, quantity))
}

export const trackCheckoutEvent = (eventInfo: CheckoutEvent): CheckoutECommerceEvent => {
    const checkoutEvent = createCheckoutEvent(eventInfo)
    push(checkoutEvent)
    return checkoutEvent
}

export const trackPurchaseAuthorizationEvent = (eventInfo: any): CheckoutECommerceEvent => {
    const purchaseAuthEvent = createCheckoutPurchaseAuthorizationEvent(eventInfo)
    push(purchaseAuthEvent)
    return purchaseAuthEvent
}

export const trackPurchaseErrorEvent = (error_code: number | string): PurchaseAuthorizationEvent => {
    const purchaseAuthErrorEvent = createCheckoutPurchaseAuthorizationErrorEvent(error_code)
    push(purchaseAuthErrorEvent)
    return purchaseAuthErrorEvent
}

export const trackOptimizelyExperiment = (experiment: string, variation: string): void => {
    push(createOptimizelyExperimentEvent(experiment, variation))
}

export const trackBrazeEvent = safelyTrack((triggerId: string, messageId: string): void => {
    push({
        event: BRAZE_EVENT_NAME,
        trigger_id: triggerId,
        message_id: messageId,
    })
})
