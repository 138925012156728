import type { Level2MenuItem } from '@/context/performers-navigation/types'

import cities from './cities'
import mlb from './mlb'
import nba from './nba'
import ncaab from './ncaab'
import ncaaf from './ncaaf'
import nfl from './nfl'
import nhl from './nhl'
import other from './other'
import trending from './trending'

const sports: Level2MenuItem[] = [
    {
        level: 2,
        url: '/sports',
        label: 'Trending',
        id: 'trending',
        footerLink: {
            url: '/sports',
            label: 'View All Sports',
        },
        sublinks: trending,
    },
    {
        level: 2,
        url: '/mlb-baseball',
        label: 'MLB',
        id: 'mlb',
        footerLink: {
            url: '/mlb-baseball',
            label: 'View All MLB',
        },
        sublinks: mlb,
    },
    {
        level: 2,
        url: '/nba-basketball',
        label: 'NBA',
        footerLink: {
            url: '/nba-basketball',
            label: 'View All NBA',
        },
        sublinks: nba,
    },
    {
        level: 2,
        url: '/ncaab',
        label: 'NCAA BB',
        footerLink: {
            url: '/ncaab',
            label: 'View All NCAA Basketball',
        },
        sublinks: ncaab,
    },
    {
        level: 2,
        url: '/ncaaf',
        label: 'NCAA FB',
        footerLink: {
            url: '/ncaaf',
            label: 'View All NCAA Football',
        },
        sublinks: ncaaf,
    },
    {
        level: 2,
        url: '/nfl',
        label: 'NFL',
        footerLink: {
            url: '/nfl',
            label: 'View All NFL',
        },
        sublinks: nfl,
    },
    {
        level: 2,
        url: '/nhl-hockey',
        label: 'NHL',
        footerLink: {
            url: '/nhl-hockey',
            label: 'View All NHL',
        },
        sublinks: nhl,
    },
    {
        level: 2,
        url: '/region',
        label: 'Cities',
        footerLink: {
            url: '/region',
            label: 'View All Cities',
        },
        sublinks: cities,
    },
    {
        level: 2,
        url: '/sports',
        label: 'Other',
        id: 'other',
        footerLink: {
            url: '/sports',
            label: 'View All Sports',
        },
        sublinks: other,
    },
]

export default sports
