import { AnalyticEvents, COOKIES } from '@/constants'

export const OPTIMIZELY_OFF_VALUE = 'off'

export const OPTIMIZELY_FEATURE = {
    /* long term features */
    allInPricingByVenueState: 'all_in_pricing_by_venue_state',
    allInPricingConnecticut: 'all_in_pricing_connecticut',
    allInPricingTennessee: 'all_in_pricing_tennessee',
    catalogCacheControl: 'web20_catalog_cache_control',
    salesTaxAndDeliveryCombinedQuantified: 'checkout_2_0_sales_tax_and_delivery_combined_quantified',
    homePageDynamicContent: 'web20_home_page_dynamic_content',
    homepageRedesignDesktop: 'web20_homepage_redesign_desktop',
    homepageRedesignMobile: 'web20_homepage_redesign_mobile',
    insurance: 'checkout_2_0_insurance',
    paypalPayLater: 'paypal_pay_later',
    riskifiedBeacon: 'web_riskified_beacon',
    trendingCategory: 'web20_trending_category_feature',
    sportsPerformerPageNearYou: 'sports_performer_page_near_you',
    sellingFastDealsAvailableConcertPerformer: 'sfda_concert_performer',
    sellingFastDealsAvailableTheatrePerformer: 'sfda_theater_performer',
    sellingFastDealsAvailableSportsPerformer: 'sfda_sports_performer',
    sellingFastDealsAvailableSportsHomePerformer: 'sfda_sports_home_performer',
    onsalePresaleBadges: 'onsale_presale_badges',

    /* temporary experiments to be cleaned up or turned into long term features */

    //  web squad's
    matchupPage: 'web20_matchup_page_feature',
    searchBar: 'web20_search_bar',
    rewardsLandingPageSms: 'web20_rewards_landing_page_sms',
    userLocation: 'web20_user_location',
    cognitoAuthentication: 'web20_cognito_authentication',
    nflHomePage: 'web20_nfl_home_page_feature',
    nflHomepageSuperbowlBanner: 'web20_homepage_superbowl_banner',
    contactUsV2: 'contact_us_v2',
    infiniteScrollLimit: 'web20_infinite_scroll_production_listing_limit_feature',
    localizedCurrency: 'web20_localized_currency_feature',
    transactionalCurrency: 'web20_transactional_currency',
    generalAdmissionUK: 'web20_general_admission_uk_feature',
    listingPerks: 'web20_ticket_details_listing_perks',
    seatFeatures: 'web20_ticket_details_seat_features',
    dealScoreConcerts: 'web20_deal_score_concerts',
    dealScoreSports: 'web20_deal_score_sports',
    dealScoreTheater: 'web20_deal_score_theater',
    groupRollup: 'web20_group_rollup',
    creativeMap: 'web20_creative_map_url',

    //  conversion squad's
    ncaaRegionsMobile: 'web20_ncaa_regions_feature',
    ncaaRegionsDesktop: 'web20_ncaa_regions_desktop_feature',
    mlbSpringTraining: 'web20_mlb_spring_training',
    klarnaAwarenessMobile: 'web20_klarna_awareness_mobile_feature',
    klarnaAwarenessDesktop: 'web20_klarna_awareness_desktop_feature',
    loyaltyPerformerMobile: 'web20_loyalty_performer_mobile_feature',
    performerSignUpForUpdatesHref: 'web20_performer_sign_up_for_updates_href',
    // these *ProductionPatternHeader features have been partially cleaned up
    sportsProductionPatternHeader: 'web20_sports_production_pattern_header_feature',
    theaterProductionPatternHeader: 'web20_theater_production_pattern_header_feature',
    concertProductionPatternHeader: 'web20_concert_production_pattern_header_feature',
    animatedSearchbarPlaceholder: 'web20_search_animated_placeholder_text',
    mlbPerformerOpeningDayMobile: 'web20_mlb_performer_opening_day_mobile_feature',
    mlbPerformerOpeningDayDesktop: 'web20_mlb_performer_opening_day_desktop_feature',
    mlbPerformerCalendarViewDefault: 'mlb_performer_calendar_view_default',
    ncaaBasketballPerformerNearYouDesktop: 'web20_ncaa_basketball_performer_near_you_desktop_feature',
    ncaaBasketballPerformerNearYouMobile: 'web20_ncaa_basketball_performer_near_you_mobile_feature',
    mlbPerformerHomeAwayToggleMobile: 'web20_mlb_performer_home_away_toggle_mobile_feature',
    nbaPlayoffsMobileHero: 'web20_nba_playoffs_mobile_hero_feature',
    nbaPlayoffsDesktopHero: 'web20_nba_playoffs_desktop_hero_feature',
    maximCasinoRoyaleExperience: 'maxim_casino_royale_experience_2_10_24',
    frontOfSectionBadge: 'web20_front_of_section_badge',

    // BES owns these flags
    useMapboxMapMobile: 'venue_maps_march_24_mobile_test',
    useMapboxMaps: 'venue_maps_march_24_desktop_test',
} as const

export const OPTIMIZELY_DECISION_COOKIES = {
    COGNITO_AUTH: `${COOKIES.OPTIMIZELY_DECISION_COOKIE}-${OPTIMIZELY_FEATURE.cognitoAuthentication}`,
}

export const OPTIMIZELY_EVENT = {
    productionPageView: 'web20_production_page_view',
    theaterProductionPageView: 'web20_theater_production_page_view',
    performerPageView: 'web20_performer_page_view',
    theaterPerformerPageView: 'web20_theater_performer_page_view',
    productionTicketDetailsView: 'web20_production_ticket_details_view',
    checkoutButtonClick: 'web20_production_checkout_button_click',
    loyaltyModalView: 'web20_production_loyalty_modal_view',
    moreInfoButtonClick: 'web20_production_more_info',
    superSellerCheckboxClick: 'web20_production_super_seller',
    instantDeliveryCheckboxClick: 'web20_production_instant_delivery',
    productionLinkClick: 'web20_production_link_click',
    productionListViewClick: 'web20_production_list_view_click',
    productionMapViewClick: 'web20_production_map_view_click',
    productionFiltersClick: 'web20_production_filters_click',
    sportsProductionPageView: 'web20_sports_production_page_view',
    concertProductionPageView: 'web20_concert_production_page_view',
    lowPricesDealsAvailableProductionClick: 'web20_lpda_production_click',
    searchBarClick: 'web20_search_bar_click',
    productionMinimapClick: 'web20_production_minimap_clicked',
    topPickClick: 'web20_top_picks_click',
    eventsUnder100Click: 'web20_events_under_100_click',
    browseCategoriesClick: 'web20_browse_categories_click',
    categoryPageView: 'web20_category_page_view',
}

export const OPTIMIZELY_CHECKOUT_EVENTS = {
    // checkoutStart & beginCheckout are tracked in same place;
    // use beginCheckout going forward (checkoutStart will eventually be removed)
    checkoutStart: 'global_checkout_start',
    beginCheckout: AnalyticEvents.BeginCheckout,

    // checkoutComplete & purchase are tracked in same place;
    // use purchase going forward (checkoutComplete will eventually be removed)
    checkoutComplete: 'global_checkout_complete',
    purchase: AnalyticEvents.Purchase,
    checkoutLogin: AnalyticEvents.CheckoutLogin,
    addShippingInfo: AnalyticEvents.AddShippingInfo,
    addPaymentInfo: AnalyticEvents.AddPaymentInfo,
    // TODO BWCT-931: add Purchase Auth and PurchaseError to Optimizely
}

export const FILTERED_USER_AGENTS = ['axios', 'Datadog Agent', 'botify']

export const scarcityBadgingIndices = [0, 6]
