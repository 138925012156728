import type { EventTags } from '@optimizely/optimizely-sdk'
import { ReactSDKClient, useDecision } from '@optimizely/react-sdk'
import { VariableValuesObject } from '@optimizely/react-sdk/dist/client'
import once from 'lodash/once'

import type { OptimizelyFeatureKey, OptimizelyHookResult } from './types'

/**
 * Ideally we would import this type from optimizely but doing so yields a
 * syntax error because it depends on the `uuid` package which jest imports as
 * an ESM
 */
// type EventTags = Record<string, string | number | null>

export const trackOptimizelyEvent = (
    eventKey: string,
    optimizely: ReactSDKClient | null,
    eventTags?: EventTags,
): void => {
    if (optimizely?.user?.id && optimizely.user.attributes) {
        optimizely.track(eventKey, optimizely.user.id, optimizely.user.attributes, eventTags)
    }
}

const depMessage = once(() => console.warn('secondaryIsEnabled is deprecated: utilize optimizely audiences instead'))

export const useOptimizelyFeature = (
    featureKey: OptimizelyFeatureKey,
    // DO NOT USE secondaryIsEnabled GOING FORWARD!
    // This is a deprecated way to handle; optimizely audiences should be used now instead!
    secondaryIsEnabled?:
        | boolean
        | ((options: { originalIsEnabled: boolean; variables: VariableValuesObject }) => boolean),
): OptimizelyHookResult => {
    /* istanbul ignore next */
    if (
        secondaryIsEnabled !== undefined &&
        process.env.ENVIRONMENT !== 'production' &&
        process.env.ENVIRONMENT !== 'test'
    ) {
        depMessage()
    }

    // useFeature defaults autoUpdate to be false. However, useDecision defaults autoUpdate to be true
    const [decision] = useDecision(featureKey, { autoUpdate: false })
    const { enabled: originalIsEnabled, variables } = decision

    const definedSecondaryIsEnabled = secondaryIsEnabled ?? true
    // definedSecondaryIsEnabled is either a boolean or a function
    const finalIsEnabled: boolean =
        typeof definedSecondaryIsEnabled === 'boolean'
            ? originalIsEnabled && definedSecondaryIsEnabled
            : // If definedSecondaryIsEnabled is a function then pass in data from the original useFeature hook
              originalIsEnabled && definedSecondaryIsEnabled({ originalIsEnabled, variables })

    return finalIsEnabled ? [true, variables] : [false, {}]
}
