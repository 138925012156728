import { safelyTrack } from '@/utils/analytics/helpers'

import { trackBrazeEvent } from '../ga4'
import { _ready } from '../segment'

declare global {
    interface Window {
        appboy?: any
        braze?: any
    }
}

interface BrazeMessage {
    triggerId: string
    extras: any
}

export const _getBraze = () => {
    return window?.braze || window?.appboy
}

export const _getUser = () => {
    return _getBraze()?.getUser()
}

export const _subscribeToInAppMessage = (f: any) => {
    return _getBraze()?.subscribeToInAppMessage(f)
}

export const _initializeBraze = (): void => {
    const braze = _getBraze()

    // if we still don't have a braze handle then defer
    if (!braze) {
        setTimeout(_initializeBraze, 100)
    } else {
        _subscribeToInAppMessage(function (message: BrazeMessage) {
            trackBrazeEvent(message?.triggerId, message?.extras?.messageId)
        })
    }
}

export const initializeBraze = (): void => {
    // only initialize braze on the client, not the server
    if (typeof window !== undefined && document.body.getElementsByTagName('script').length > 0) {
        // wait for segment to be ready
        _ready(_initializeBraze)
    }
}

// sets an alias on the current user that can be used to look up the user later
export const setBrazeAlias = safelyTrack((key: string, id: string): boolean => {
    try {
        return _getUser()?.addAlias(id, key)
    } catch (err) {
        // during the transition, sometimes segment is initialized before braze and this will throw
        // we can safely eat this exception and return false
        return false
    }
})
