import type { Level3MenuItem } from '@/context/performers-navigation/types'

const ncaaf: Level3MenuItem[] = [
    {
        level: 3,
        url: '/college-football-championship-tickets--sports-ncaa-football/performer/34081',
        label: 'College Football Championship',
    },
    {
        level: 3,
        url: '/college-football-playoff-tickets--sports-ncaa-football/performer/34119',
        label: 'College Football Playoff',
    },
    {
        level: 3,
        url: '/alabama-crimson-tide-football-tickets--sports-ncaa-football/performer/988',
        label: 'Alabama Crimson Tide ',
        id: 'sports-ncaaf-alabama-crimson-tide',
    },
    {
        level: 3,
        url: '/ncaaf',
        label: 'All NCAA Football',
    },
    {
        level: 3,
        url: '/arkansas-razorbacks-football-tickets--sports-ncaa-football/performer/1158',
        label: 'Arkansas Razorbacks',
    },
    {
        level: 3,
        url: '/auburn-tigers-football-tickets--sports-ncaa-football/performer/54',
        label: 'Auburn Tigers',
    },
    {
        level: 3,
        url: '/big-ten-football-championship-tickets--sports-ncaa-football/performer/15066',
        label: 'Big Ten Championship',
    },
    {
        level: 3,
        url: '/college-football-playoff-tickets--sports-ncaa-football/performer/34119',
        label: 'College Football Playoff ',
    },
    {
        level: 3,
        url: '/florida-state-seminoles-football-tickets--sports-ncaa-football/performer/294',
        label: 'Florida State Seminoles',
    },
    {
        level: 3,
        url: '/georgia-bulldogs-football-tickets--sports-ncaa-football/performer/320',
        label: 'Georgia Bulldogs ',
    },
    {
        level: 3,
        url: '/kansas-state-wildcats-football-tickets--sports-ncaa-football/performer/1066',
        label: 'Kansas State Wildcats',
    },
    {
        level: 3,
        url: '/louisville-cardinals-football-tickets--sports-ncaa-football/performer/489',
        label: 'Louisville Cardinals',
    },
    {
        level: 3,
        url: '/lsu-tigers-football-tickets--sports-ncaa-football/performer/1003',
        label: 'LSU Tigers',
    },
    {
        level: 3,
        url: '/michigan-state-spartans-football-tickets--sports-ncaa-football/performer/540',
        label: 'Michigan State Spartans',
    },
    {
        level: 3,
        url: '/michigan-wolverines-football-tickets--sports-ncaa-football/performer/541',
        label: 'Michigan Wolverines',
    },
    {
        level: 3,
        url: '/mississippi-state-bulldogs-football-tickets--sports-ncaa-football/performer/1111',
        label: 'Mississippi State Bulldogs',
    },
    {
        level: 3,
        url: '/missouri-tigers-football-tickets--sports-ncaa-football/performer/558',
        label: 'Missouri Tigers',
    },
    {
        level: 3,
        url: '/bowl-game-tickets--sports-college-football/custom/139',
        label: 'NCAA Bowl Games',
    },
    {
        level: 3,
        url: '/notre-dame-fighting-irish-football-tickets--sports-ncaa-football/performer/618',
        label: 'Notre Dame Fighting Irish',
    },
    {
        level: 3,
        url: '/ohio-state-buckeyes-football-tickets--sports-ncaa-football/performer/630',
        label: 'Ohio State Buckeyes',
    },
    {
        level: 3,
        url: '/ole-miss-rebels-football-tickets--sports-ncaa-football/performer/1100',
        label: 'Ole Miss Rebels',
    },
    {
        level: 3,
        url: '/oregon-ducks-football-tickets--sports-ncaa-football/performer/635',
        label: 'Oregon Ducks ',
    },
    {
        level: 3,
        url: '/penn-state-nittany-lions-football-tickets--sports-ncaa-football/performer/1013',
        label: 'Penn State Nittany Lions ',
    },
    {
        level: 3,
        url: '/south-carolina-gamecocks-football-tickets--sports-ncaa-football/performer/1025',
        label: 'South Carolina Gamecocks',
    },
    {
        level: 3,
        url: '/tennessee-vols-football-tickets--sports-ncaa-football/performer/849',
        label: 'Tennessee Vols ',
    },
    {
        level: 3,
        url: '/texas-tech-red-raiders-football-tickets--sports-ncaa-football/performer/1156',
        label: 'Texas Tech Red Raiders',
    },
]

export default ncaaf
