import cloneDeep from 'lodash/cloneDeep'
import { createSelector } from 'reselect'

import type { Performer } from '@/types/app-types'
import { getPerformerUrl } from '@/utils/production'

import getMainMenuItems from './menu-items'
import type { Level1MenuItem, Level3MenuItem, PerformersNavigation, PerformersNavigationStateType } from './types'

const convertPerformersToMenuSublinks = (performers: Performer[]): Level3MenuItem[] => {
    const menuSublinks: Level3MenuItem[] = []

    performers.forEach((performer: Performer) => {
        const url = getPerformerUrl(performer)

        // Filter out performers that don't have URLs
        if (url)
            menuSublinks.push({
                level: 3,
                url,
                label: performer.name,
            })
    })

    // Sort menu sublinks by performer name
    return menuSublinks.sort((a, b) => (a.label > b.label ? 1 : -1))
}

type MenuItemsOptimizelyFeatures = { isTrendingCategoryEnabled: boolean; isCognitoFeatureEnabled: boolean }
export const mergedHardCodedAndCatalogMenuItemsSelector = createSelector(
    [
        // Usual first input - extract value from `state`
        (state: PerformersNavigationStateType) => state.data,
        // Take the second arg, `isTrendingCategoryEnabled`, and forward to the output selector
        (_state: PerformersNavigationStateType, optimizelyFeatures: MenuItemsOptimizelyFeatures) => optimizelyFeatures,
    ],
    (data: PerformersNavigation | undefined, optimizelyFeatures: MenuItemsOptimizelyFeatures): Level1MenuItem[] => {
        const { isTrendingCategoryEnabled, isCognitoFeatureEnabled } = optimizelyFeatures
        const menuItems = getMainMenuItems(isCognitoFeatureEnabled)
        const featuredMenuItems = isTrendingCategoryEnabled
            ? menuItems
            : menuItems.filter((item) => item.id !== 'trendingCategory')
        if (!data) {
            return featuredMenuItems
        }

        // Replace some hard coded links with links from catalog
        const menuItemsCopy: Level1MenuItem[] = cloneDeep(featuredMenuItems)

        // Trending nav
        const trendingIndex = menuItemsCopy.findIndex((item) => item.id === 'trendingCategory')

        if (trendingIndex > -1) {
            // Trending sports
            menuItemsCopy[trendingIndex].sublinks[0].sublinks = convertPerformersToMenuSublinks(
                data.topSportsPerformers,
            )
            // Trending concerts
            menuItemsCopy[trendingIndex].sublinks[1].sublinks = convertPerformersToMenuSublinks(
                data.topConcertPerformers,
            )
            // Trending theatre
            menuItemsCopy[trendingIndex].sublinks[2].sublinks = convertPerformersToMenuSublinks(
                data.topTheaterPerformers,
            )
        }

        const sportsIndex = menuItemsCopy.findIndex((item) => item.label.toLowerCase().includes('sports'))
        // Trending sports
        menuItemsCopy[sportsIndex].sublinks[0].sublinks = convertPerformersToMenuSublinks(data.topSportsPerformers)

        // NCAA Basketball
        menuItemsCopy[sportsIndex].sublinks[3].sublinks = convertPerformersToMenuSublinks(
            data.topNCAABasketballPerformers,
        )

        // NCAA Football
        menuItemsCopy[sportsIndex].sublinks[4].sublinks = convertPerformersToMenuSublinks(
            data.topNCAAFootballPerformers,
        )

        const concertsIndex = menuItemsCopy.findIndex((item) => item.label.toLowerCase().includes('concert'))

        // Trending concerts
        menuItemsCopy[concertsIndex].sublinks[0].sublinks = convertPerformersToMenuSublinks(data.topConcertPerformers)

        // Music festivals
        menuItemsCopy[concertsIndex].sublinks[1].sublinks = convertPerformersToMenuSublinks(
            data.topMusicFestivalPerformers,
        )

        const theatreIndex = menuItemsCopy.findIndex((item) => item.label.toLowerCase().includes('theater'))

        // Trending theater
        menuItemsCopy[theatreIndex].sublinks[0].sublinks = convertPerformersToMenuSublinks(data.topTheaterPerformers)

        // Comedy
        menuItemsCopy[theatreIndex].sublinks[1].sublinks = convertPerformersToMenuSublinks(data.topComedyPerformers)

        // Family
        menuItemsCopy[theatreIndex].sublinks[2].sublinks = convertPerformersToMenuSublinks(data.topFamilyPerformers)

        return menuItemsCopy
    },
)
