import type { Level3MenuItem } from '@/context/performers-navigation/types'

const nba: Level3MenuItem[] = [
    {
        level: 3,
        url: '/nba-playoff-tickets--sports-nba-basketball/custom/171',
        label: 'NBA Playoffs',
    },
    {
        level: 3,
        url: '/nba-finals-tickets--sports-nba-basketball/performer/2724',
        label: 'NBA Finals',
    },
    {
        level: 3,
        url: '/nba-all-star-game-tickets--sports-nba-basketball/performer/577',
        label: 'NBA All Star Game',
    },
    {
        level: 3,
        url: '/atlanta-hawks-tickets--sports-nba-basketball/performer/52',
        label: 'Atlanta Hawks',
        id: 'sports-nba-atlanta-hawks',
    },
    {
        level: 3,
        url: '/boston-celtics-tickets--sports-nba-basketball/performer/105',
        label: 'Boston Celtics',
    },
    {
        level: 3,
        url: '/brooklyn-nets-tickets--sports-nba-basketball/performer/29773',
        label: 'Brooklyn Nets',
    },
    {
        level: 3,
        url: '/charlotte-hornets-tickets--sports-nba-basketball/performer/4547',
        label: 'Charlotte Hornets',
    },
    {
        level: 3,
        url: '/chicago-bulls-tickets--sports-nba-basketball/performer/161',
        label: 'Chicago Bulls',
    },
    {
        level: 3,
        url: '/cleveland-cavaliers-tickets--sports-nba-basketball/performer/182',
        label: 'Cleveland Cavaliers',
    },
    {
        level: 3,
        url: '/dallas-mavericks-tickets--sports-nba-basketball/performer/215',
        label: 'Dallas Mavericks',
    },
    {
        level: 3,
        url: '/denver-nuggets-tickets--sports-nba-basketball/performer/235',
        label: 'Denver Nuggets',
    },
    {
        level: 3,
        url: '/detroit-pistons-tickets--sports-nba-basketball/performer/239',
        label: 'Detroit Pistons',
    },
    {
        level: 3,
        url: '/golden-state-warriors-tickets--sports-nba-basketball/performer/328',
        label: 'Golden State Warriors',
    },
    {
        level: 3,
        url: '/houston-rockets-tickets--sports-nba-basketball/performer/360',
        label: 'Houston Rockets',
    },
    {
        level: 3,
        url: '/indiana-pacers-tickets--sports-nba-basketball/performer/369',
        label: 'Indiana Pacers',
    },
    {
        level: 3,
        url: '/la-clippers-tickets--sports-nba-basketball/performer/480',
        label: 'LA Clippers',
    },
    {
        level: 3,
        url: '/los-angeles-lakers-tickets--sports-nba-basketball/performer/483',
        label: 'Los Angeles Lakers',
    },
    {
        level: 3,
        url: '/memphis-grizzlies-tickets--sports-nba-basketball/performer/910',
        label: 'Memphis Grizzlies',
    },
    {
        level: 3,
        url: '/miami-heat-tickets--sports-nba-basketball/performer/533',
        label: 'Miami Heat',
    },
    {
        level: 3,
        url: '/milwaukee-bucks-tickets--sports-nba-basketball/performer/550',
        label: 'Milwaukee Bucks',
    },
    {
        level: 3,
        url: '/minnesota-timberwolves-tickets--sports-nba-basketball/performer/553',
        label: 'Minnesota Timberwolves',
    },
    {
        level: 3,
        url: '/new-orleans-pelicans-tickets--sports-nba-basketball/performer/153',
        label: 'New Orleans Pelicans',
    },
    {
        level: 3,
        url: '/new-york-knicks-tickets--sports-nba-basketball/performer/602',
        label: 'New York Knicks',
    },
    {
        level: 3,
        url: '/oklahoma-city-thunder-tickets--sports-nba-basketball/performer/16164',
        label: 'Oklahoma City Thunder',
    },
    {
        level: 3,
        url: '/orlando-magic-tickets--sports-nba-basketball/performer/636',
        label: 'Orlando Magic',
    },
    {
        level: 3,
        url: '/philadelphia-76ers-tickets--sports-nba-basketball/performer/668',
        label: 'Philadelphia 76ers',
    },
    {
        level: 3,
        url: '/phoenix-suns-tickets--sports-nba-basketball/performer/679',
        label: 'Phoenix Suns',
    },
    {
        level: 3,
        url: '/portland-trail-blazers-tickets--sports-nba-basketball/performer/690',
        label: 'Portland Trail Blazers',
    },
    {
        level: 3,
        url: '/sacramento-kings-tickets--sports-nba-basketball/performer/748',
        label: 'Sacramento Kings',
    },
    {
        level: 3,
        url: '/san-antonio-spurs-tickets--sports-nba-basketball/performer/754',
        label: 'San Antonio Spurs',
    },
    {
        level: 3,
        url: '/toronto-raptors-tickets--sports-nba-basketball/performer/879',
        label: 'Toronto Raptors',
    },
    {
        level: 3,
        url: '/utah-jazz-tickets--sports-nba-basketball/performer/902',
        label: 'Utah Jazz',
    },
    {
        level: 3,
        url: '/washington-wizards-tickets--sports-nba-basketball/performer/926',
        label: 'Washington Wizards',
    },
]

export default nba
