import React from 'react'

import * as Sentry from '@sentry/nextjs'
import { NextPage } from 'next'
import NextError, { ErrorProps } from 'next/error'

import appLogger from '@/utils/app-logger'

interface MyErrorProps extends ErrorProps {
    hasGetInitialPropsRun?: boolean
    err?: Error
    tag?: string
}

// Custom error page is not testable during development mode (`npm run dev`)
// I think NextJS intended to have custom error page to be simple implementation ONLY
// Therefore making it hard to test.
const MyError: NextPage<MyErrorProps> = ({ statusCode, hasGetInitialPropsRun = false, err, title, tag }) => {
    if (!hasGetInitialPropsRun && err) {
        // getInitialProps is not called in case of
        // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
        // err via _app.js so it can be captured
        if (tag) Sentry.setTag('page_type', tag)
        appLogger.logError(err)
    }

    return <NextError statusCode={statusCode} title={title} />
}

MyError.getInitialProps = async (ctx) => {
    const { res, err, asPath } = ctx
    const errorInitialProps: MyErrorProps = await NextError.getInitialProps(ctx)

    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    errorInitialProps.hasGetInitialPropsRun = true

    // Running on the server, the response object (`res`) is available.
    //
    // Next.js will pass an err on the server if a page's data fetching methods
    // threw or returned a Promise that rejected
    //
    // Running on the client (browser), Next.js will provide an err if:
    //
    //  - a page's `getInitialProps` threw or returned a Promise that rejected
    //  - an exception was thrown somewhere in the React lifecycle (render,
    //    componentDidMount, etc) that was caught by Next.js's React Error
    //    Boundary. Read more about what types of exceptions are caught by Error
    //    Boundaries: https://reactjs.org/docs/error-boundaries.html

    if (res?.statusCode === 404) {
        // Opinionated: do not record an exception in Sentry for 404
        return { statusCode: 404 }
    }

    if (err) {
        appLogger.logError(err)
        await Sentry.flush(2000)
        return errorInitialProps
    }

    // If this point is reached, getInitialProps was called without any
    // information about what the error might be. This is unexpected and may
    // indicate a bug introduced in Next.js, so record it in Sentry
    appLogger.logError(new Error(`_error.js getInitialProps missing data at path: ${asPath}`))
    await Sentry.flush(2000)

    return errorInitialProps
}

export default MyError
