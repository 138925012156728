import React, { createContext, PropsWithChildren, useMemo, useState, useEffect, useRef, useContext } from 'react'

import { OptimizelyContext } from '@optimizely/react-sdk'
import cloneDeep from 'lodash/cloneDeep'
import { Cookies } from 'react-cookie'

import { serverSideQuery, useQuery } from '@/api'
import { GEOLOCATION_ENDPOINT, HEADERS } from '@/constants'
import {
    getNameSelector,
    getLatLongSelector,
    getRegionSelector,
    getCountrySelector,
    getDropdownNameSelector,
    getDetectedCountrySelector,
} from '@/context/location/selectors'
import type {
    LocationContextType,
    LocationDispatchType,
    LocationProviderProps,
    LocationSelectorType,
    LocationStateType,
} from '@/context/location/types'
import { useBrowserLocation } from '@/hooks/use-browser-location'
import { UserLocation } from '@/types/app-types'
import { trackGeolocationEvent, trackUserLocation } from '@/utils/analytics/ga4'
import { GeolocationEventEnum } from '@/utils/analytics/types'
import { applyUserInfo } from '@/utils/optimizely/utils'

import { AUTO_DETECTED, DEFAULT_CONTEXT_SELECTOR, DEFAULT_LOCATION, USER_DEFINED, USER_LOCATION_KEY } from './constants'
import { setUserLocationCookie } from './utils'

const cookies = new Cookies()

export const defaultContextValue: LocationContextType = {
    state: {},
    selectors: DEFAULT_CONTEXT_SELECTOR,
    dispatch: {
        setLocation: () => undefined,
        resetLocation: () => undefined,
    },
}

const UserLocationContext = createContext<LocationContextType>(defaultContextValue)
const { Provider } = UserLocationContext

const getEndpointDetails = (token: string, placeId: string, storedLocation: UserLocation | undefined) => {
    return placeId
        ? {
              endpoint: `${GEOLOCATION_ENDPOINT}/${placeId}`,
              enabled: !!token,
              headers: { [HEADERS.X_PLACES_SESSION_TOKEN]: token },
          }
        : {
              endpoint: GEOLOCATION_ENDPOINT,
              initialData: storedLocation,
              enabled: !storedLocation,
          }
}

export const UserLocationProvider: React.FC<PropsWithChildren<LocationProviderProps>> = ({
    children,
    initialProps,
}) => {
    const [placeId, setPlaceId] = useState('')
    const [token, setToken] = useState('')
    const [hasSelectedLocation, setHasSelectedLocation] = useState(initialProps?.hasSelectedLocation ?? false)
    const {
        fetching: fetchingBrowserLocation,
        data: browserLocationData,
        isError: browserLocationError,
        getBrowserLocation,
    } = useBrowserLocation()

    // if we aren't selecting for a new place from search, use stored location. Otherwise use fetch data
    const storedLocation = cookies.get(USER_LOCATION_KEY) ?? initialProps?.data
    const shouldUseStoredLocation = !placeId && !!storedLocation

    // Users detected location when app mounts - never stored in a cookie
    const detectedLocation = initialProps?.detectedLocation

    // we only need to change the endpoint if placeId has changed
    const endpointDetails = getEndpointDetails(token, placeId, storedLocation)
    const { fetching, data, error } = useQuery<UserLocation>(endpointDetails)

    let locationCaptureMethod: string | undefined
    if (fetching) {
        locationCaptureMethod = undefined
    } else if (placeId || browserLocationData) {
        locationCaptureMethod = USER_DEFINED
    } else if (storedLocation) {
        locationCaptureMethod = storedLocation.method
    } else {
        locationCaptureMethod = AUTO_DETECTED
    }
    if (locationCaptureMethod && locationCaptureMethod !== AUTO_DETECTED && !hasSelectedLocation && data) {
        setHasSelectedLocation(true)
    }

    let locationData: any
    if (shouldUseStoredLocation) {
        if (browserLocationError) {
            locationData = detectedLocation ? detectedLocation : DEFAULT_LOCATION
            setUserLocationCookie(locationData, placeId)
        } else {
            locationData = browserLocationData ? browserLocationData : storedLocation
        }
    } else if (data) {
        locationData = { ...data, method: locationCaptureMethod }
    } else {
        locationData = DEFAULT_LOCATION
    }

    // clear token after a fetch to ensure token only used once
    if (!fetching && token) setToken('')

    const originalCountry = useRef<string | undefined>(storedLocation?.countryCode)
    const { optimizely } = useContext(OptimizelyContext)

    useEffect(() => {
        if (fetching || fetchingBrowserLocation) return

        if (!shouldUseStoredLocation || browserLocationData) {
            const data = shouldUseStoredLocation ? browserLocationData : locationData
            setUserLocationCookie(data, placeId)
            trackUserLocation() // push user location to data layer
            trackGeolocationEvent({ event_type: GeolocationEventEnum.INIT })
        }

        if (!originalCountry.current && data?.countryCode && optimizely?.user) {
            originalCountry.current = data.countryCode

            const user = cloneDeep(optimizely.user)
            if (user.attributes) {
                user.attributes.user_country = originalCountry.current
                applyUserInfo(optimizely, user)
            }
        }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [fetching, data?.countryCode, browserLocationData, fetchingBrowserLocation, browserLocationError])

    const contextValue = useMemo(() => {
        const state: LocationStateType = {
            fetching,
            data: locationData,
            error,
            hasSelectedLocation,
            detectedLocation,
        }

        const selectors: LocationSelectorType = {
            city: getNameSelector(state),
            latLong: getLatLongSelector(state),
            detectedCountryName: getDetectedCountrySelector(state),
            region: getRegionSelector(state),
            country: getCountrySelector(state),
            dropdownName: getDropdownNameSelector(state),
        }

        const dispatch: LocationDispatchType = {
            setLocation: (token, placeId) => {
                setHasSelectedLocation(true)
                setToken(token)
                setPlaceId(placeId)
            },
            resetLocation: () => {
                setToken('')
                setPlaceId('')
                getBrowserLocation()
            },
        }

        return { state, selectors, dispatch }
        /* eslint-disable-next-line react-hooks/exhaustive-deps */
    }, [fetching, locationData, hasSelectedLocation, error, detectedLocation])

    return <Provider value={contextValue}>{children}</Provider>
}

/**
 * In production, Cloudflare provides the client IP in a `cf-connecting-ip` header.
 * To get the user's location in server-side render, include a `clientIp` param,
 * like this:
 *
 * ```
 * { clientIp: context.req.headers['cf-connecting-ip'] }
 * ```
 *
 * **NOTE**: Locally and in stage, we'll see odd behavior: Cloudflare isn't implemented,
 * so this header is undefined. As a result, Hermes will return a default location,
 * in the Chicago area.
 */
export const serverSideRead = serverSideQuery<UserLocation>(GEOLOCATION_ENDPOINT)

export default UserLocationContext
