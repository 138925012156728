import type { Level3MenuItem } from '@/context/performers-navigation/types'

const family: Level3MenuItem[] = [
    {
        level: 3,
        url: '/a-christmas-carol-tickets--theater-family/performer/6',
        label: 'A Christmas Carol',
    },
    {
        level: 3,
        url: '/aladdin-tickets--theater-family/performer/14',
        label: 'Aladdin',
    },
    {
        level: 3,
        url: '/alice-in-wonderland-tickets--theater-family/performer/1180',
        label: 'Alice in Wonderland',
    },
    {
        level: 3,
        url: '/baby-shark-live-tickets--theater-family/performer/112673',
        label: 'Baby Shark Live',
    },
    {
        level: 3,
        url: '/blue-man-group-tickets--theater-family/performer/92',
        label: 'Blue Man Group',
    },
    {
        level: 3,
        url: '/cat-in-the-hat-tickets--theater-family/performer/31401',
        label: 'Cat In The Hat',
    },
    {
        level: 3,
        url: '/celtic-yuletide-tickets--theater-family/performer/24312',
        label: 'Celtic Yuletide',
    },
    {
        level: 3,
        url: '/charlie-and-the-chocolate-factory-tickets--theater-family/performer/7813',
        label: 'Charlie and the Chocolate Factory',
    },
    {
        level: 3,
        url: '/cinderella-tickets--theater-family/performer/21971',
        label: 'Cinderella',
    },
    {
        level: 3,
        url: '/daniel-tigers-neighborhood-tickets--theater-family/performer/42756',
        label: 'Daniel Tigers Neighborhood',
    },
    {
        level: 3,
        url: '/disney-on-ice-tickets--theater-family/performer/248',
        label: 'Disney On Ice',
    },
    {
        level: 3,
        url: '/disney-on-ice-dream-big-tickets--theater-family/performer/50975',
        label: 'Disney On Ice: Dream Big',
    },
    {
        level: 3,
        url: '/dog-man-the-musical-tickets--theater-family/performer/108361',
        label: 'Dog Man - The Musical',
    },
    {
        level: 3,
        url: '/dragons-love-tacos-tickets--theater-family/performer/61978',
        label: 'Dragons Love Tacos',
    },
    {
        level: 3,
        url: '/elephant-and-piggies-we-are-in-a-play-tickets--theater-family/performer/34138',
        label: "Elephant and Piggie's We Are in a Play!",
    },
    {
        level: 3,
        url: '/fairytales-on-ice-tickets--theater-family/performer/122305',
        label: 'Fairytales On Ice',
    },
    {
        level: 3,
        url: '/family-fun-series-tickets--theater-family/performer/42242',
        label: 'Family Fun Series',
    },
    {
        level: 3,
        url: '/family-magic-and-comedy-for-all-ages-tickets--theater-family/performer/115053',
        label: 'Family Magic and Comedy For All Ages',
    },
    {
        level: 3,
        url: '/fred-penner-tickets--theater-family/performer/32453',
        label: 'Fred Penner',
    },
    {
        level: 3,
        url: '/how-the-grinch-stole-christmas-tickets--theater-family/performer/1251',
        label: 'How the Grinch Stole Christmas',
    },
    {
        level: 3,
        url: '/joyous-christmas-tickets--theater-family/performer/62162',
        label: 'Joyous Christmas',
    },
    {
        level: 3,
        url: '/junie-b-jones-tickets--theater-family/performer/7640',
        label: 'Junie B. Jones',
    },
    {
        level: 3,
        url: '/kidz-bop-live-parking-tickets--theater-family/performer/59539',
        label: 'Kidz Bop Live Parking',
    },
    {
        level: 3,
        url: '/kidz-bop-live-tickets--theater-family/performer/8852',
        label: 'Kidz Bop Live!',
    },
    {
        level: 3,
        url: '/lightwire-theater-christmas-show-tickets--theater-family/performer/92625',
        label: 'Lightwire Theater Christmas Show',
    },
    {
        level: 3,
        url: '/little-mermaid-jr-tickets--theater-family/performer/32757',
        label: 'Little Mermaid Jr',
    },
    {
        level: 3,
        url: '/madagascar-a-musical-adventure-tickets--theater-family/performer/25235',
        label: 'Madagascar - A Musical Adventure',
    },
    {
        level: 3,
        url: '/menoma-mia-tickets--theater-family/performer/124506',
        label: 'Menoma Mia',
    },
    {
        level: 3,
        url: '/mutts-gone-nuts-tickets--theater-family/performer/40913',
        label: 'Mutts Gone Nuts',
    },
    {
        level: 3,
        url: '/old-macdonalds-singalong-farm-tickets--theater-family/performer/128453',
        label: 'Old MacDonalds Singalong Farm',
    },
    {
        level: 3,
        url: '/paddington-gets-in-a-jam-tickets--theater-family/performer/123789',
        label: 'Paddington Gets in a Jam',
    },
    {
        level: 3,
        url: '/passepartout-tickets--theater-family/performer/130335',
        label: 'Passe-Partout',
    },
    {
        level: 3,
        url: '/paw-patrol-live-tickets--theater-family/performer/50297',
        label: 'Paw Patrol Live',
    },
    {
        level: 3,
        url: '/peking-acrobats-tickets--theater-family/performer/5615',
        label: 'Peking Acrobats',
    },
    {
        level: 3,
        url: '/peter-pan-jr-tickets--theater-family/performer/57214',
        label: 'Peter Pan Jr',
    },
    {
        level: 3,
        url: '/pinocchio-tickets--theater-family/performer/682',
        label: 'Pinocchio',
    },
    {
        level: 3,
        url: '/radio-city-christmas-spectacular-tickets--theater-family/performer/705',
        label: 'Radio City Christmas Spectacular',
    },
    {
        level: 3,
        url: '/rosie-revere-engineer-and-other-stories-tickets--theater-family/performer/102716',
        label: 'Rosie Revere Engineer and Other Stories',
    },
    {
        level: 3,
        url: '/rudolph-the-rednosed-reindeer-tickets--theater-family/performer/33007',
        label: 'Rudolph the Red-Nosed Reindeer',
    },
    {
        level: 3,
        url: '/scoobydoo-live-musical-mysteries-tickets--theater-family/performer/30334',
        label: 'Scooby-Doo Live! Musical Mysteries',
    },
    {
        level: 3,
        url: '/scoobydoo-and-the-lost-city-of-gold-tickets--theater-family/performer/121368',
        label: 'Scooby-Doo! and The Lost City of Gold',
    },
    {
        level: 3,
        url: '/sleeping-beauty-tickets--theater-family/performer/1235',
        label: 'Sleeping Beauty',
    },
    {
        level: 3,
        url: '/snow-white-tickets--theater-family/performer/7642',
        label: 'Snow White',
    },
    {
        level: 3,
        url: '/the-dinosaur-diary-of-dr-trex-tickets--theater-family/performer/47789',
        label: 'The Dinosaur Diary of Dr. T-Rex',
    },
    {
        level: 3,
        url: '/the-little-mermaid-tickets--theater-family/performer/12470',
        label: 'The Little Mermaid',
    },
    {
        level: 3,
        url: '/the-three-little-pigs-tickets--theater-family/performer/7643',
        label: 'The Three Little Pigs',
    },
    {
        level: 3,
        url: '/trolls-live-tickets--theater-family/performer/107303',
        label: 'Trolls Live!',
    },
    {
        level: 3,
        url: '/westminster-kennel-club-dog-show-tickets--theater-family/performer/3976',
        label: 'Westminster Kennel Club Dog Show',
    },
]

export default family
