import React, { $FC, createContext } from 'react'

import { serverSideQuery, useQuery } from '@/api'
import { DEFAULT_CONTEXT_VALUES, PERFORMERS_NAVIGATION_ENDPOINT } from '@/constants'
import { useCognitoAuthentication } from '@/optimizely/features/cognito-authentication'
import { useTrendingCategoryFeature } from '@/optimizely/features/trending-category-feature'

import { mergedHardCodedAndCatalogMenuItemsSelector } from './selectors'
import type {
    PerformersNavigation,
    PerformersNavigationContextType,
    PerformersNavigationProviderProps,
    PerformersNavigationSelectorsType,
    PerformersNavigationStateType,
} from './types'

const PerformersNavigationContext = createContext<PerformersNavigationContextType>(DEFAULT_CONTEXT_VALUES)
const { Provider } = PerformersNavigationContext

export const PerformersNavigationProvider: $FC<PerformersNavigationProviderProps> = ({ children, initialProps }) => {
    const { fetching, data, error } = useQuery<PerformersNavigation>({
        endpoint: PERFORMERS_NAVIGATION_ENDPOINT,
        params: undefined,
        initialFetchDisabled: !!initialProps?.data,
        initialData: initialProps?.data,
    })

    const [isCognitoFeatureEnabled] = useCognitoAuthentication()
    const [isTrendingCategoryEnabled] = useTrendingCategoryFeature()

    const state: PerformersNavigationStateType = { fetching, data, error }

    const selectors: PerformersNavigationSelectorsType = {
        mergedHardCodedAndCatalogMenuItems: mergedHardCodedAndCatalogMenuItemsSelector(state, {
            isTrendingCategoryEnabled,
            isCognitoFeatureEnabled,
        }),
    }

    return <Provider value={{ state, selectors }}>{children}</Provider>
}

export const serverSideRead = async (maxAgeMs?: number): Promise<PerformersNavigation> => {
    const queryInstance = serverSideQuery<PerformersNavigation>(PERFORMERS_NAVIGATION_ENDPOINT)
    return await queryInstance(undefined, maxAgeMs)
}

export default PerformersNavigationContext
