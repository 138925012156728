/* eslint-disable @typescript-eslint/no-unused-vars */
import pickBy from 'lodash/pickBy'

import {
    VTRK_COOKIE_ADGROUP_REGEX,
    VTRK_COOKIE_DEVICE_REGEX,
    VTRK_COOKIE_GCLID_REGEX,
    VTRK_COOKIE_KEYWORD_REGEX,
    VTRK_COOKIE_TARGET_REGEX,
    VTRK_COOKIE_VCAMP_REGEX,
    VTRK_COOKIE_VKID_REGEX,
    VTRK_COOKIE_VMED_REGEX,
    VTRK_COOKIE_VSRC_REGEX,
    VTRK_COOKIE_VTRM_REGEX,
} from '@/constants'
import type { ObjectWithKeyIteration } from '@/types/app-types'
import {
    getCookie,
    getPriceGroupCookieValue,
    getRegionNameCookieValue,
    getUUIDCookieValue,
    getVTRKCookieValue,
} from '@/utils/cookies'

declare global {
    interface Window {
        __APP_DATA__?: AppData
    }
}

export type eventValueType = number | null
export type dataLayerType = Record<string, eventValueType>[]
export interface MiscPageData extends ObjectWithKeyIteration {
    optimizelyTestingVersion?: string | number
    canaryTestName?: boolean
    clientId?: number | string
    customerId?: number | string
    wsUserId?: string | number
    wsUserVar?: string | number
    hashedEmail?: string
    keywordId?: string | number
    originalCampaign?: string
    originalMedium?: string
    originalSource?: string
    originalUtmTerm?: string
    sessionId?: string | number
    adGroupId?: string | number
    regionName?: string
    categoryAndSubCategory?: string
    priceGroup?: string
}

interface PageData extends ObjectWithKeyIteration {
    purchaseCategory?: string
    purchaseSubCategory?: string
    eventId?: number | string
    eventName?: string
}

export interface ProductionPageData extends PageData {
    daysToEvent?: number
    noTixError?: boolean
    productionName?: string
    productionId?: number
    selectedTicketQuantity?: number
    ticketPrice?: number
    medianPrice?: number
    listingCount?: number
    ticketsAvailableCount?: number
    ticketSection?: string
    ticketRow?: string
    ticketValueScore?: number
}

export interface PerformerPageData extends PageData {
    productionCount?: number
}

export type RegionPageData = PerformerPageData

export type VenuePageData = PerformerPageData

interface AppData {
    performerId?: number
    performerName?: string
    categoryName?: string
    subCategoryName?: string
    regionId?: number
    regionName?: string
    venueId?: number
    venueName?: string
}

/** If value is 'null', return undefined. Otherwise, return the original value  */
const trimNullString = (str: string | undefined) => (str === 'null' ? undefined : str)

export const gatherMiscellaneousDimensions = (): MiscPageData => {
    const customerCookie = getCookie(document.cookie, 'x-device-analytics')

    return {
        // TODO WITH B2C-: optimizelyTestingVersion: '',
        canaryTestName: `${getCookie(document.cookie, 'VS_SID')}` === 'cs-0',
        clientId: `${getCookie(document.cookie, 'clientId')}` || undefined,
        customerId: (customerCookie as ObjectWithKeyIteration).customerId,
        wsUserId: `${getCookie(document.cookie, 'wsUser')}` || undefined,
        wsUserVar: `${getCookie(document.cookie, 'wsVar')}` || undefined,
        hashedEmail: (customerCookie as ObjectWithKeyIteration).hashedEmailAddress,
        keywordId: trimNullString(getVTRKCookieValue(VTRK_COOKIE_KEYWORD_REGEX)),
        originalCampaign: trimNullString(getVTRKCookieValue(VTRK_COOKIE_VCAMP_REGEX)),
        originalMedium: trimNullString(getVTRKCookieValue(VTRK_COOKIE_VMED_REGEX)),
        originalSource: trimNullString(getVTRKCookieValue(VTRK_COOKIE_VSRC_REGEX)),
        originalUtmTerm: trimNullString(getVTRKCookieValue(VTRK_COOKIE_VTRM_REGEX)),
        sessionId: getUUIDCookieValue(),
        adGroupId: trimNullString(getVTRKCookieValue(VTRK_COOKIE_ADGROUP_REGEX)),
        vkid: trimNullString(getVTRKCookieValue(VTRK_COOKIE_VKID_REGEX)),
        target: trimNullString(getVTRKCookieValue(VTRK_COOKIE_TARGET_REGEX)),
        device: trimNullString(getVTRKCookieValue(VTRK_COOKIE_DEVICE_REGEX)),
        gclid: trimNullString(getVTRKCookieValue(VTRK_COOKIE_GCLID_REGEX)),
        regionName: getRegionNameCookieValue(),
        priceGroup: getPriceGroupCookieValue(),
    }
}

export const setCustomDimensions = (
    pageData?: ProductionPageData | PerformerPageData | RegionPageData | VenuePageData,
    removeUndefined = true,
): ObjectWithKeyIteration => {
    pageData = { ...gatherMiscellaneousDimensions(), ...pageData }

    if (pageData.purchaseCategory && pageData.purchaseSubCategory && !pageData.categoryAndSubCategory) {
        pageData.categoryAndSubCategory = `${pageData.purchaseCategory}:${pageData.purchaseSubCategory}`
    }

    const dimensions = {
        dimension1: pageData.purchaseCategory,
        dimension2: pageData.categoryAndSubCategory,
        dimension3: pageData.optimizlyTestingVersion,
        dimension4: pageData.eventId,
        dimension5: pageData.productionId,
        dimension8: pageData.originalCampaign,
        dimension9: pageData.originalMedium,
        dimension10: pageData.originalSource,
        dimension11: pageData.originalUtmTerm,
        dimension12: pageData.daysToEvent,
        dimension14: pageData.regionName,
        dimension15: pageData.noTixError,
        dimension16: pageData.canaryTestName,
        dimension17: pageData.medianPrice,
        dimension18: pageData.listingCount,
        dimension21: pageData.productionName,
        dimension22: pageData.eventName,
        dimension23: pageData.wsUserId,
        dimension24: pageData.wsUserVar,
        dimension32: pageData.productionId,
        dimension33: pageData.productionName,
        dimension34: pageData.optimizlyTestingVersion,
        dimension35: pageData.eventName,
        dimension36: pageData.daysToEvent,
        dimension38: pageData.listingCount,
        dimension39: pageData.categoryAndSubCategory,
        dimension40: pageData.purchaseCategory,
        dimension42: pageData.medianPrice,
        dimension43: pageData.regionName,
        dimension44: pageData.noTixError,
        dimension50: pageData.priceGroup,
        dimension51: pageData.selectedTicketQuantity,
        dimension52: pageData.ticketPrice,
        dimension53: pageData.ticketSection,
        dimension54: pageData.ticketRow,
        dimension55: pageData.ticketValueScore,
        dimension57: pageData.productionCount,
        dimension58: pageData.eventId,
        dimension59: pageData.clientId,
        dimension60: pageData.clientId,
        dimension61: pageData.customerId,
        dimension62: pageData.customerId,
        dimension66: pageData.ticketsAvailableCount,
        dimension67: pageData.ticketsAvailableCount,
        dimension69: pageData.canaryTestName,
        dimension77: pageData.originalCampaign,
        dimension78: pageData.originalMedium,
        dimension79: pageData.originalSource,
        dimension80: pageData.originalUtmTerm,
        dimension81: pageData.selectedTicketQuantity,
        dimension84: pageData.ticketPrice,
        dimension85: pageData.ticketRow,
        dimension86: pageData.ticketSection,
        dimension87: pageData.ticketValueScore,
        dimension89: pageData.wsUserId,
        dimension90: pageData.wsUserVar,
        dimension104: pageData.hashedEmail,
        dimension105: pageData.hashedEmail,
        dimension106: pageData.sessionId,
        dimension107: pageData.sessionId,
        dimension113: pageData.adGroupId,
        dimension114: pageData.keywordId,
    }

    return removeUndefined ? pickBy(dimensions, (value) => !!value) : dimensions
}

export const initGTM = (pageData: ProductionPageData | PerformerPageData | RegionPageData | VenuePageData): void => {
    const dimensions = setCustomDimensions(pageData, false)
    dimensions.location = window.location.href.split('?')[0]
}

export const track = (
    event: string,
    category?: string,
    action?: string,
    label?: string,
    value?: eventValueType,
    nonInteractive = true,
    pageData?: ProductionPageData | PerformerPageData | RegionPageData | VenuePageData,
): void => {}

export const trackEvent = (
    category?: string,
    action?: string,
    label?: string,
    value?: eventValueType,
    nonInteractive = true,
    pageData?: ProductionPageData | PerformerPageData | RegionPageData | VenuePageData,
): void => {}

export const trackPageView = (
    pageData?: ProductionPageData | PerformerPageData | RegionPageData | VenuePageData,
): void => {}
