import { Mutex } from 'async-mutex'
import { GetServerSidePropsContext } from 'next'

import { AuthCookie } from '@/types/app-types'

import { buildAuthHeaders, getTokenExpiryDate, logoutCognitoAccount } from '../utils'

import { RefreshTokenArgs } from './types'
import {
    clientSideGetAuthCookie,
    clientSideRemoveAuthCookie,
    clientSideSetAuthCookie,
    refreshCognitoToken,
    serverSideGetAuthCookie,
    serverSideRemoveAuthCookie,
    serverSideSetAuthCookie,
    shouldRefreshAuthToken,
} from './utils'

// Mutex to lock the token refresh
const mutex = new Mutex()

/**
 * Generic token refresh logic for both client and server side requests:
 * (i) starts a token refresh if necessary and returns the most up-to-date token and its expiry date
 * (ii) logs the user out if the refresh fails
 * (iii) prevents multiple refresh calls by controlling access to the refresh calls using a mutex
 *
 * Based on: https://github.com/VividSeats/vivid-rn-mobile/blob/main/src/domain/API/api.ts#L100
 *           https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#preventing-multiple-unauthorized-errors
 */
export const refreshToken = async ({
    getAuthCookie,
    setAuthCookie,
    removeAuthCookie,
}: RefreshTokenArgs): Promise<AuthCookie | undefined> => {
    // if the mutex is locked, a refresh is under way and we have to wait for its completion
    // before moving on with the current request
    await mutex.waitForUnlock()
    const authCookie = getAuthCookie()

    if (authCookie && shouldRefreshAuthToken(authCookie.tokenExpiresAt)) {
        // checking whether the mutex is locked
        if (!mutex.isLocked()) {
            // lock the mutex before starting a refresh
            const release = await mutex.acquire()
            const { accountId, refreshToken } = authCookie

            try {
                // attempt to refresh the token
                const refreshResult = await refreshCognitoToken({ refreshToken, accountId })

                // if refresh is successful, release the mutex for the next request and
                // return the new token and its expiry date
                const newAuthCookie = {
                    token: refreshResult.accessToken,
                    accountId: refreshResult.accountId,
                    refreshToken: refreshResult.refreshToken,
                    tokenExpiresAt: getTokenExpiryDate(Date.now(), refreshResult.expiresIn),
                }
                setAuthCookie(newAuthCookie)
                release()
                return newAuthCookie
            } catch {
                // if refresh fails, release the mutex for the next request and logout
                await logoutCognitoAccount(refreshToken)
                removeAuthCookie()
                release()
            }
        }
    }

    return authCookie
}

/**
 * Performs the token refresh for client side requests if necessary, updating the 'at' cookie
 */
export const clientSideRefresh = async () => {
    const getAuthCookie = (): AuthCookie | undefined => clientSideGetAuthCookie()
    const setAuthCookie = (newAuthCookie?: AuthCookie) => clientSideSetAuthCookie(newAuthCookie)
    const removeAuthCookie = () => clientSideRemoveAuthCookie()

    // refresh the token if necessary
    await refreshToken({ getAuthCookie, setAuthCookie, removeAuthCookie })
}

/**
 * Performs the token refresh for server side requests if necessary, updating the 'at' cookie and returning the
 * updated auth headers to be used by server side API requests
 */
export const serverSideRefresh = async (context?: GetServerSidePropsContext): Promise<{ [key: string]: unknown }> => {
    const getAuthCookie = (): AuthCookie | undefined => serverSideGetAuthCookie(context)
    const setAuthCookie = (newAuthCookie?: AuthCookie) => serverSideSetAuthCookie(context, newAuthCookie)
    const removeAuthCookie = () => serverSideRemoveAuthCookie(context)

    // refresh the token if necessary and return new auth headers
    const currentAuthCookie = await refreshToken({ getAuthCookie, setAuthCookie, removeAuthCookie })
    return currentAuthCookie ? buildAuthHeaders(currentAuthCookie) : {}
}
