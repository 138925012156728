import type { Level2MenuItem } from '@/context/performers-navigation/types'

import cities from './cities'
import musicFestivals from './music-festivals'
import other from './other'
import trending from './trending'

const concerts: Level2MenuItem[] = [
    {
        level: 2,
        url: '/concerts',
        label: 'Trending',
        id: 'trending',
        footerLink: {
            url: '/concerts',
            label: 'View All Concerts',
        },
        sublinks: trending,
    },
    {
        level: 2,
        url: '/concerts/festivals',
        label: 'Music Festivals',
        id: 'concerts-music-festivals',
        footerLink: {
            url: '/concerts/festivals',
            label: 'View All Music Festivals',
        },
        sublinks: musicFestivals,
    },
    {
        level: 2,
        url: '/region',
        label: 'Cities',
        footerLink: {
            url: '/region',
            label: 'View All Cities',
        },
        sublinks: cities,
    },
    {
        level: 2,
        url: '/concerts',
        label: 'Other',
        id: 'other',
        footerLink: {
            url: '/concerts',
            label: 'View All Concerts',
        },
        sublinks: other,
    },
]

export default concerts
