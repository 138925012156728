import { useEffect, useState } from 'react'

import { useQuery } from '@/api'
import { GEOLOCATION_ENDPOINT } from '@/constants'
import { AUTO_DETECTED } from '@/context/location/constants'

import { CurrentLocation, GET_LOCATION_CONFIG, LocationApiResponse, LocationData } from './types'

export const useBrowserLocation = () => {
    const [browserLocationData, setBrowserLocationData] = useState<LocationData | null>(null)
    const [currentLocation, setCurrentLocation] = useState<CurrentLocation | null>(null)
    const [noBrowserSupport, setNoBrowserSupport] = useState<boolean>(false)

    const { data, fetching, error } = useQuery<LocationApiResponse>({
        endpoint: `${GEOLOCATION_ENDPOINT}/${currentLocation?.latitude}/${currentLocation?.longitude}`,
        initialFetchDisabled: true,
        enabled: !!(currentLocation?.latitude && currentLocation?.longitude),
    })

    useEffect(() => {
        if (!currentLocation || fetching) return
        if (!data) {
            setNoBrowserSupport(true)
            return
        }
        const locationDataObject: LocationData = {
            latitude: currentLocation?.latitude,
            longitude: currentLocation?.longitude,
            countryCode: data.countryCode,
            region: data.regionCode,
            city: data.city,
            countryName: data.countryName,
            method: AUTO_DETECTED,
        }

        setBrowserLocationData(locationDataObject)
    }, [data, fetching, currentLocation])

    const getBrowserLocation = () => {
        if (!navigator.geolocation) {
            setNoBrowserSupport(true)
            return
        }

        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords

                    resolve({ latitude, longitude })
                },
                (err) => reject(err),
                GET_LOCATION_CONFIG,
            )
        })
            .then((response) => {
                const { latitude, longitude } = response as CurrentLocation
                setCurrentLocation({ latitude, longitude })
            })
            .catch((err) => {
                setNoBrowserSupport(true)
                console.error(`Geolocation error: ${err.message}`)
            })
    }

    return {
        fetching,
        data: browserLocationData,
        isError: !!error || noBrowserSupport,
        getBrowserLocation,
    }
}
