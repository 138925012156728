import Agent, { HttpsAgent } from 'agentkeepalive'
import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import axios from 'axios'
import type { IAxiosCacheAdapterOptions, ISetupCache } from 'axios-cache-adapter'
import { setupCache } from 'axios-cache-adapter'
import axiosRetry from 'axios-retry'

import {
    COOKIES,
    CATALOG_ENDPOINTS,
    PERFORMERS_NAVIGATION_ENDPOINT,
    COGNITO_REFRESH_TOKEN_ENDPOINT,
    MAP_ENDPOINT,
    DEFAULT_REQUEST_TIMEOUT,
    REQUEST_RETRY_LIMIT,
} from '@/constants'
import { getCognitoFeature } from '@/optimizely/features/cognito-authentication'
import type { AuthCookie } from '@/types/app-types'
import { isRunningOnServer } from '@/utils'
import { _anonymousId, _userid } from '@/utils/analytics/segment'
import { getCookie } from '@/utils/cookies'

import { clientSideRefresh } from './refresh-token'
import { buildAuthHeaders, isCrossOriginRequest } from './utils'

export type AxiosInstanceType = AxiosInstance & { cache: ISetupCache }

const isServer = isRunningOnServer()

const apiFactory = (
    baseURL: string,
    headers: Record<string, unknown> = {},
    options: IAxiosCacheAdapterOptions = {},
): AxiosInstanceType => {
    const cache = isServer
        ? setupCache({
              maxAge: 0,
              ...options,
              exclude: {
                  query: false,
                  filter: (reqInfo: { url: string }) => {
                      return reqInfo.url !== PERFORMERS_NAVIGATION_ENDPOINT
                  },
                  ...options.exclude,
              },
          })
        : undefined

    // Only use agentkeepalive on the server
    const agent = isServer
        ? new HttpsAgent({
              keepAlive: true,
              maxSockets: 100,
              maxFreeSockets: 10,
              freeSocketTimeout: DEFAULT_REQUEST_TIMEOUT + 2_000, // to prevent excess connection resets
          })
        : undefined

    const httpAgent = isServer
        ? new Agent({
              keepAlive: true,
              maxSockets: 100,
              maxFreeSockets: 10,
              freeSocketTimeout: DEFAULT_REQUEST_TIMEOUT + 2_000, // to prevent excess connection resets
          })
        : undefined

    const dnsConfig = {
        disabled: process.env.AXIOS_DNS_DISABLE === 'true',
        dnsTtlMs: 5,
        cacheGraceExpireMultiplier: 2,
        backgroundScanMs: 2400,
        dnsCacheSize: 100,
    }

    const extendedHeaders: Record<string, unknown> = {
        Accept: 'application/json',
        ...headers,
    }

    if (!isServer && extendedHeaders.Connection) {
        // Unsafe client-side header
        delete extendedHeaders.Connection
    }

    const axiosInstance = axios.create({
        // Add caching for server-side queries only - will be stored in memory
        adapter: cache?.adapter,
        baseURL,
        headers: extendedHeaders,
        timeout: DEFAULT_REQUEST_TIMEOUT,
        httpsAgent: agent,
        httpAgent,
        ...dnsConfig,
    })

    /*
        From https://www.npmjs.com/package/axios-retry
        "By default, it retries if it is a network error or a 5xx error on an idempotent request (GET, HEAD, OPTIONS, PUT or DELETE)"
    */
    axiosRetry(axiosInstance, {
        retries: REQUEST_RETRY_LIMIT,
        retryDelay: (retryCount: number) => {
            return retryCount * 500
        },
    })

    // Append cache controller to the instance for direct access if needed
    return { ...axiosInstance, cache } as AxiosInstanceType
}

export const hermesBaseUrl = isServer ? process.env.HERMES_SERVICE : process.env.NEXT_PUBLIC_HERMES_SERVICE
const legacyWebUrl = process.env.INTERNAL_LEGACY_WEB_URL || '' // server side only
const optimizelyDatafileUrl = process.env.NEXT_PUBLIC_OPTIMIZELY_FULLSTACK_DATAFILE || '' // server side only

/* Add new services here */
if (!hermesBaseUrl) {
    throw new Error(`Missing ${isServer ? 'HERMES_SERVICE' : 'NEXT_PUBLIC_HERMES_SERVICE'} environment variable`)
}

export const cloudfrontMapService = apiFactory(MAP_ENDPOINT)
export const legacyWebService = apiFactory(legacyWebUrl)
// Enable HTTP keep alive for performance, axios retry should help with socket disconnects
export const hermesService = apiFactory(hermesBaseUrl, { Connection: 'Keep-Alive' })
export const optimizelyService = apiFactory(
    optimizelyDatafileUrl,
    {},
    {
        maxAge: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 5 * 60 * 1000 : 0,
        exclude: { filter: () => false },
    }, // cache for 5 minutes on production, disable filter
)

export const errorInterceptor = (err: string & Error) => {
    if (typeof window === 'undefined') {
        const { appLogger } = require('../../log')
        appLogger.error(err?.message || err)
    }
    throw err
}

const setSearchAnalyticsToken = (config: AxiosRequestConfig) => {
    let userId = undefined

    if (!!window.analytics.user?.()) {
        userId = _userid() || _anonymousId()
    } else {
        // falling back to cookie since for the first load we might not have analytics.user yet
        const anonymousId = getCookie(document.cookie, COOKIES.SEGMENT_ANONYMOUS_USER)
        const loggedInUser = getCookie(document.cookie, COOKIES.SEGMENT_LOGGEDIN_USER)

        userId = loggedInUser || anonymousId
    }

    if (!!CATALOG_ENDPOINTS.find((endpoint) => endpoint === config.url)) {
        config.params = userId ? { ...config.params, searchAnalyticsUserToken: userId } : config.params
    }
}

const requestInterceptor = async (config: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
    if (!isServer) {
        const sendAnalyticsUserToken = getCookie(document.cookie, COOKIES.SEND_SEARCH_ANALYTICS_TOKEN)

        if (sendAnalyticsUserToken) {
            setSearchAnalyticsToken(config)
        }
    }

    /*
        WARNING!
        FEATURE BRANCH ONLY PROXY PATCH
        https://vividseats.atlassian.net/browse/BW-353
    */
    if (process.env.VIVID_ROUTE_ENABLED === 'true') {
        if (isServer) {
            // server side we get this from vivid_route
            config.headers['x-request-route'] = process.env.VIVID_ROUTE
        } else {
            // client side we get this from the cookie
            config.headers['x-request-route'] = getCookie(document.cookie, 'x-request-route')
        }

        // if the header doesn't have a value, delete it
        if (!config.headers['x-request-route']) {
            delete config.headers['x-request-route']
        }
    }

    const isCrossOrigin = isCrossOriginRequest(config.url || '', hermesBaseUrl)

    // Refresh auth token and attach auth headers to client side requests
    // Auth headers for server side requests are attached on serverSideQuery (src/api/use-query/hooks.ts)
    if (!isCrossOrigin && !isServer && config.url !== COGNITO_REFRESH_TOKEN_ENDPOINT) {
        const cognitoDecision = getCognitoFeature()
        cognitoDecision?.enabled && (await clientSideRefresh())

        const authCookie = getCookie(document.cookie, COOKIES.AUTH_TOKEN_COOKIE)
        if (authCookie) {
            const authHeaders = buildAuthHeaders(authCookie as AuthCookie)
            config.headers = {
                ...config.headers,
                ...authHeaders,
            }
        }
    }

    return config
}

hermesService.interceptors.request.use(requestInterceptor)
hermesService.interceptors.response.use((res) => res, errorInterceptor)
legacyWebService.interceptors.response.use((res) => res, errorInterceptor)

const registerInterceptors = async () => {
    try {
        const { registerInterceptor } = await import('axios-cached-dns-resolve')

        registerInterceptor(cloudfrontMapService)
        registerInterceptor(hermesService)
        registerInterceptor(legacyWebService)
        registerInterceptor(optimizelyService)

        return Promise.resolve()
    } catch (e) {
        console.error((e as Error).message)

        return Promise.resolve()
    }
}

// on the server-side - cache dns entries
if (isServer) {
    registerInterceptors()
}

export default apiFactory
