import { createSelector } from 'reselect'

import { COUNTRY_FLAG_IMAGE_PATH } from '@/constants'
import type { InternationalizationStateType } from '@/context/internationalization/types'
import { ContactUsSettings } from '@/types/app-types'

export const internationalizationGlobalSelector = (state: InternationalizationStateType) => state

export const getFlagSelector = createSelector(
    [
        internationalizationGlobalSelector,
        (_state: InternationalizationStateType, selectedCurrency: string) => selectedCurrency,
    ],
    (state: InternationalizationStateType, selectedCurrency: string): string | undefined => {
        if (!state) return undefined

        const countryCode = !selectedCurrency
            ? state.data?.countries?.find((country) => country.isDefault)?.countryCode
            : state.data?.countries?.find((country) => country.currencyDefault === selectedCurrency)?.countryCode

        return countryCode ? `${COUNTRY_FLAG_IMAGE_PATH}/${countryCode}.svg` : undefined
    },
)

export const getDefaultCurrencySelector = createSelector(
    internationalizationGlobalSelector,
    (state: InternationalizationStateType): string => {
        if (state.fetching) return ''
        return state.data?.countries?.find((country) => country.isDefault)?.currencyDefault ?? 'USD'
    },
)

export const getContactSettingsSelector = createSelector(
    internationalizationGlobalSelector,
    (state: InternationalizationStateType): ContactUsSettings | undefined => {
        if (state.fetching) return
        return state.data?.countries?.find((country) => country.isDefault)?.defaultRequirement?.contactPageSettings
    },
)
