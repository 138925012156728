import { CurrencyConfig } from '@/types/app-types'
import appLogger from '@/utils/app-logger'

import { AUTO_DETECTED, DEFAULT_CURRENCY } from '../constants'
import { serverSideRead } from '../context'
import { SelectedCurrency } from '../types'

export const getCountryConfigs = async (
    clientIp: string,
    storedCurrency: SelectedCurrency | undefined,
): Promise<CurrencyConfig | undefined> => {
    let currencyConfig: CurrencyConfig | undefined = { storedCurrency, currencies: undefined }

    try {
        const response = await serverSideRead({ clientIp })
        const detectedCurrency =
            response?.countries?.find((country) => country.isDefault)?.currencyDefault || DEFAULT_CURRENCY
        currencyConfig = {
            storedCurrency: storedCurrency || { currency: detectedCurrency, method: AUTO_DETECTED },
            currencies: response,
        }
    } catch (err) {
        console.error((err as Error).message)
        appLogger.logError(new Error(`Unable to fetch country configs: ${(err as Error).message}`))
    } finally {
        return currencyConfig
    }
}
