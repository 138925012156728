import type { Level3MenuItem } from '@/context/performers-navigation/types'

const cities: Level3MenuItem[] = [
    {
        level: 3,
        url: '/region/usa/ga/atlanta-tickets/80/theater',
        label: 'Atlanta Theater',
    },
    {
        level: 3,
        url: '/region/usa/tx/austin-tickets/114/theater',
        label: 'Austin Theater',
    },
    {
        level: 3,
        url: '/region/usa/ma/boston-area-tickets/74/theater',
        label: 'Boston Theater',
    },
    {
        level: 3,
        url: '/region/usa/ny/buffalo-tickets/109/theater',
        label: 'Buffalo Theater',
    },
    {
        level: 3,
        url: '/region/usa/il/chicago-area-tickets/57/theater',
        label: 'Chicago Theater',
    },
    {
        level: 3,
        url: '/region/usa/oh/cleveland-tickets/16/theater',
        label: 'Cleveland Theater',
    },
    {
        level: 3,
        url: '/region/usa/oh/columbus-tickets/15/theater',
        label: 'Columbus Theater',
    },
    {
        level: 3,
        url: '/region/usa/tx/dallas-tickets/19/theater',
        label: 'Dallas Theater',
    },
    {
        level: 3,
        url: '/region/usa/co/denver-tickets/40/theater',
        label: 'Denver Theater',
    },
    {
        level: 3,
        url: '/region/usa/mi/michigan-tickets/61/theater',
        label: 'Detroit Theater',
    },
    {
        level: 3,
        url: '/region/usa/ct/hartford-tickets/73/theater',
        label: 'Hartford Theater',
    },
    {
        level: 3,
        url: '/region/usa/tx/houston-tickets/18/theater',
        label: 'Houston Theater',
    },
    {
        level: 3,
        url: '/region/usa/in/indianapolis-tickets/58/theater',
        label: 'Indianapolis Theater',
    },
    {
        level: 3,
        url: '/region/usa/nv/las-vegas-tickets/33/theater',
        label: 'Las Vegas Theater',
    },
    {
        level: 3,
        url: '/region/usa/ca/los-angeles-tickets/29/theater',
        label: 'Los Angeles Theater',
    },
    {
        level: 3,
        url: '/region/usa/tn/nashville-tickets/56/theater',
        label: 'Nashville Theater',
    },
    {
        level: 3,
        url: '/region/usa/la/louisiana-and-mississippi-tickets/52/theater',
        label: 'New Orleans Theater',
    },
    {
        level: 3,
        url: '/region/usa/ny/new-york-tickets/7/theater',
        label: 'New York City Theater',
    },
    {
        level: 3,
        url: '/region/usa/fl/orlando-tickets/78/theater',
        label: 'Orlando Theater',
    },
    {
        level: 3,
        url: '/region/usa/pa/philadelphia-tickets/72/theater',
        label: 'Philadelphia Theater',
    },
    {
        level: 3,
        url: '/region/usa/or/portland-tickets/27/theater',
        label: 'Portland Theater',
    },
    {
        level: 3,
        url: '/region/usa/tx/san-antonio-tickets/20/theater',
        label: 'San Antonio Theater',
    },
    {
        level: 3,
        url: '/region/usa/ca/san-diego-tickets/85/theater',
        label: 'San Diego Theater',
    },
    {
        level: 3,
        url: '/region/usa/ca/bay-area-tickets/28/theater',
        label: 'San Francisco Theater',
    },
    {
        level: 3,
        url: '/region/usa/wa/seattle-tickets/26/theater',
        label: 'Seattle Theater',
    },
    {
        level: 3,
        url: '/region/usa/dc/washington-dc-and-baltimore-tickets/70/theater',
        label: 'Washington DC Theater',
    },
]

export default cities
