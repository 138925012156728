import type { Level2MenuItem } from '@/context/performers-navigation/types'

import cities from './cities'
import comedy from './comedy'
import family from './family'
import other from './other'
import trending from './trending'

const theater: Level2MenuItem[] = [
    {
        level: 2,
        url: '/theater',
        label: 'Trending',
        id: 'trending',
        footerLink: {
            url: '/theater',
            label: 'View All Theater',
        },
        sublinks: trending,
    },
    {
        level: 2,
        url: '/comedy',
        label: 'Comedy',
        id: 'theater-comedy',
        footerLink: {
            url: '/comedy',
            label: 'View All Comedy',
        },
        sublinks: comedy,
    },
    {
        level: 2,
        url: '/theater/family',
        label: 'Family',
        footerLink: {
            url: '/theater/family',
            label: 'View All Family',
        },
        sublinks: family,
    },
    {
        level: 2,
        url: '/region',
        label: 'Cities',
        footerLink: {
            url: '/region',
            label: 'View All Cities',
        },
        sublinks: cities,
    },
    {
        level: 2,
        url: '/theater',
        label: 'Other',
        id: 'other',
        footerLink: {
            url: '/theater',
            label: 'View All Theater',
        },
        sublinks: other,
    },
]

export default theater
