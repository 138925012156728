export * from './app-types'
export * from './ga4-types'

export interface ErrorEvent {
    error_type: string
    error_message: string
    error_code: string
    error_url: string
}

export const HOME_PAGE_BLOG_CLICK_LOCATION = 'blog'
export const HOME_PAGE_DISCOVER_CATEGORY_CLICK_LOCATION = 'discover category'
export const HOME_PAGE_DISCOVER_ITEM_CLICK_LOCATION = 'discover item'
export const HOME_PAGE_HERO_GET_TICKETS_CLICK_LOCATION = 'hero get tickets'
// confirming this with Evan.
export enum ClickLocation {
    HOME_PAGE_BLOG = 'blog',
    HOME_PAGE_DISCOVER_CATEGORY = 'discover category',
    HOME_PAGE_DISCOVER_ITEM = 'discover item',
    HOME_PAGE_TOP_PICKS = 'top picks',
    HOME_PAGE_EVENTS_UNDER = 'events under',
    HERO_GET_TICKETS = 'hero get tickets',
    REGION_PAGE_TOP_PICKS = 'region page top picks',
    HOME_PAGE_TOP_PICKS_SCROLL_CLICK_LOCATION = 'top picks scroll',
    HOME_PAGE_TOP_PICKS_EXPAND_CLICK_LOCATION = 'top picks expand',
    HOME_PAGE_SHOP_OUR_TOP_CATEGORIES = 'shop our top categories',
    VENUE_PAGE_TOP_PICKS = 'venue page top picks',
}

export interface HomePageEvent {
    page_type: string
    click_location: ClickLocation
    click_text: string
    click_tile_location: number
    carousel_location: number
}

export interface HomePageGetTicketsEvent {
    click_location: string
    click_text: string
}

export interface GeolocationEvent {
    event_type: GeolocationEventEnum | GeolocationEventType
    page_type?: string
}

export interface SelectGeolocationEvent extends GeolocationEvent {
    event_type: GeolocationEventEnum.SELECT | 'select'
    search_term?: string
}

export interface DisplayCurrencyEvent {
    event_type: DisplayCurrencyEventEnum | DisplayCurrencyEventType
    display_currency: string
}

export enum GeolocationEventEnum {
    INIT = 'initialization',
    TOGGLE = 'toggle',
    FOCUS = 'focus',
    TYPE = 'type',
    SELECT = 'select',
    RESET = 'reset',
}

export enum DisplayCurrencyEventEnum {
    INIT = 'initialization',
    TOGGLE = 'toggle',
}

export type GeolocationEventType = `${GeolocationEventEnum}`

export type DisplayCurrencyEventType = `${DisplayCurrencyEventEnum}`

export interface Utm {
    medium?: string
    source?: string
    campaign?: string
    term?: string
    adgroupId?: string
    target?: string
    keywordId?: string
    content?: string
    promo?: string
}

export interface CheckoutEventData {
    // Checkout 2.0 will pass in Production, Listing and quantity in the event data, but the defs for those types are
    // different than in Athena, so for now I'm just letting them be any types instead of duplicating them here.
    // production: Production
    // listing: Listing
    // quantity: number
    [key: string]: any
}

export interface CheckoutEvent {
    type: string
    data: CheckoutEventData
    email?: string
}

export interface PurchaseAuthorizationEvent extends CheckoutEvent {
    purchasedInsuranceAmount: number
}

export enum CONSENT {
    NOT_SPECIFIED = '0',
    ONLY_FUNCTIONAL_NO_TRACKING_OR_ADS = '1',
    YES_TRACKING_NO_ADS = '2',
    PERMIT_ALL = '3',
    PERMIT_REQUIRED = 'permit 1 required',
}

export const PRIVACY_MODAL_WINDOW_EVENT_SOURCE = 'preference_manager'
export enum GAConsent {
    DEFAULT = 'default',
    UPDATE = 'update',
}
export type GTMConsentOption = 'denied' | 'granted'
export interface GTMConsentSettings {
    analytics_storage: GTMConsentOption
    personalization_storage: GTMConsentOption
    ad_storage: GTMConsentOption
    ad_user_data: GTMConsentOption
    ad_personalization: GTMConsentOption
    wait_for_update?: string
    region?: string[]
}
