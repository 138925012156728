import type { MutableRefObject } from 'react'

import type {
    CatalogImageAsset,
    DefaultProviderProps,
    DefaultStateType,
    Performer,
    PerformerImage,
    Production,
    ProductionListSelectorType,
    Venue,
} from '@/types/app-types'

export type CursorStateByCategory = {
    performerSelectState: number | null
    productionSelectState: number | null
    venueSelectState: number | null
}
interface AlgoliaIndexInfoDetail {
    queryId: string
    indexUsed: string
}
export interface AlgoliaIndexInfo {
    performers: AlgoliaIndexInfoDetail
    venues: AlgoliaIndexInfoDetail
    productions: AlgoliaIndexInfoDetail
}
export interface AlgoliaQueryIdSelector {
    performer: string
    venue: string
    production: string
}

export interface SuggestionDataType {
    performers: Array<Performer & { images?: CatalogImageAsset[] }>
    productions: Production[]
    venues: Venue[]
    algoliaIndexInfo: AlgoliaIndexInfo
}

export interface SuggestionStateType extends DefaultStateType<SuggestionDataType> {
    navigating: MutableRefObject<boolean>
    query: string
    cursorPosition: number
    cursorPositionByCategory: CursorStateByCategory
}

export interface SuggestionSelectorType {
    performers: PerformerImage[]
    productionListRows: ProductionListSelectorType[]
    productions: Production[]
    venues: Venue[]
    hasResults: boolean
    algoliaQueryIds: AlgoliaQueryIdSelector
}

export interface SuggestionDispatch {
    setQuery: React.Dispatch<React.SetStateAction<string>>
    setOptions: React.Dispatch<React.SetStateAction<{ includeIpAddress: boolean; radius: number }>>
    setSearchTerm: (value: string) => void
    handleNavigateTrendingItem: (title: string, index: number) => void
    handleNavigateRecentItem: (title: string, index: number) => void
    handleSetCursorPosition: (val: number) => void
    handleGoToSelectedItem: () => void
    handleNavigatePerformerItem: (performer: PerformerImage, index: number) => void
    handleNavigateProductionItem: (production: ProductionListSelectorType, index: number) => void
    handleNavigateVenueItem: (venue: Venue, index: number) => void
}

export interface SuggestionContextType {
    state: SuggestionStateType
    selectors: SuggestionSelectorType
    dispatch: SuggestionDispatch
}

interface SuggestionParams {
    query: string
    regionId?: number
}

export type SuggestionProviderProps = DefaultProviderProps<SuggestionStateType, SuggestionParams>

export enum AlgoliaIndexTypes {
    PERFORMERS = 'performers',
    PRODUCTIONS = 'productions',
    VENUES = 'venues',
}

export interface AlgoliaCookie {
    queryID: string
    positions: number[]
}

export interface SuggestionCursorState {
    resultCursorPosition: number
    resultCursorPositionByCategory: {
        performerSelectState: number | null
        productionSelectState: number | null
        venueSelectState: number | null
    }
}
