import { v4 as uuidv4 } from 'uuid'

const STORE_DOMAIN = 'http://vividseats_sync.com/'
export const SESSION_ID_PREFIX = `b2c-web-`
export const RISKIFIED_BEACON_COOKIE_NAME = 'beaconSessionId'

export const composeSessionId = (): string => `${SESSION_ID_PREFIX}${uuidv4()}`

export const generate24HoursFromNowDate = (): Date => {
    const nowTime = new Date()
    return new Date(nowTime.getTime() + 24 * 60 * 60 * 1000) // 24 hours in milliseconds
}

export const riskifiedBeaconLoad = (beaconSessionId: string, scriptOnloadHandler: () => void): HTMLScriptElement => {
    // most code copied from https://apiref.riskified.com/#beacon
    const url =
        ('https:' == document.location.protocol ? 'https://' : 'http://') +
        'beacon.riskified.com?shop=' +
        STORE_DOMAIN +
        '&sid=' +
        beaconSessionId
    const s = document.createElement('script')
    s.type = 'text/javascript'
    s.async = true
    s.src = url
    s.onload = scriptOnloadHandler // send signal to the hook when this script is completely loaded and `RISKX` is ready to use.
    document.head.appendChild(s) // use this for easier cleanup instead of eventListener 'load' suggested by official doc

    return s
}

export const removeQueryParamsFromUrlPath = (urlPath: string): string => urlPath.replace(/\?.+/i, '')
