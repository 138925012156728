import { CA, UK } from '@/constants'

export const DEFAULT_CURRENCY = 'USD'
export const DEFAULT_CURRENCY_SYMBOL = '$'
export const DEFAULT_LOCALE = 'en-US'
export const AUTO_DETECTED = 'auto-detected'
export const USER_DEFINED = 'user-defined'
export const ROUNDED_CURRENCIES = ['JPY']
export const BUYER_GUARANTEE_EXCLUDED_COUNTRIES = ['United Kingdom']
export const UNITED_KINGDOM = 'United Kingdom'
export const AIP_COUNTRY_CODES = [UK, CA]
