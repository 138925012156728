import type { Level3MenuItem } from '@/context/performers-navigation/types'

const cities: Level3MenuItem[] = [
    {
        level: 3,
        url: '/region/usa/ga/atlanta-tickets/80/sports',
        label: 'Atlanta Sports',
    },
    {
        level: 3,
        url: '/region/usa/tx/austin-tickets/114/sports',
        label: 'Austin Sports',
    },
    {
        level: 3,
        url: '/region/usa/ma/boston-area-tickets/74/sports',
        label: 'Boston Sports',
    },
    {
        level: 3,
        url: '/region/usa/ny/buffalo-tickets/109/sports',
        label: 'Buffalo Sports',
    },
    {
        level: 3,
        url: '/region/usa/il/chicago-area-tickets/57/sports',
        label: 'Chicago Sports',
    },
    {
        level: 3,
        url: '/region/usa/oh/cleveland-tickets/16/sports',
        label: 'Cleveland Sports',
    },
    {
        level: 3,
        url: '/region/usa/oh/columbus-tickets/15/sports',
        label: 'Columbus Sports',
    },
    {
        level: 3,
        url: '/region/usa/tx/dallas-tickets/19/sports',
        label: 'Dallas Sports',
    },
    {
        level: 3,
        url: '/region/usa/co/denver-tickets/40/sports',
        label: 'Denver Sports',
    },
    {
        level: 3,
        url: '/region/usa/mi/michigan-tickets/61/sports',
        label: 'Detroit Sports',
    },
    {
        level: 3,
        url: '/region/usa/ct/hartford-tickets/73/sports',
        label: 'Hartford Sports',
    },
    {
        level: 3,
        url: '/region/usa/tx/houston-tickets/18/sports',
        label: 'Houston Sports',
    },
    {
        level: 3,
        url: '/region/usa/in/indianapolis-tickets/58/sports',
        label: 'Indianapolis Sports',
    },
    {
        level: 3,
        url: '/region/usa/nv/las-vegas-tickets/33/sports',
        label: 'Las Vegas Sports',
    },
    {
        level: 3,
        url: '/region/usa/ca/los-angeles-tickets/29/sports',
        label: 'Los Angeles Sports',
    },
    {
        level: 3,
        url: '/region/usa/tn/nashville-tickets/56/sports',
        label: 'Nashville Sports',
    },
    {
        level: 3,
        url: '/region/usa/la/louisiana-and-mississippi-tickets/52/sports',
        label: 'New Orleans Sports',
    },
    {
        level: 3,
        url: '/region/usa/ny/new-york-tickets/7/sports',
        label: 'New York City Sports',
    },
    {
        level: 3,
        url: '/region/usa/fl/orlando-tickets/78/sports',
        label: 'Orlando Sports',
    },
    {
        level: 3,
        url: '/region/usa/pa/philadelphia-tickets/72/sports',
        label: 'Philadelphia Sports',
    },
    {
        level: 3,
        url: '/region/usa/or/portland-tickets/27/sports',
        label: 'Portland Sports',
    },
    {
        level: 3,
        url: '/region/usa/tx/san-antonio-tickets/20/sports',
        label: 'San Antonio Sports',
    },
    {
        level: 3,
        url: '/region/usa/ca/san-diego-tickets/85/sports',
        label: 'San Diego Sports',
    },
    {
        level: 3,
        url: '/region/usa/ca/bay-area-tickets/28/sports',
        label: 'San Francisco Sports',
    },
    {
        level: 3,
        url: '/region/usa/wa/seattle-tickets/26/sports',
        label: 'Seattle Sports',
    },
    {
        level: 3,
        url: '/region/usa/dc/washington-dc-and-baltimore-tickets/70/sports',
        label: 'Washington DC Sports',
    },
]

export default cities
