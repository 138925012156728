import { Cookies, type CookieSetOptions } from 'react-cookie'

import { USER_LOCATION_KEY } from './constants'

export const CURRENT_DATE = new Date()
const NEXT_NINETY_DAY = new Date(new Date().setDate(new Date().getDate() + 90))
const NEXT_THREE_DAY = new Date(new Date().setDate(new Date().getDate() + 3))

const cookies = new Cookies()

export const getCookieExpiration = (placeId: string | number) => {
    // placeId is set when user change location manualy. In this case we store cookie for 90 days
    return placeId ? NEXT_NINETY_DAY : NEXT_THREE_DAY
}

export const setUserLocationCookie = (locationData: any, placeId: string) => {
    const userLocationCookieOption: CookieSetOptions = { expires: getCookieExpiration(placeId), path: '/' }
    cookies.set(USER_LOCATION_KEY, JSON.stringify(locationData), userLocationCookieOption)
}
