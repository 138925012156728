import debounce from 'lodash/debounce'

import type { ProductionFilterState } from '@/components/pages/production/components/context/types'
import type { ListingsStateType } from '@/context/listings/types'
import type { ComposedListing, Performer, Production, UserData, Venue } from '@/types/app-types'

import type { MyAccountMetadata } from '../../components/layouts/account-layout/type'

import * as braze from './braze'
import * as ga4 from './ga4'
import type { FilterEvent } from './ga4/types'
import { safelyTrack } from './helpers'
import * as segment from './segment'
import type {
    CheckoutEvent,
    ErrorEvent,
    HomePageEvent,
    HomePageGetTicketsEvent,
    GeolocationEvent,
    SelectGeolocationEvent,
    DisplayCurrencyEvent,
} from './types'

export { trackLandingPageView } from './ga4'

export const getReferrer = (): string => {
    return document?.referrer
}

export const initializeAnalytics = safelyTrack((userEmail?: string): void => {
    ga4.initializeGA4()
    segment.initializeSegment(userEmail)
    braze.initializeBraze()

    experimentsReportedToOptimizely = {}
})

export const trackMarketingVisit = safelyTrack((userData: UserData): void => {
    if (!userData.utmSource) return
    if (getReferrer()?.includes('vividseats')) return

    ga4.trackMarketingVisit(userData)
    segment.trackMarketingVisit(userData)
})

export const trackPageView = safelyTrack((pageType: string, pageData?: string | null): void => {
    ga4.trackPageView(pageData || pageType)
    segment.trackPageView(pageType, pageData)
})

export const trackCheckoutPageView = safelyTrack((pageType: string, production: Production, urlPath: string): void => {
    ga4.trackCheckoutPageView(pageType, production, urlPath)
    segment.trackCheckoutPageView(pageType, urlPath)
})

export const trackPerformerPageView = safelyTrack((pageType: string, performers: Performer[]): void => {
    ga4.trackPerformerPageView(pageType, performers)
    segment.trackPerformerPageView(pageType, performers)
})

export const trackVenuePageView = safelyTrack((venue: Venue): void => {
    ga4.trackVenuePageView(venue)
    segment.trackVenuePageView(venue)
})

export const trackProductionPageView = safelyTrack((production: Production): void => {
    ga4.trackProductionPageView(production)
    segment.trackProductionPageView(production)
})

export const trackHomePageEvent = safelyTrack((eventInfo: HomePageEvent | HomePageGetTicketsEvent): void => {
    ga4.trackHomePageEvent(eventInfo)
})

export const trackMyAccountPageEvent = safelyTrack((eventInfo: MyAccountMetadata): void => {
    ga4.trackMyAccountPageEvent(eventInfo)
})

export const trackGeolocationEvent = safelyTrack((eventInfo: GeolocationEvent | SelectGeolocationEvent): void => {
    ga4.trackGeolocationEvent(eventInfo)
})

export const trackDisplayCurrencyEvent = safelyTrack((eventInfo: DisplayCurrencyEvent): void => {
    ga4.trackDisplayCurrencyEvent(eventInfo)
})

export const trackCheckoutEvent = safelyTrack((eventInfo: CheckoutEvent): void => {
    const ga4Event = ga4.trackCheckoutEvent(eventInfo)
    segment.trackCheckoutEvent(ga4Event, eventInfo?.email)
})

export const trackPurchaseAuthorizationEvent = safelyTrack((eventInfo: any): void => {
    ga4.trackPurchaseAuthorizationEvent(eventInfo)
})

export const trackPurchaseErrorEvent = safelyTrack((eventInfo: any): void => {
    ga4.trackPurchaseErrorEvent(eventInfo)
})

export const trackSearchEvent = safelyTrack((pageLocation?: string): void => {
    ga4.trackSearchEvent(pageLocation)
    segment.trackSearchEvent()
})

export const trackFilterEvent = safelyTrack((eventInfo: FilterEvent) => {
    ga4.trackFilterEvent(eventInfo)
})

// filter events can come quickly so debounce the logging
export const trackProductionFilter = debounce(
    safelyTrack((filterState: ProductionFilterState, listingsState: ListingsStateType) => {
        ga4.trackProductionFilter(filterState, listingsState)
    }),
    350,
)

export const trackErrorEvent = safelyTrack((eventInfo: ErrorEvent): void => {
    ga4.trackErrorEvent(eventInfo)
})

export const trackViewPromotion = safelyTrack(
    ({
        item,
        id,
        creative,
        name,
        position,
    }: {
        item: {
            production?: Production
            performer: Performer
        }
        id?: string
        creative?: string
        name?: string
        position?: string
    }): void => {
        ga4.trackViewPromotion({ item, id, creative, name, position })
        segment.trackPromotionViewed(id, creative, name, position, item.performer.id)
    },
)

export const trackViewVenuePromotion = safelyTrack(
    ({
        item,
        id,
        creative,
        name,
        position,
    }: {
        item: { venue: Venue }
        id?: string
        creative?: string
        name?: string
        position?: string
    }): void => {
        segment.trackPromotionViewed(id, creative, name, position, undefined, item.venue.id)
    },
)

export const trackUserExitIntent = safelyTrack(
    ({
        name,
        eventType,
        pageType,
        performerId,
        productionId,
    }: {
        name: string
        eventType: string
        pageType: string
        performerId?: number
        productionId?: number
    }): void => {
        segment.trackUserExitIntent(name, eventType, pageType, performerId, productionId)
    },
)

export const trackViewProductionList = safelyTrack(
    ({ item_list_name = null, productions }: { item_list_name: string | null; productions: Production[] }): void => {
        ga4.trackViewProductionList({ item_list_name, productions })
        segment.trackViewProductionList(item_list_name, productions)
    },
)

// these sessionStorage to track the last production list you clicked on
// if the production id doesn't match the last list, then assume they got to a production from a different method
// these are session that should not persist
// unfortunately, GA4 doesn't save this data automatically so we have to
// put it in a sessionStorage so we don't have to pass IDs around anywhere else
const ITEM_LIST_KEY = 'analytics_item_list_name'
const PRODUCTION_ID_KEY = 'analytics_item_list_production'

export const saveLastItemListName = (item_list_name: string | null, production_id?: number): void => {
    if (typeof window === 'undefined') return

    if (item_list_name == null || production_id == null) {
        sessionStorage.removeItem(ITEM_LIST_KEY)
        sessionStorage.removeItem(PRODUCTION_ID_KEY)
        return
    }

    sessionStorage.setItem(ITEM_LIST_KEY, String(item_list_name))
    sessionStorage.setItem(PRODUCTION_ID_KEY, String(production_id))
}

export const getLastItemListName = (production_id: number): string | null => {
    if (typeof window === 'undefined') return null

    const saved_item_list = sessionStorage.getItem(ITEM_LIST_KEY)
    const saved_production_id = Number(sessionStorage.getItem(PRODUCTION_ID_KEY))

    return saved_production_id == production_id ? saved_item_list : null
}

export const trackSelectProduction = safelyTrack(
    ({
        item_list_name = null,
        index,
        production,
    }: {
        item_list_name: string | null
        index: number
        production: Production
    }): void => {
        saveLastItemListName(item_list_name, production?.id)
        ga4.trackSelectProduction({ item_list_name, index, production })
        segment.trackSelectProduction(item_list_name, index, production)
    },
)
export const trackSelectSearchSuggestion = safelyTrack(
    ({
        name,
        pageType,
        category,
        pageLocation,
        index,
    }: {
        name: string
        pageType: string
        category?: string
        pageLocation: string
        index: number
    }): void => {
        ga4.trackSelectSearchSuggestion({ name, pageType, category, pageLocation, index })
    },
)

export const trackSelectFocusSearchSuggestion = safelyTrack(
    ({
        title,
        focusGroup,
        pageLocation,
        index,
    }: {
        title: string
        focusGroup: string
        pageLocation: string
        index: number
    }): void => {
        ga4.trackSelectFocusSearchSuggestion({ title, focusGroup, pageLocation, index })
    },
)

export const trackViewProduction = safelyTrack(({ production }: { production: Production }): void => {
    const item_list_name = getLastItemListName(production.id)
    ga4.trackViewProduction({ production, item_list_name })
    segment.trackViewProduction(production)
})

export const trackSelectListing = safelyTrack(
    (production: Production, listing: ComposedListing, quantity?: number): void => {
        ga4.trackSelectListing(production, listing, quantity)
    },
)

// optimizely will trigger an experiment hit each time you call usefeature, use this to deduplicate
let experimentsReportedToOptimizely: { [experiment: string]: string } = {}

export const trackOptimizelyExperiment = (experiment: string, variation: string): void => {
    if (!experimentsReportedToOptimizely[experiment]) {
        ga4.trackOptimizelyExperiment(experiment, variation)
        experimentsReportedToOptimizely[experiment] = variation
    }
}

// comes from https://github.com/VividSeats/vivid-web/blob/611e51f7768b74bb7ea29a29f206de8c298f1679/src/main/es6/global/analytics.js#L1065-L1075
// Required for Shopper Approved to works
const eventMap = {
    FILTER_ACTIONS: { event: 'event17', prop: 'prop47', evar: 'eVar58' },
    TICKET_ACTIONS: { event: 'event18', prop: 'prop36', evar: 'eVar69' },
    PRICING_ACTIONS: { event: 'event18', prop: 'prop36', evar: 'eVar69' },
    SIDE_BAR_ACTIONS: { event: 'event19', prop: '', evar: '' },
    VALUE_SCORE_ACTIONS: { event: 'event20', prop: 'prop58', evar: 'eVar76' },
    BUY_BUTTON_ACTIONS: { event: 'event20', prop: 'prop58', evar: 'eVar76' },
    BANNER_ACTIONS: { event: 'event18', prop: 'prop36', evar: 'eVar69' },
    QTIP_ACTIONS: { event: 'event18', prop: 'prop36', evar: 'eVar69' },
    CHECKOUT_FEE_ACTIONS: { event: 'event18', prop: 'prop36', evar: 'eVar69' },
}

const analyticsExports = {
    initializeAnalytics,
    trackMarketingVisit,
    trackPageView,
    trackPerformerPageView,
    trackProductionPageView,
    trackHomePageEvent,
    trackSearchEvent,
    trackFilterEvent,
    trackProductionFilter,
    trackErrorEvent,
    trackViewPromotion,
    trackViewProductionList,
    trackSelectProduction,
    trackViewProduction,
    trackSelectListing,
    trackOptimizelyExperiment,
    trackCheckoutPageView,
    trackPurchaseAuthorizationEvent,
    trackPurchaseErrorEvent,
    trackCheckoutEvent,
    trackGeolocationEvent,
    eventMap,
}

export type Analytics = typeof analyticsExports
export const Analytics = analyticsExports

export default analyticsExports
