import { useEffect, useMemo } from 'react'

import { FullStory, isInitialized, init } from '@fullstory/browser'
import { useCookies } from 'react-cookie'

import { COOKIES } from '@/constants'
import { CONSENT, PRIVACY_MODAL_WINDOW_EVENT_SOURCE } from '@/utils/analytics/types'

import { fullstorySample } from './utils'

export const useInitFullStory = () => {
    const [cookies] = useCookies()

    const isInSample = useMemo(
        () =>
            fullstorySample({
                rate: 20,
                daysValid: 31,
            }),
        [],
    )

    // Registers event listener for consent changes
    useEffect(() => {
        const watchForFullStoryPrivacySettingChanges = (e: MessageEvent) => {
            let data = e.data
            // Attempt to parse data
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data)
                } catch (e) {
                    /* weird message, bail */
                }
            }

            if (data && data.source === PRIVACY_MODAL_WINDOW_EVENT_SOURCE) {
                if (!isInitialized()) {
                    return
                }

                // Consent not specified means consent was withdrawn so we stop tracking
                if (data.data === CONSENT.NOT_SPECIFIED) {
                    FullStory('shutdown')
                }
                // Consent was given so we restart tracking
                FullStory('restart')
            }
        }

        window.addEventListener('message', watchForFullStoryPrivacySettingChanges, false)

        return () => {
            // Clean up event on hook unmount
            window.removeEventListener('message', watchForFullStoryPrivacySettingChanges)
        }
    }, [])

    useEffect(() => {
        if (
            (process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' && !isInSample) ||
            !process.env.NEXT_PUBLIC_FULLSTORY_ORGID
        ) {
            return
        }

        try {
            if (!isInitialized()) {
                const consentDecision = cookies[COOKIES.PRIVACY_COOKIE]

                init({ orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORGID })

                // Shut down full story if user only agrees to required tracking
                if (consentDecision === CONSENT.PERMIT_REQUIRED) {
                    FullStory('shutdown')
                }
            }
        } catch (e) {
            console.error('Something went wrong during FullStory initialization: ' + (e as Error).message)
        }
    }, [cookies, isInSample])
}
