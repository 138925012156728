import { PATHS } from '@/constants'
import type { Level2MenuItem } from '@/context/performers-navigation/types'

const getAccountMenuItems = (isCognitoFeatureEnabled: boolean): Level2MenuItem[] => [
    {
        level: 2,
        url: PATHS.LEGACY_ACCOUNT,
        label: 'Account',
    },
    {
        level: 2,
        url: PATHS.LEGACY_ORDERS,
        label: 'Orders',
    },
    {
        level: 2,
        url: PATHS.LEGACY_SALES,
        label: 'Sales',
    },
    {
        level: 2,
        url: PATHS.LEGACY_PROFILE,
        label: 'Profile',
    },
    {
        level: 2,
        url: isCognitoFeatureEnabled ? PATHS.LOGOUT : PATHS.LEGACY_LOGOUT,
        label: 'Sign Out',
        id: 'account-sign-out',
    },
]

export default getAccountMenuItems
