export interface ErrorEvent {
    error_type: string
    error_message: string
    error_code: string
    error_url: string
}

export interface UserData {
    isAuthenticated?: boolean
    regionId?: number
    regionName?: string
    utmSource?: string
    utmMedium?: string
    utmCampaign?: string
    utmTerm?: string
    utmAdgroup?: string
    utmTarget?: string
    utmContent?: string
    utmPromo?: string
    isDesktop?: boolean
}

export enum PageType {
    Checkout = 'Checkout',
}
