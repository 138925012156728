import type { Level3MenuItem } from '@/context/performers-navigation/types'

const nfl: Level3MenuItem[] = [
    {
        level: 3,
        url: '/nfl-playoff-tickets--sports-nfl-football/custom/140',
        label: 'NFL Playoffs',
    },
    {
        level: 3,
        url: '/afc-playoff-tickets--sports-nfl-football/custom/465',
        label: 'AFC Playoffs',
    },
    {
        level: 3,
        url: '/nfc-playoff-tickets--sports-nfl-football/custom/466',
        label: 'NFC Playoffs',
    },
    {
        level: 3,
        url: '/super-bowl-tickets--sports-nfl-football/performer/1124',
        label: 'Super Bowl',
    },
    {
        level: 3,
        url: '/arizona-cardinals-tickets--sports-nfl-football/performer/40',
        label: 'Arizona Cardinals',
        id: 'sports-nfl-arizona-cardinals',
    },
    {
        level: 3,
        url: '/atlanta-falcons-tickets--sports-nfl-football/performer/51',
        label: 'Atlanta Falcons',
    },
    {
        level: 3,
        url: '/baltimore-ravens-tickets--sports-nfl-football/performer/60',
        label: 'Baltimore Ravens',
    },
    {
        level: 3,
        url: '/buffalo-bills-tickets--sports-nfl-football/performer/128',
        label: 'Buffalo Bills',
    },
    {
        level: 3,
        url: '/carolina-panthers-tickets--sports-nfl-football/performer/144',
        label: 'Carolina Panthers',
    },
    {
        level: 3,
        url: '/chicago-bears-tickets--sports-nfl-football/performer/159',
        label: 'Chicago Bears',
    },
    {
        level: 3,
        url: '/cincinnati-bengals-tickets--sports-nfl-football/performer/172',
        label: 'Cincinnati Bengals',
    },
    {
        level: 3,
        url: '/cleveland-browns-tickets--sports-nfl-football/performer/181',
        label: 'Cleveland Browns',
    },
    {
        level: 3,
        url: '/dallas-cowboys-tickets--sports-nfl-football/performer/214',
        label: 'Dallas Cowboys',
    },
    {
        level: 3,
        url: '/denver-broncos-tickets--sports-nfl-football/performer/234',
        label: 'Denver Broncos',
    },
    {
        level: 3,
        url: '/detroit-lions-tickets--sports-nfl-football/performer/238',
        label: 'Detroit Lions',
    },
    {
        level: 3,
        url: '/green-bay-packers-tickets--sports-nfl-football/performer/339',
        label: 'Green Bay Packers',
    },
    {
        level: 3,
        url: '/houston-texans-tickets--sports-nfl-football/performer/2559',
        label: 'Houston Texans',
    },
    {
        level: 3,
        url: '/indianapolis-colts-tickets--sports-nfl-football/performer/370',
        label: 'Indianapolis Colts',
    },
    {
        level: 3,
        url: '/jacksonville-jaguars-tickets--sports-nfl-football/performer/381',
        label: 'Jacksonville Jaguars',
    },
    {
        level: 3,
        url: '/kansas-city-chiefs-tickets--sports-nfl-football/performer/427',
        label: 'Kansas City Chiefs',
    },
    {
        level: 3,
        url: '/las-vegas-raiders-tickets--sports-nfl-football/performer/626',
        label: 'Las Vegas Raiders',
    },
    {
        level: 3,
        url: '/los-angeles-chargers-tickets--sports-nfl-football/performer/755',
        label: 'Los Angeles Chargers',
    },
    {
        level: 3,
        url: '/los-angeles-rams-tickets--sports-nfl-football/performer/808',
        label: 'Los Angeles Rams',
    },
    {
        level: 3,
        url: '/miami-dolphins-tickets--sports-nfl-football/performer/532',
        label: 'Miami Dolphins',
    },
    {
        level: 3,
        url: '/minnesota-vikings-tickets--sports-nfl-football/performer/555',
        label: 'Minnesota Vikings',
    },
    {
        level: 3,
        url: '/new-england-patriots-tickets--sports-nfl-football/performer/592',
        label: 'New England Patriots',
    },
    {
        level: 3,
        url: '/new-orleans-saints-tickets--sports-nfl-football/performer/597',
        label: 'New Orleans Saints',
    },
    {
        level: 3,
        url: '/new-york-giants-tickets--sports-nfl-football/performer/599',
        label: 'New York Giants',
    },
    {
        level: 3,
        url: '/new-york-jets-tickets--sports-nfl-football/performer/601',
        label: 'New York Jets',
    },
    {
        level: 3,
        url: '/philadelphia-eagles-tickets--sports-nfl-football/performer/669',
        label: 'Philadelphia Eagles',
    },
    {
        level: 3,
        url: '/pittsburgh-steelers-tickets--sports-nfl-football/performer/686',
        label: 'Pittsburgh Steelers',
    },
    {
        level: 3,
        url: '/san-francisco-49ers-tickets--sports-nfl-football/performer/758',
        label: 'San Francisco 49ers',
    },
    {
        level: 3,
        url: '/seattle-seahawks-tickets--sports-nfl-football/performer/772',
        label: 'Seattle Seahawks',
    },
    {
        level: 3,
        url: '/tampa-bay-buccaneers-tickets--sports-nfl-football/performer/839',
        label: 'Tampa Bay Buccaneers',
    },
    {
        level: 3,
        url: '/tennessee-titans-tickets--sports-nfl-football/performer/848',
        label: 'Tennessee Titans',
    },
    {
        level: 3,
        url: '/washington-commanders-tickets--sports-nfl-football/performer/925',
        label: 'Washington Commanders',
    },
]

export default nfl
