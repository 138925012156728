import type { Level3MenuItem } from '@/context/performers-navigation/types'

const mlb: Level3MenuItem[] = [
    {
        level: 3,
        url: '/mlb-spring-training-tickets--sports-mlb-baseball/custom/323',
        label: 'MLB Spring Training',
    },
    {
        level: 3,
        url: '/opening-day-tickets--sports-mlb-baseball/custom/160',
        label: 'MLB Opening Day',
    },
    {
        level: 3,
        url: '/mlb-playoff-tickets--sports-mlb-baseball/custom/1',
        label: 'MLB Playoffs',
    },
    {
        level: 3,
        url: '/world-series-tickets--sports-mlb-baseball/performer/1143',
        label: 'World Series',
    },
    {
        level: 3,
        url: '/mlb-all-star-game-tickets--sports-mlb-baseball/performer/21',
        label: 'MLB All Star Game',
    },
    {
        level: 3,
        url: '/mlb-home-run-derby-tickets--sports-mlb-baseball/performer/1920',
        label: 'MLB Home Run Derby',
    },
    {
        level: 3,
        url: '/arizona-diamondbacks-tickets--sports-mlb-baseball/performer/41',
        label: 'Arizona Diamondbacks',
        id: 'sports-mlb-arizona-diamondbacks',
    },
    {
        level: 3,
        url: '/atlanta-braves-tickets--sports-mlb-baseball/performer/50',
        label: 'Atlanta Braves',
    },
    {
        level: 3,
        url: '/baltimore-orioles-tickets--sports-mlb-baseball/performer/59',
        label: 'Baltimore Orioles',
    },
    {
        level: 3,
        url: '/boston-red-sox-tickets--sports-mlb-baseball/performer/107',
        label: 'Boston Red Sox',
    },
    {
        level: 3,
        url: '/chicago-cubs-tickets--sports-mlb-baseball/performer/162',
        label: 'Chicago Cubs',
    },
    {
        level: 3,
        url: '/chicago-white-sox-tickets--sports-mlb-baseball/performer/164',
        label: 'Chicago White Sox',
    },
    {
        level: 3,
        url: '/cincinnati-reds-tickets--sports-mlb-baseball/performer/173',
        label: 'Cincinnati Reds',
    },
    {
        level: 3,
        url: '/cleveland-guardians-tickets--sports-mlb-baseball/performer/183',
        label: 'Cleveland Guardians',
    },
    {
        level: 3,
        url: '/colorado-rockies-tickets--sports-mlb-baseball/performer/193',
        label: 'Colorado Rockies',
    },
    {
        level: 3,
        url: '/detroit-tigers-tickets--sports-mlb-baseball/performer/241',
        label: 'Detroit Tigers',
    },
    {
        level: 3,
        url: '/houston-astros-tickets--sports-mlb-baseball/performer/358',
        label: 'Houston Astros',
    },
    {
        level: 3,
        url: '/kansas-city-royals-tickets--sports-mlb-baseball/performer/428',
        label: 'Kansas City Royals',
    },
    {
        level: 3,
        url: '/los-angeles-angels-of-anaheim-tickets--sports-mlb-baseball/performer/29',
        label: 'Los Angeles Angels',
    },
    {
        level: 3,
        url: '/los-angeles-dodgers-tickets--sports-mlb-baseball/performer/481',
        label: 'Los Angeles Dodgers',
    },
    {
        level: 3,
        url: '/miami-marlins-tickets--sports-mlb-baseball/performer/293',
        label: 'Miami Marlins',
    },
    {
        level: 3,
        url: '/minnesota-twins-tickets--sports-mlb-baseball/performer/554',
        label: 'Minnesota Twins',
    },
    {
        level: 3,
        url: '/new-york-mets-tickets--sports-mlb-baseball/performer/605',
        label: 'New York Mets',
    },
    {
        level: 3,
        url: '/new-york-yankees-tickets--sports-mlb-baseball/performer/607',
        label: 'New York Yankees',
    },
    {
        level: 3,
        url: '/oakland-athletics-tickets--sports-mlb-baseball/performer/625',
        label: 'Oakland Athletics',
    },
    {
        level: 3,
        url: '/philadelphia-phillies-tickets--sports-mlb-baseball/performer/672',
        label: 'Philadelphia Phillies',
    },
    {
        level: 3,
        url: '/pittsburgh-pirates-tickets--sports-mlb-baseball/performer/685',
        label: 'Pittsburgh Pirates',
    },
    {
        level: 3,
        url: '/san-diego-padres-tickets--sports-mlb-baseball/performer/757',
        label: 'San Diego Padres',
    },
    {
        level: 3,
        url: '/san-francisco-giants-tickets--sports-mlb-baseball/performer/759',
        label: 'San Francisco Giants',
    },
    {
        level: 3,
        url: '/seattle-mariners-tickets--sports-mlb-baseball/performer/771',
        label: 'Seattle Mariners',
    },
    {
        level: 3,
        url: '/st-louis-cardinals-tickets--sports-mlb-baseball/performer/807',
        label: 'St. Louis Cardinals',
    },
    {
        level: 3,
        url: '/tampa-bay-rays-tickets--sports-mlb-baseball/performer/840',
        label: 'Tampa Bay Rays',
    },
    {
        level: 3,
        url: '/texas-rangers-tickets--sports-mlb-baseball/performer/853',
        label: 'Texas Rangers',
    },
    {
        level: 3,
        url: '/toronto-blue-jays-tickets--sports-mlb-baseball/performer/877',
        label: 'Toronto Blue Jays',
    },
    {
        level: 3,
        url: '/washington-nationals-tickets--sports-mlb-baseball/performer/560',
        label: 'Washington Nationals',
    },
]

export default mlb
