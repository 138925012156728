/* istanbul ignore next */
import { COOKIES } from '@/constants'
import { getCookie } from '@/utils/cookies'

import { postTrustarcGetConsentMesssageToWindow, registerForReloadIfConsentIsDenied } from '../helpers'
import { CONSENT } from '../types'

/*
    the goal of this file is to:

    1. Look for the TrustArc privacy cookie.
    2. If the user selected a privacy setting that requires us to turn off destinations,
    3.  Get the list of segment destinations and filter them properly
    4. Initialize segment with the list of allowed destinations
*/

const AUTOMATICALLY_ENROLL_IN_COOKIES = true // default to automatically opt in

declare global {
    interface Window {
        truste: any
    }
}

// returns the raw segment destinations
async function fetchDestinationForWriteKey(writeKey: string) {
    const res = await window.fetch(`https://segment-cdn.vividseats.com/v1/projects/${writeKey}/integrations`)
    if (!res.ok) {
        throw new Error(`Failed to fetch integrations for write key ${writeKey}: HTTP ${res.status} ${res.statusText}`)
    }
    const destinations = await res.json()
    // Rename creationName to id to abstract the weird data model
    for (const destination of destinations) {
        destination.id = destination.creationName
        delete destination.creationName
    }
    return destinations
}

// returns the list of segment destinations and cleans up the data structure
async function fetchDestinations(writeKeys: string[]) {
    const destinationsRequests = []
    for (const writeKey of writeKeys) {
        destinationsRequests.push(fetchDestinationForWriteKey(writeKey))
    }
    let destinations = await Promise.all(destinationsRequests)
    // unique list of destination across all sources
    destinations = [
        ...destinations
            .reduce((a, b) => a.concat(b), []) // flatten multi-d array
            .reduce((map: any, item: any) => {
                if (item.id === 'Repeater') return map // remove repeater
                map.has(item['id']) || map.set(item['id'], item)
                return map
            }, new Map()) // return object
            .values(),
    ]
    return destinations
}

export const initializeSegmentWithPii = async (segmentKey: string) => {
    registerForReloadIfConsentIsDenied()
    postTrustarcGetConsentMesssageToWindow()
    // check for the current consent cookie
    const consentDecision = getCookie(document.cookie, COOKIES.PRIVACY_COOKIE)

    // if there is no cookie or it specifies "include all", then just initialize normally
    if (!consentDecision || consentDecision == '' || consentDecision.includes(CONSENT.PERMIT_ALL)) {
        window.analytics.load(segmentKey)
        return
    }

    // the cookie is set so we need to filter destinations
    const destinations = await fetchDestinations([segmentKey])
    const destinationPreferences: { [x: string]: boolean } =
        destinations
            .map(function (dest) {
                if (consentDecision.includes(CONSENT.YES_TRACKING_NO_ADS))
                    return { [dest.id]: dest.category === 'Advertising' ? false : true }
                if (consentDecision.includes(CONSENT.ONLY_FUNCTIONAL_NO_TRACKING_OR_ADS)) return { [dest.id]: false }
                if (consentDecision.includes(CONSENT.NOT_SPECIFIED))
                    return { [dest.id]: AUTOMATICALLY_ENROLL_IN_COOKIES }
            })
            .reduce((acc, val) => {
                return {
                    ...val,
                    ...acc,
                }
            }) || {}

    // we always need segment to function
    destinationPreferences['Segment.io'] = true

    // load segment with specific integrations
    window.analytics.load(segmentKey, {
        integrations: destinationPreferences,
    })
}
