import type { Level3MenuItem } from '@/context/performers-navigation/types'

const trending: Level3MenuItem[] = [
    {
        level: 3,
        url: '/come-from-away-tickets--theater-musical/performer/41772',
        label: 'Come From Away',
    },
    {
        level: 3,
        url: '/dancing-with-the-stars-tickets--theater-ballet-and-dance/performer/11491',
        label: 'Dancing with the Stars',
    },
    {
        level: 3,
        url: '/dear-evan-hansen-tickets--theater-arts-and-theater/performer/49986',
        label: 'Dear Evan Hansen',
    },
    {
        level: 3,
        url: '/hamilton-tickets--theater-musical/performer/40710',
        label: 'Hamilton',
    },
    {
        level: 3,
        url: '/les-miserables-tickets--theater-musical/performer/461',
        label: 'Les Miserables',
    },
    {
        level: 3,
        url: '/mean-girls-the-musical-tickets--theater-musical/performer/57445',
        label: 'Mean Girls - The Musical',
    },
    {
        level: 3,
        url: '/oprah-winfrey-tickets--theater-public-speaking/performer/1936',
        label: 'Oprah Winfrey',
    },
    {
        level: 3,
        url: '/the-book-of-mormon-tickets--theater-musical/performer/25373',
        label: 'The Book Of Mormon',
    },
    {
        level: 3,
        url: '/the-lion-king-tickets--theater-musical/performer/466',
        label: 'The Lion King',
    },
    {
        level: 3,
        url: '/wicked-tickets--theater-musical/performer/4167',
        label: 'Wicked',
    },
]

export default trending
