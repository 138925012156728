import type { Level3MenuItem } from '@/context/performers-navigation/types'

const other: Level3MenuItem[] = [
    {
        level: 3,
        url: '/theater/ballet-dance',
        label: 'Ballet and Dance',
    },
    {
        level: 3,
        url: '/theater/broadway',
        label: 'Broadway',
    },
    {
        level: 3,
        url: '/theater/cirque',
        label: 'Cirque du Soleil',
    },
    {
        level: 3,
        url: '/theater/musicals',
        label: 'Musical',
    },
    {
        level: 3,
        url: '/theater/public-speaking',
        label: 'Public Speaking',
    },
]

export default other
