import { Level2MenuItem } from '../../types'
import concerts from '../concerts/trending'
import sports from '../sports/trending'
import theater from '../theater/trending'

const trendingItems: Level2MenuItem[] = [
    {
        level: 2,
        url: '/sports',
        label: 'Sports',
        id: 'trendingSports', // this id has reserved keyword "trending" which enforces selected item
        sublinks: sports,
        footerLink: {
            url: '/sports',
            label: 'View All Sports',
        },
    },
    {
        level: 2,
        url: '/concerts',
        label: 'Concerts',
        sublinks: concerts,
        footerLink: {
            url: '/concerts',
            label: 'View All Concerts',
        },
    },
    {
        level: 2,
        url: '/theater',
        label: 'Theater & Comedy',
        sublinks: theater,
        footerLink: {
            url: '/theater',
            label: 'View All Theater',
        },
    },
]

export default trendingItems
