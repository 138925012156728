import type { Level3MenuItem } from '@/context/performers-navigation/types'

const nhl: Level3MenuItem[] = [
    {
        level: 3,
        url: '/nhl-playoffs-tickets--sports-nhl-hockey/performer/1144',
        label: 'NHL Playoffs',
    },
    {
        level: 3,
        url: '/stanley-cup-finals-tickets--sports-nhl-hockey/performer/143164',
        label: 'Stanley Cup Finals',
    },
    {
        level: 3,
        url: '/nhl-winter-classic-tickets--sports-nhl-hockey/performer/20531',
        label: 'NHL Winter Classic',
    },
    {
        level: 3,
        url: '/anaheim-ducks-tickets--sports-nhl-hockey/performer/30',
        label: 'Anaheim Ducks',
        id: 'sports-nhl-anaheim-ducks',
    },
    {
        level: 3,
        url: '/arizona-coyotes-tickets--sports-nhl-hockey/performer/677',
        label: 'Arizona Coyotes',
    },
    {
        level: 3,
        url: '/boston-bruins-tickets--sports-nhl-hockey/performer/104',
        label: 'Boston Bruins',
    },
    {
        level: 3,
        url: '/buffalo-sabres-tickets--sports-nhl-hockey/performer/129',
        label: 'Buffalo Sabres',
    },
    {
        level: 3,
        url: '/calgary-flames-tickets--sports-nhl-hockey/performer/136',
        label: 'Calgary Flames',
    },
    {
        level: 3,
        url: '/carolina-hurricanes-tickets--sports-nhl-hockey/performer/143',
        label: 'Carolina Hurricanes',
    },
    {
        level: 3,
        url: '/chicago-blackhawks-tickets--sports-nhl-hockey/performer/160',
        label: 'Chicago Blackhawks',
    },
    {
        level: 3,
        url: '/colorado-avalanche-tickets--sports-nhl-hockey/performer/191',
        label: 'Colorado Avalanche',
    },
    {
        level: 3,
        url: '/columbus-blue-jackets-tickets--sports-nhl-hockey/performer/979',
        label: 'Columbus Blue Jackets',
    },
    {
        level: 3,
        url: '/dallas-stars-tickets--sports-nhl-hockey/performer/216',
        label: 'Dallas Stars',
    },
    {
        level: 3,
        url: '/detroit-red-wings-tickets--sports-nhl-hockey/performer/240',
        label: 'Detroit Red Wings',
    },
    {
        level: 3,
        url: '/edmonton-oilers-tickets--sports-nhl-hockey/performer/263',
        label: 'Edmonton Oilers',
    },
    {
        level: 3,
        url: '/florida-panthers-tickets--sports-nhl-hockey/performer/1132',
        label: 'Florida Panthers',
    },
    {
        level: 3,
        url: '/los-angeles-kings-tickets--sports-nhl-hockey/performer/482',
        label: 'Los Angeles Kings',
    },
    {
        level: 3,
        url: '/minnesota-wild-tickets--sports-nhl-hockey/performer/1137',
        label: 'Minnesota Wild',
    },
    {
        level: 3,
        url: '/montreal-canadiens-tickets--sports-nhl-hockey/performer/559',
        label: 'Montreal Canadiens',
    },
    {
        level: 3,
        url: '/nashville-predators-tickets--sports-nhl-hockey/performer/573',
        label: 'Nashville Predators',
    },
    {
        level: 3,
        url: '/new-jersey-devils-tickets--sports-nhl-hockey/performer/593',
        label: 'New Jersey Devils',
    },
    {
        level: 3,
        url: '/new-york-islanders-tickets--sports-nhl-hockey/performer/600',
        label: 'New York Islanders',
    },
    {
        level: 3,
        url: '/new-york-rangers-tickets--sports-nhl-hockey/performer/606',
        label: 'New York Rangers',
    },
    {
        level: 3,
        url: '/ottawa-senators-tickets--sports-nhl-hockey/performer/639',
        label: 'Ottawa Senators',
    },
    {
        level: 3,
        url: '/philadelphia-flyers-tickets--sports-nhl-hockey/performer/670',
        label: 'Philadelphia Flyers',
    },
    {
        level: 3,
        url: '/pittsburgh-penguins-tickets--sports-nhl-hockey/performer/684',
        label: 'Pittsburgh Penguins',
    },
    {
        level: 3,
        url: '/san-jose-sharks-tickets--sports-nhl-hockey/performer/761',
        label: 'San Jose Sharks',
    },
    {
        level: 3,
        url: '/seattle-kraken-tickets--sports-nhl-hockey/performer/129459',
        label: 'Seattle Kraken',
    },
    {
        level: 3,
        url: '/st-louis-blues-tickets--sports-nhl-hockey/performer/806',
        label: 'St. Louis Blues',
    },
    {
        level: 3,
        url: '/tampa-bay-lightning-tickets--sports-nhl-hockey/performer/841',
        label: 'Tampa Bay Lightning',
    },
    {
        level: 3,
        url: '/toronto-maple-leafs-tickets--sports-nhl-hockey/performer/878',
        label: 'Toronto Maple Leafs',
    },
    {
        level: 3,
        url: '/vancouver-canucks-tickets--sports-nhl-hockey/performer/909',
        label: 'Vancouver Canucks',
    },
    {
        level: 3,
        url: '/vegas-golden-knights-tickets--sports-nhl-hockey/performer/54228',
        label: 'Vegas Golden Knights',
    },
    {
        level: 3,
        url: '/washington-capitals-tickets--sports-nhl-hockey/performer/923',
        label: 'Washington Capitals',
    },
    {
        level: 3,
        url: '/winnipeg-jets-tickets--sports-nhl-hockey/performer/26544',
        label: 'Winnipeg Jets',
    },
]

export default nhl
