import { AnalyticEvents } from '@/constants'
import { AlgoliaIndexTypes } from '@/context/suggestions/types'
import { clearAlgoliaQueryIdCookie, getAlgoliaQueryIdCookie } from '@/context/suggestions/utils'
import type { Performer, Production, UserData, Venue } from '@/types/app-types'
import { PageType } from '@/types/app-types'
import {
    PERFORMER_RECOMMENDATION_INDEX,
    PRODUCTION_RECOMMENDATION_INDEX,
    VENUE_RECOMMENDATION_INDEX,
} from '@/utils/analytics/algolia'
import { setBrazeAlias } from '@/utils/analytics/braze'
import type { Utm } from '@/utils/analytics/types'
import { getCrmIDCookieValue, getGuestEmailCookieValue, getReturningUserCookieValue } from '@/utils/cookies'
import { getMainPerformer } from '@/utils/production'

import type { CheckoutECommerceEvent, SelectListingItem, ServerInfo, VenueItem } from '../ga4/types'
import { generateSHA1EmailHash } from '../helpers'

import segmentLib from './lib'
import type { SegmentProduct, SegmentData } from './types'

declare global {
    interface Window {
        analytics?: any
    }
}

export const _ready = (f: any): void => {
    window?.analytics?.ready(f)
}

export const _track = (event: string, data: any): void => {
    // need to wait for ready to access traits
    _ready(() => {
        window.analytics.track(event, data, {
            traits: _traits(),
        })
    })
}

export const _page = (category: string, data: any): void => {
    // need to wait for ready to access traits
    _ready(() => {
        window.analytics.page(category || undefined, undefined, data, {
            context: {
                traits: _traits(),
            },
        })
    })
}

export const _identify = (userid?: string, traits?: any): void => {
    // need to wait for ready to access identify
    _ready(() => {
        window.analytics.identify(userid, traits)
    })
}

export const _user = (): any | undefined => {
    return window?.analytics?.user?.()
}

export const _userid = (): string | undefined | null => {
    return _user?.()?.id()
}

export const _anonymousId = (): string => {
    return _user?.()?.anonymousId()
}

export const _traits = (): any | undefined => {
    return _user?.()?.traits()
}

export function _performerToSegmentItem(performers: Performer[] = []) {
    const performerCategory =
        performers.length > 1 ? `${performers[0]?.name} vs. ${performers[1]?.name}` : performers[0]?.name

    return {
        performer_id: performers[0]?.id.toString(),
        performer: performers[0]?.name,
        ...(performers.length > 1 && { performer2: performers[1]?.name }),
        ...(performers.length > 1 && { performer_id_2: performers[1]?.id.toString() }),
        category: performers[0]?.category.name.endsWith('s')
            ? performers[0]?.category.name.toUpperCase().slice(0, -1)
            : performers[0]?.category.name.toUpperCase(),
        category2: performers[0]?.category.subCategories?.[0]?.name,
        category3: performerCategory,
    }
}

function _ga4ItemToSegmentProduct(item: SelectListingItem, index: number): SegmentProduct {
    const {
        item_id = '',
        item_name = '',
        price = 0,
        quantity = 0,
        ticket_id = '',
        performer_id = '',
        item_variant = '',
        item_category = '',
        item_category2 = '',
        item_category3 = '',
        item_category4 = '',
        item_category5 = '',
    } = item

    return {
        // segment fields
        product_id: item_id.toString(),
        sku: item_id.toString(),
        name: item_name,
        price,
        quantity,
        category: item_category,
        variant: item_variant,
        position: item.index || index,

        // custom fields
        item_type: item.item_type,
        category2: item_category2,
        category3: item_category3,
        category4: item_category4,
        category5: item_category5,

        listing_id: ticket_id,
        performer_id: performer_id.toString(),

        // algolia fields
        objectID: performer_id.toString(),
    }
}

interface Ga4DataToSegmentDataProps extends CheckoutECommerceEvent {
    server?: ServerInfo
}

export function _ga4DataToSegmentData(data: Ga4DataToSegmentDataProps): SegmentData {
    const { ecommerce = {}, user, server } = data
    const { items = [] } = ecommerce

    // segment fields
    return {
        // Segment wants order_id as string
        order_id: String(ecommerce.transaction_id || ''),
        affiliation: server?.affiliation,
        value: ecommerce.value,
        revenue: (ecommerce.total || 0) - (ecommerce.shipping || 0) - (ecommerce.tax || 0),
        shipping: ecommerce.shipping,
        tax: ecommerce.tax,
        discount: ecommerce.discount,
        coupon: ecommerce.coupon,
        currency: ecommerce.currency,
        products: items.map(_ga4ItemToSegmentProduct),

        // custom fields
        price_group: ecommerce.price_group,
        email: user?.email,
    }
}

export function _productionToSegmentItem(production: Production) {
    const performers = [getMainPerformer(production)]

    return {
        ..._performerToSegmentItem(performers),

        product_id: production.id.toString(),
        name: production.name,
        sku: production.id.toString(),

        venue: production.venue.name,
        venue_id: production.venue.id.toString(),
        production_city: production.venue.city,
        production_state: production.venue.state,
        production_datetime: production.utcDate,

        category4: production.venue.name,
        category5: production.utcDate,
    }
}

export const initializeSegment = (userEmail?: string): void => {
    const segmentKey = process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY
    if (!segmentKey) return

    // need to execute the segment snippet so we can call methods on it
    // but we want to skip it in test enviroments where the document isn't initialized
    if (typeof window !== undefined && document.body.getElementsByTagName('script').length > 0) {
        segmentLib(segmentKey)
    }
    // This needs to be an anonymous function to allow passing function params to identifyUserToSegment
    _ready(() => identifyUserToSegment(userEmail))
}

export const BRAZE_ALIAS_LABEL = 'segment_id'

export const identifyUserToSegment = (customEmail?: string): void => {
    // based on java hashcode function. provides a reasonable distribution of numbers
    const fastHash = (s: string): number => {
        return Math.abs(Array.from(s).reduce((hash, char) => 0 | (31 * hash + char.charCodeAt(0)), 0))
    }

    // BEST PRACTICES
    // 1. at least at the start of each session, get the identity information from the server so we keep it fresh on this device's local cache
    // 2. whenever you have new information, send it with identify

    // in this case:
    // 1. Pixel.action in legacy web sets these cookies on every request, so we can use them. When pixel.action goes away we need a mechanism to refresh this.
    // 2. Since we're assuming they are fresh we can just identify with them. Once auth happens in web 2.0, you only need to identify at session start/login/profile save.
    const crmUserId = getCrmIDCookieValue()?.toUpperCase()
    const anonymousId = _anonymousId()

    // if the user id has changed on this device, re-identify to segment
    // to kill pixel.action, move these out of cookies and into a service-profile call upon begin session
    if (!!crmUserId && crmUserId !== _userid()) {
        _identify(crmUserId, {
            is_returning_customer: getReturningUserCookieValue(),
        })
    }

    const email = getGuestEmailCookieValue() || customEmail
    if (!!email && email !== _traits()?.email) {
        _identify(crmUserId, { email: email })
    }

    // exchange segment id with braze if we haven't yet
    // ok to call this separately. segment will combine these into a single call
    if (!!anonymousId && !_traits()?.linked_with_braze) {
        if (setBrazeAlias(BRAZE_ALIAS_LABEL, anonymousId)) {
            _identify(crmUserId, { linked_with_braze: true })
        }
    }

    // assign a test group from 1 to 100 based on a hash of their id
    const testGroup = (fastHash(crmUserId || anonymousId) % 100) + 1
    if (testGroup !== _traits()?.test_group) {
        _identify(crmUserId, { test_group: testGroup })
    }
}

export const _createSegmentUtm = (userData: UserData): Utm => {
    return {
        medium: userData.utmMedium,
        source: userData.utmSource,
        campaign: userData.utmCampaign,
        term: userData.utmTerm,
        adgroupId: userData.utmAdgroup,
        target: userData.utmTarget,
        keywordId: /kwd-(\d+)/.exec(userData.utmTarget || '')?.[1],
        content: userData.utmContent,
        promo: userData.utmPromo || undefined,
    }
}

export const trackMarketingVisit = (userData: UserData): void => {
    _track('Marketing Visit Started', _createSegmentUtm(userData))
}

export const trackPageView = (category: string, pageData?: string | null): void => {
    _page(category, pageData || {})
}

export const trackCheckoutPageView = (pageType: string, urlPath?: string): void => {
    _page(pageType, urlPath)
}

export const trackSelectPerformer = (position: number, performer?: Performer) => {
    const queryIdCookie = getAlgoliaQueryIdCookie(AlgoliaIndexTypes.PERFORMERS)
    _track('Performer Clicked', {
        ...(performer && _performerToSegmentItem([performer])),
        eventType: 'click',
        index: PERFORMER_RECOMMENDATION_INDEX,
        position: position + 1, // Aglolia starts counting at 1 instead of 0 for this property
        ...(performer && { objectIDs: [`${performer.id}`] }),
        ...(queryIdCookie?.queryID && { queryID: queryIdCookie.queryID }),
    })
}

export const trackPerformerPageView = (category: string, performers: Performer[]): void => {
    const data = _performerToSegmentItem(performers)

    _page(category, data)

    _track(`${category} Viewed`, {
        ...data,
        eventType: 'view',
        index: PERFORMER_RECOMMENDATION_INDEX,
        objectIDs: performers.map((performer) => `${performer.id}`),
    })
}

export const trackVenuePageView = (venue: Venue): void => {
    const venueItemPayload: VenueItem = {
        venue_id: `${venue.id}`,
        venue_name: venue.name,
    }

    _page(PageType.Venue, venueItemPayload)
    _track(`${PageType.Venue} Viewed`, {
        eventType: 'click',
        index: VENUE_RECOMMENDATION_INDEX,
        objectIDs: [`${venue.id}`],

        ...venueItemPayload,
    })
}

export const trackProductionPageView = (production: Production): void => {
    _page('Production', _productionToSegmentItem(production))

    // there isn't a separate production viewed event so don't track here
}

export const trackSearchEvent = (): void => {
    _track('Products Searched', {})
}

export const trackPromotionViewed = (
    id?: string,
    creative?: string,
    name?: string,
    position?: string,
    performerId?: number,
    venueId?: number,
): void => {
    _track('Promotion Viewed', {
        promotion_id: id,
        creative,
        name,
        position,
        ...(performerId && { performer_id: performerId }),
        ...(venueId && { venue_id: venueId }),
    })
}

export const trackSearch = (searchTerm?: string): void => _track('Search', { searchTerm })

export const trackViewProductionList = (item_list_name: string | null, productions: Production[]): void => {
    // no marketing value in sending empty lists to Segment
    // NOTE: for GA4 we DO want to know if there is an empty list
    if (!productions || productions.length == 0) return

    _track('Product List Viewed', {
        index: PRODUCTION_RECOMMENDATION_INDEX,
        eventType: 'view',
        objectIDs: productions.map((p) => `${p.id}`),
        list_id: item_list_name,
        products: productions.map(_productionToSegmentItem),
    })
}

export const trackSelectProduction = (item_list_name: string | null, index: number, production: Production): void => {
    _track('Product Clicked', {
        ..._productionToSegmentItem(production),
        index: PERFORMER_RECOMMENDATION_INDEX,
        objectIDs: production.performers.map((performer) => `${performer.id}`),
        eventType: 'click',
    })
}

export const trackViewProduction = (production: Production): void => {
    _track('Product Viewed', {
        ..._productionToSegmentItem(production),
        index: PERFORMER_RECOMMENDATION_INDEX,
        objectIDs: production.performers.map((performer) => `${performer.id}`),
        eventType: 'view',
    })
}

export const trackEmailLeadCapture = async (userEmail: string): Promise<void> => {
    const sha1Email = await generateSHA1EmailHash(userEmail)
    _identify(sha1Email, { email: userEmail, lead_capture_email: 'homepage' })
}

export const trackUserExitIntent = (
    name: string,
    eventType: string,
    pageType: string,
    performerId?: number,
    productionId?: number,
): void => {
    _track('Exit_Intent', {
        name,
        event_type: eventType,
        page_type: pageType,
        performer_id: performerId,
        product_id: productionId,
    })
}

export const trackCheckoutEvent = (eventInfo: CheckoutECommerceEvent, email?: string): void => {
    const { event } = eventInfo
    switch (event) {
        case AnalyticEvents.BeginCheckout:
            _track('Checkout Started', _ga4DataToSegmentData(eventInfo))
            break
        case AnalyticEvents.SignUp:
            identifyUserToSegment(email)
            break
        case AnalyticEvents.CheckoutLogin:
            identifyUserToSegment(email)
            _track('Checkout Step Completed', { step: 1, step_name: 'Login Completed' })
            break
        case AnalyticEvents.AddShippingInfo:
            _track('Checkout Step Completed', { step: 2, step_name: 'Shipping Added' })
            break
        case AnalyticEvents.AddPaymentInfo:
            _track('Checkout Step Completed', { step: 3, step_name: 'Payment Added' })
            break
        case AnalyticEvents.SelectInsurance:
            _track('Checkout Step Completed', { step: 4, step_name: 'Insurance Selected' })
            break
        case AnalyticEvents.AddToCart:
            _track('Product Added', _ga4DataToSegmentData(eventInfo))
            break
        case AnalyticEvents.Purchase:
            const performersQueryIdCookie = getAlgoliaQueryIdCookie(AlgoliaIndexTypes.PERFORMERS)
            identifyUserToSegment(email)
            _track('Order Completed', {
                index: PERFORMER_RECOMMENDATION_INDEX,
                eventType: 'conversion',
                objectIDs: eventInfo?.ecommerce?.items?.map(
                    (p: SelectListingItem, i: number) => _ga4ItemToSegmentProduct(p, i).objectID,
                ),
                ...(performersQueryIdCookie?.queryID && { queryID: performersQueryIdCookie.queryID }),
                ..._ga4DataToSegmentData(eventInfo),
            })
            clearAlgoliaQueryIdCookie()
            break
    }
}
