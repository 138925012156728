import type { Level3MenuItem } from '@/context/performers-navigation/types'

const comedy: Level3MenuItem[] = [
    {
        level: 3,
        url: '/andrew-schulz-tickets--theater-comedy/performer/34614',
        label: 'Andrew Schulz',
    },
    {
        level: 3,
        url: '/april-fools-comedy-jam-tickets--theater-comedy/performer/47145',
        label: 'April Fools Comedy Jam',
    },
    {
        level: 3,
        url: '/bert-kreischer-tickets--theater-comedy/performer/23933',
        label: 'Bert Kreischer',
    },
    {
        level: 3,
        url: '/bill-maher-tickets--theater-comedy/performer/2057',
        label: 'Bill Maher',
    },
    {
        level: 3,
        url: '/brian-regan-tickets--theater-comedy/performer/5637',
        label: 'Brian Regan',
    },
    {
        level: 3,
        url: '/carol-burnett-tickets--theater-comedy/performer/141',
        label: 'Carol Burnett',
    },
    {
        level: 3,
        url: '/chris-tucker-tickets--theater-comedy/performer/9443',
        label: 'Chris Tucker',
    },
    {
        level: 3,
        url: '/david-spade-tickets--theater-comedy/performer/2682',
        label: 'David Spade',
    },
    {
        level: 3,
        url: '/dude-perfect-tickets--theater-comedy/performer/106064',
        label: 'Dude Perfect',
    },
    {
        level: 3,
        url: '/festival-of-laughs-tickets--theater-comedy/performer/33476',
        label: 'Festival of Laughs',
    },
    {
        level: 3,
        url: '/fortune-feimster-tickets--theater-comedy/performer/40438',
        label: 'Fortune Feimster',
    },
    {
        level: 3,
        url: '/franco-escamilla-tickets--theater-comedy/performer/52845',
        label: 'Franco Escamilla',
    },
    {
        level: 3,
        url: '/gabriel-iglesias-tickets--theater-comedy/performer/3916',
        label: 'Gabriel Iglesias',
    },
    {
        level: 3,
        url: '/george-lopez-tickets--theater-comedy/performer/2209',
        label: 'George Lopez',
    },
    {
        level: 3,
        url: '/heather-mcmahan-tickets--theater-comedy/performer/113547',
        label: 'Heather McMahan',
    },
    {
        level: 3,
        url: '/iliza-shlesinger-tickets--theater-comedy/performer/21079',
        label: 'Iliza Shlesinger',
    },
    {
        level: 3,
        url: '/impractical-jokers-tickets--theater-comedy/performer/31826',
        label: 'Impractical Jokers',
    },
    {
        level: 3,
        url: '/jay-leno-tickets--theater-comedy/performer/1311',
        label: 'Jay Leno',
    },
    {
        level: 3,
        url: '/jeanne-robertson-tickets--theater-comedy/performer/24846',
        label: 'Jeanne Robertson',
    },
    {
        level: 3,
        url: '/jeff-dunham-tickets--theater-comedy/performer/2660',
        label: 'Jeff Dunham',
    },
    {
        level: 3,
        url: '/jerry-seinfeld-tickets--theater-comedy/performer/394',
        label: 'Jerry Seinfeld',
    },
    {
        level: 3,
        url: '/jim-gaffigan-tickets--theater-comedy/performer/8165',
        label: 'Jim Gaffigan',
    },
    {
        level: 3,
        url: '/jo-koy-tickets--theater-comedy/performer/18448',
        label: 'Jo Koy',
    },
    {
        level: 3,
        url: '/joe-rogan-tickets--theater-comedy/performer/7971',
        label: 'Joe Rogan',
    },
    {
        level: 3,
        url: '/kathleen-madigan-tickets--theater-comedy/performer/7621',
        label: 'Kathleen Madigan',
    },
    {
        level: 3,
        url: '/kevin-james-tickets--theater-comedy/performer/438',
        label: 'Kevin James',
    },
    {
        level: 3,
        url: '/larry-the-cable-guy-tickets--theater-comedy/performer/4060',
        label: 'Larry the Cable Guy',
    },
    {
        level: 3,
        url: '/leanne-morgan-tickets--theater-comedy/performer/48042',
        label: 'Leanne Morgan',
    },
    {
        level: 3,
        url: '/lewis-black-tickets--theater-comedy/performer/2615',
        label: 'Lewis Black',
    },
    {
        level: 3,
        url: '/lit-af-tour-tickets--theater-comedy/performer/88557',
        label: 'Lit AF Tour',
    },
    {
        level: 3,
        url: '/miranda-sings-tickets--theater-comedy/performer/32951',
        label: 'Miranda Sings',
    },
    {
        level: 3,
        url: '/nate-bargatze-tickets--theater-comedy/performer/47996',
        label: 'Nate Bargatze',
    },
    {
        level: 3,
        url: '/nikki-glaser-tickets--theater-comedy/performer/35141',
        label: 'Nikki Glaser',
    },
    {
        level: 3,
        url: '/penn-and-teller-tickets--theater-comedy/performer/656',
        label: 'Penn and Teller',
    },
    {
        level: 3,
        url: '/ricky-gervais-tickets--theater-comedy/performer/12818',
        label: 'Ricky Gervais',
    },
    {
        level: 3,
        url: '/rodney-carrington-tickets--theater-comedy/performer/2420',
        label: 'Rodney Carrington',
    },
    {
        level: 3,
        url: '/ron-white-tickets--theater-comedy/performer/5062',
        label: 'Ron White',
    },
    {
        level: 3,
        url: '/russell-howard-tickets--theater-comedy/performer/18779',
        label: 'Russell Howard',
    },
    {
        level: 3,
        url: '/schitts-creek-tickets--theater-comedy/performer/88966',
        label: 'Schitts Creek',
    },
    {
        level: 3,
        url: '/sebastian-maniscalco-tickets--theater-comedy/performer/29424',
        label: 'Sebastian Maniscalco',
    },
    {
        level: 3,
        url: '/small-town-murder-tickets--theater-comedy/performer/71509',
        label: 'Small Town Murder',
    },
    {
        level: 3,
        url: '/steve-martin-tickets--theater-comedy/performer/19638',
        label: 'Steve Martin',
    },
    {
        level: 3,
        url: '/the-fabulously-funny-comedy-festival-tickets--theater-comedy/performer/119581',
        label: 'The Fabulously Funny Comedy Festival',
    },
    {
        level: 3,
        url: '/tim-allen-tickets--theater-comedy/performer/22776',
        label: 'Tim Allen',
    },
    {
        level: 3,
        url: '/tiny-meat-gang-tickets--theater-comedy/performer/95881',
        label: 'Tiny Meat Gang',
    },
    {
        level: 3,
        url: '/tom-segura-tickets--theater-comedy/performer/36221',
        label: 'Tom Segura',
    },
    {
        level: 3,
        url: '/tracy-morgan-tickets--theater-comedy/performer/5319',
        label: 'Tracy Morgan',
    },
    {
        level: 3,
        url: '/trevor-noah-tickets--theater-comedy/performer/37098',
        label: 'Trevor Noah',
    },
    {
        level: 3,
        url: '/whitney-cummings-tickets--theater-comedy/performer/23340',
        label: 'Whitney Cummings',
    },
    {
        level: 3,
        url: '/whose-live-anyway-tickets--theater-comedy/performer/15598',
        label: 'Whose Live Anyway',
    },
]

export default comedy
