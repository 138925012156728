import { AUTH_HEADERS, COGNITO_LOGOUT_ENDPOINT, COOKIES } from '@/constants'
import { AUTH_HEADER_VALUES, AuthCookie, ObjectWithKeyIteration } from '@/types/app-types'
import appLogger from '@/utils/app-logger'

import { hermesService } from './factory'

export const getAuthHeaderValues = (cookies: ObjectWithKeyIteration): AUTH_HEADER_VALUES | undefined => {
    const rawAuthCookie = cookies[COOKIES.AUTH_TOKEN_COOKIE]
    const authCookie = `at=${encodeURIComponent(rawAuthCookie)}`
    let accountId = '',
        authToken = '',
        refreshToken = ''

    try {
        const parsedCookie = JSON.parse(rawAuthCookie)
        accountId = parsedCookie.accountId || ''
        authToken = parsedCookie.token || ''
        refreshToken = parsedCookie.refreshToken || ''
    } catch {
        return
    }

    return { accountId, authToken, authCookie, refreshToken }
}

export const buildAuthHeaders = (authCookie: AuthCookie) => {
    return {
        [AUTH_HEADERS.X_ACCOUNT]: authCookie.accountId,
        [AUTH_HEADERS.X_AUTH_TOKEN]: authCookie.token,
        [AUTH_HEADERS.X_LEGACY_AUTH_COOKIE]: `${COOKIES.AUTH_TOKEN_COOKIE}=${encodeURIComponent(
            JSON.stringify(authCookie),
        )}`,
    }
}

export const logoutCognitoAccount = async (refreshToken: string) => {
    try {
        return await hermesService.delete(COGNITO_LOGOUT_ENDPOINT, {
            headers: { [AUTH_HEADERS.X_REFRESH_TOKEN]: refreshToken },
        })
    } catch (e) {
        console.error((e as Error).message)
        return Promise.reject(e)
    }
}

/**
 * Token expires at date = baseTimestamp + expires in seconds
 * Returns the UNIX timestamp representation of the date for reliable storage
 */
export const getTokenExpiryDate = (baseTimestamp: number, expiresIn: number): number => {
    return baseTimestamp + 1000 * expiresIn
}

/**
 * Checks if the axios request origin is different from the baseURL configured in the axios client
 *
 * By default this function returns false since config.url is generally only the path component of
 * the request URL, unless an absolute URL is provided to axios
 *
 * The current behavior of axios as of v1.7.2 is to silently ignores the baseURL configured in the
 * axios client if an absolute URL is provided for a request: https://github.com/axios/axios/issues/5902
 */
export const isCrossOriginRequest = (requestUrl: string, requestBaseUrl: string): boolean => {
    try {
        const parsedRequestUrl = new URL(requestUrl)
        const isCrossOrigin = parsedRequestUrl.origin !== requestBaseUrl

        if (isCrossOrigin) {
            appLogger.logError(new Error(`Absolute cross origin URL used in hermes service: ${requestUrl}`))
        }

        return isCrossOrigin
    } catch {
        // no-op
    }

    return false
}
