import { ReactNode } from 'react'

import { DefaultStateType } from '@/types/app-types'

/**
 * A UTM code is a simple code that you can attach to a URL in order to track a
 * campaign. VividSeats uses UTM code as a second validation to promo codes.
 * Users will receive an email or notification with a link that contains the
 * embedded UTM promo. When the user taps on the received link, they will be
 * forwarded to either the website or mobile app with the link added to their
 * session.
 *
 * @see https://vividseats.atlassian.net/wiki/spaces/TEC/pages/482017693/Rewards+Multiplier+Promo+Design
 */
export enum PromoAmountTypes {
    /**
     * Discount a fixed dollar amount from the final checkout price.
     */
    DOLLAR = 'DOLLAR',
    /**
     * Discount a percentage of the price of each ticket.
     */
    PERCENT = 'PERCENT',
    /**
     * Bonus cash back earned when earning credits.
     */
    LOYALTY_MULTIPLIER = 'LOYALTY_MULTIPLIER',
}

export interface PromoResponse {
    /**
     * Max dollar off for PERCENT or DOLLAR, or max bonus credit earned from
     * LOYALTY_MULTIPLIER.
     */
    readonly maxAmount: number
    /**
     * Amount discount/credit earned on promo. Depends on
     * {@link PromoResponse['amountType']}.
     */
    readonly amount: number
    /**
     * Minimum order subtotal (cost of tickets) for eligibility.
     */
    readonly minTotal: number
    /**
     * Whether or not the UTM promo code is expired.
     */
    readonly expired: boolean
    /**
     * The UTM promo code.
     */
    readonly promoCode: string
    /**
     * Is `true` if the promo code is 1-time use.
     */
    readonly unique: boolean
    /**
     * Is `true` if the promo code has already been applied to an order.
     */
    readonly used: boolean
    /**
     * Is `true` if the promo code can only be redeemed in native app.
     */
    readonly nativeAppOnly: boolean
    /**
     * Promo code expiration datetime in ISO format `yyyy-MM-dd'T'HH:mm:ss.SSSXXX`
     */
    readonly expiration: string
    /**
     * One of `'PERCENT'`, `'DOLLAR'`, or `'LOYALTY_MULTIPLIER'`
     */
    readonly amountType: PromoAmountTypes
}

export interface PromoSelectors {
    readonly promo: PromoResponse | undefined
}

export interface PromoDispatch {
    readonly refetch: () => any
}

export type PromoState = DefaultStateType<PromoResponse | undefined>

export interface PromoContext {
    readonly state: PromoState
    readonly selectors: PromoSelectors
    readonly dispatch: PromoDispatch
}

export interface PromoProviderInitialParams {
    /**
     * A promo trigger attribute is a string concisely summarizing the type of
     * promo being applied (e.g. `'3341-50-percent-200'`) provided via url
     * query param "utm_promo".
     */
    readonly promoTriggerAttr: string
}

interface PromoProviderInitialProps {
    readonly data?: PromoResponse
}

export interface PromoProviderProps {
    readonly children: ReactNode
    readonly initialParams: PromoProviderInitialParams
    readonly initialProps?: PromoProviderInitialProps
}
