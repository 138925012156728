import { datadogRum } from '@datadog/browser-rum'
import * as Sentry from '@sentry/nextjs'

/**
 * This is a wrapper class for *whichever* logging service we decide to use.
 * The original motivation was to pass this logger into the checkout 2.0 module but
 * this is also a pattern we need to start moving towards to avoid large scale refactors
 * when swapping underlying services for new ones.
 */

export interface AppLogger {
    logError: (error: Error) => void
    logInfo: (message: string) => void
}

export class Logger implements AppLogger {
    logError(error: Error): void {
        Sentry.captureException(error)
        datadogRum.addError(error)
    }
    logInfo(message: string): void {
        Sentry.captureMessage(message)
    }
}

export default new Logger()
