import React, { $FC } from 'react'

import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
        },
    },
})

const QueryProvider: $FC<{ shouldClearCache?: boolean }> = ({ children, shouldClearCache }) => {
    if (shouldClearCache) {
        queryClient.clear()
    }

    return <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
}

export default QueryProvider
