export * from './dates'
export * from './responsive'
export * from './cookies'
export * from './promo'

export const flatten = <TItem = unknown>(array: TItem[][]): TItem[] => {
    return array.reduce((a, b) => a.concat(b))
}

export const isRunningOnServer = (): boolean => {
    return typeof window === 'undefined'
}
