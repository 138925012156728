import { initializeSegmentWithPii } from './pii'

const needsPrivacy = true

export default function segmentLib(segmentkey: string) {
    const analytics = (window.analytics = window.analytics || [])
    if (!analytics.initialize)
        if (analytics.invoked) window.console && console.error && console.error('Segment snippet included twice.')
        else {
            analytics.invoked = !0
            analytics.methods = [
                'trackSubmit',
                'trackClick',
                'trackLink',
                'trackForm',
                'pageview',
                'identify',
                'reset',
                'group',
                'track',
                'ready',
                'alias',
                'debug',
                'page',
                'once',
                'off',
                'on',
                'addSourceMiddleware',
                'addIntegrationMiddleware',
                'setAnonymousId',
                'addDestinationMiddleware',
            ]
            analytics.factory = function (e: any) {
                return function (...args: any[]) {
                    const t = Array.prototype.slice.call(args)
                    t.unshift(e)
                    analytics.push(t)
                    return analytics
                }
            }
            for (let e = 0; e < analytics.methods.length; e++) {
                const key = analytics.methods[e]
                analytics[key] = analytics.factory(key)
            }
            analytics.load = function (key: any, e: any) {
                const t = document.createElement('script')
                t.type = 'text/javascript'
                t.async = !0
                t.src = 'https://segment-cdn.vividseats.com/analytics.js/v1/' + key + '/analytics.min.js'
                const n = document.getElementsByTagName('script')[0]
                n.parentNode?.insertBefore(t, n)
                analytics._loadOptions = e
                analytics._cdn = 'https://segment-cdn.vividseats.com'
            }
            analytics._writeKey = segmentkey
            analytics.SNIPPET_VERSION = '4.15.2'

            if (needsPrivacy) initializeSegmentWithPii(segmentkey)
            else analytics.load(segmentkey)
        }
}
