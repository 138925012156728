export const COUNTRIES = ['Canada', 'United States']
export const USER_LOCATION_KEY = 'user-location'
export const AUTO_DETECTED = 'auto-detected'
export const USER_DEFINED = 'user-defined'
export const DEFAULT_LOCATION = {
    latitude: 41.8781136,
    longitude: -87.6297982,
    countryCode: 'US',
    region: 'IL',
    city: 'Chicago',
    countryName: 'United States',
    method: USER_DEFINED,
}
export const DEFAULT_CONTEXT_SELECTOR = {
    city: 'Chicago',
    latLong: '41.8781;87.6298',
    region: 'IL',
    country: 'United States',
    dropdownName: 'Chicago, IL',
    detectedCountryName: 'United States',
}
