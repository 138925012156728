import { PATHS } from '@/constants'
import type { Level1MenuItem } from '@/context/performers-navigation/types'

import getAccountMenuItems from './account'
import concerts from './concerts'
import sports from './sports'
import theater from './theater'
import trending from './trending'

const getMainMenuItems = (isCognitoFeatureEnabled: boolean): Level1MenuItem[] => [
    {
        level: 1,
        url: '',
        label: 'Trending',
        id: 'trendingCategory',
        sublinks: trending,
    },
    {
        level: 1,
        url: '/sports',
        label: 'Sports',
        id: 'sports',
        sublinks: sports,
    },
    {
        level: 1,
        url: '/concerts',
        label: 'Concerts',
        sublinks: concerts,
    },
    {
        level: 1,
        url: '/theater',
        label: 'Theater & Comedy',
        sublinks: theater,
    },
    {
        level: 1,
        url: '',
        label: 'Currency',
        sublinks: [],
    },
    {
        level: 1,
        url: PATHS.ACCOUNT,
        label: 'My Account',
        id: 'account',
        sublinks: getAccountMenuItems(isCognitoFeatureEnabled),
    },
    {
        level: 1,
        url: isCognitoFeatureEnabled ? PATHS.LOGIN : PATHS.LEGACY_LOGIN,
        label: '',
        id: 'desktop-auth-sign-in',
        shouldRenderOnlyOnDesktop: true,
        sublinks: [],
    },
    {
        level: 1,
        url: isCognitoFeatureEnabled ? PATHS.LOGIN : PATHS.LEGACY_LOGIN,
        label: 'Sign In',
        id: 'mobile-auth-sign-in',
        shouldRenderOnlyOnMobile: true,
        sublinks: [],
    },
    {
        level: 1,
        url: isCognitoFeatureEnabled ? PATHS.LOGOUT : PATHS.LEGACY_LOGOUT,
        label: 'Sign Out',
        id: 'mobile-auth-sign-out',
        shouldRenderOnlyOnMobile: true,
        sublinks: [],
    },
]

export default getMainMenuItems
