import type { ComposedListing, ListingsGroup } from '@/types/app-types'

import { ProductionFilterStateLocalStorage } from '../../types'

export const enum ActionType {
    ON_TYPE_FILTER_CHANGE,
    ON_QUANTITY_FILTER_CHANGE,
    ON_SORT_FILTER_CHANGE,
    ON_PRICE_FILTER_CHANGE,
    RESET_FILTERS,
    RESET_FILTERS_EXCEPT_SECTIONS,
    RESET_PRICE,
    ON_SELECT_SECTION,
    ON_HIGHLIGHT_SECTION,
    ON_SHOW_ALL_IN_PRICE_CHANGE,
    RESET_SECTION_SELECTION,
    INIT_FILTER,
    ON_SELECT_SIMILAR_SECTION,
    ON_SET_SHOW_SIMILAR_SNACKBAR_VISIBILITY,
    ON_ZONE_HIGHLIGHT,
    ON_CURRENCY_UPDATED,
    ON_SELECT_ROW,
}

export enum SortFilterNames {
    PRICE,
    PRICE_HIGH,
    ROW,
    SECTION,
}

export interface TypeFilterName {
    name:
        'greatDeal' | 
        'superSeller' | 
        'instantDelivery' | 
        'recentlyViewed' | 
        'frontOfSection' | 
        'clubSeats' | 
        'suites' | 
        'familyNonAlc' | 
        'standingRoom' | 
        'unobstructedView' | 
        'unlimitedFoodAndBeverage' |
        'parking' |
        'foodBeverageVoucher' | 
        'clubAccess' | 
        'vip' | 
        'memorabilia' |
        'meetAndGreet'
}

export interface OnTypeFilterChangeAction {
    type: ActionType.ON_TYPE_FILTER_CHANGE
    payload: {
        filterName: TypeFilterName
        filterValue: boolean
    }
}

export interface OnQuantityFilterChangeAction {
    type: ActionType.ON_QUANTITY_FILTER_CHANGE
    payload: {
        quantity: number
    }
}
export interface OnSortFilterChangeAction {
    type: ActionType.ON_SORT_FILTER_CHANGE
    payload: {
        name: SortFilterNames
    }
}

export interface OnPriceFilterChangeAction {
    type: ActionType.ON_PRICE_FILTER_CHANGE
    payload: {
        field: string
        value: number
        shouldTrackFilterChange: boolean
    }
}

export interface ResetFiltersAction {
    type: ActionType.RESET_FILTERS
}

export interface ResetFiltersExceptSectionsAction {
    type: ActionType.RESET_FILTERS_EXCEPT_SECTIONS
}

export interface ResetPriceFiltersAction {
    type: ActionType.RESET_PRICE
}

export interface OnSelectSectionAction {
    type: ActionType.ON_SELECT_SECTION
    payload: SectionDefinition
}

export interface OnSelectRowAction {
    type: ActionType.ON_SELECT_ROW
    payload: {
        rowId: string
    }
}

export interface OnHighlightSectionAction {
    type: ActionType.ON_HIGHLIGHT_SECTION
    payload: SectionDefinition
}

export interface OnShowAllInPriceChangeAction {
    type: ActionType.ON_SHOW_ALL_IN_PRICE_CHANGE
}

export interface ResetSectionAction {
    type: ActionType.RESET_SECTION_SELECTION
}

export interface InitFilterAction {
    type: ActionType.INIT_FILTER
    payload: {
        initialFilter: ProductionFilterStateLocalStorage | null
        currentProductionId: number
        showAip: boolean
    }
}
export interface SelectSimilarAction {
    type: ActionType.ON_SELECT_SIMILAR_SECTION
    payload?: { groupId: string; selected: boolean }
}

export interface ZoneHighlightAction {
    type: ActionType.ON_ZONE_HIGHLIGHT
    payload: { groupId: string; highlighted: boolean }
}

export interface SelectSimilarSnackbarVisibilityAction {
    type: ActionType.ON_SET_SHOW_SIMILAR_SNACKBAR_VISIBILITY
    payload: { showSnackbar: boolean }
}

export interface CurrencyUpdatedAction {
    type: ActionType.ON_CURRENCY_UPDATED
    payload: { showAip: boolean; currency: string }
}

export type Action =
    | OnTypeFilterChangeAction
    | ResetFiltersAction
    | ResetFiltersExceptSectionsAction
    | ResetPriceFiltersAction
    | OnQuantityFilterChangeAction
    | OnSortFilterChangeAction
    | OnPriceFilterChangeAction
    | OnSelectSectionAction
    | OnHighlightSectionAction
    | OnShowAllInPriceChangeAction
    | ResetSectionAction
    | SelectSimilarAction
    | SelectSimilarSnackbarVisibilityAction
    | ZoneHighlightAction
    | CurrencyUpdatedAction
    | InitFilterAction
    | OnSelectRowAction

export interface TypeFilterState {
    superSeller: boolean
    greatDeal: boolean
    instantDelivery: boolean
    recentlyViewed: boolean
    frontOfSection: boolean
    clubSeats: boolean
    suites: boolean
    familyNonAlc: boolean
    standingRoom: boolean
    unobstructedView: boolean
    unlimitedFoodAndBeverage: boolean
    parking: boolean
    foodBeverageVoucher: boolean
    clubAccess: boolean
    vip: boolean
    memorabilia: boolean
    meetAndGreet: boolean
}

export interface QuantityFilterState {
    quantity: number
}

export interface SortFilterState {
    name: SortFilterNames
}

export interface PriceFilterState {
    currentMin: number
    currentMax: number
    currency: string | undefined
}

export interface SectionDefinition {
    id: string
    hasSectionBeenManuallyFiltered?: boolean
}

export interface ProductionFilterState {
    typeFilterState: TypeFilterState
    quantityFilterState: QuantityFilterState
    sortFilterState: SortFilterState
    priceFilterState: PriceFilterState
    selectedSectionsFilterState: Array<SectionDefinition>
    highlightedSectionsState: Array<SectionDefinition>
    showAllInPrice: boolean
    modifiedFilterList: FilterFields[]
    showSelectSimilarSnackbar: boolean
    selectedGroups: string[]
    highlightedZones: string[]
    highlightedZoneSections: string[]
    hasSectionBeenManuallyFiltered?: boolean
    expiration: number
    selectedRowsFilterState: string[]
}

export type SectionsRowsDataType = Record<string, SectionRowsDataType>

export type SectionRowsDataType = {
    [row: string]: {
        minPrice: number
        tooltip?: string
    }
}
export interface SectionRowsMap {
    [key: string]: string[]
}
export interface SectionsPriceAndColor {
    [section: string]: {
        price: number
        allInPrice: number
        color?: string
        row?: string
        section?: string
        mbi?: string
        availableRows?: string[]
        availableRowsData?: SectionRowsDataType
    }
}

export type FilterSort = 'price' | 'priceHigh' | 'row' | 'section'

export type FilterSortOption = {
    value: FilterSort
    label: string
    dataTestId: string
}

interface Selectors {
    filteredListingsSelector: (ComposedListing | ListingsGroup)[]
    getCurrentSortByValueSelector: FilterSort
    getCurrentSortByButtonTextSelector: string
    filteredSections: string[]
    filteredSectionsWithPriceAndColor: SectionsPriceAndColor
    getViewResultsButtonTextSelector: string
    getHighlightedSections: string[]
    hasGreatDealsSelector: boolean
}

interface Dispatch {
    onTypeFilterChangeAction: (payload: { filterName: TypeFilterName; filterValue: boolean }) => void
    onQuantityFilterChangeAction: (payload: { quantity: number }) => void
    onSortFilterChangeAction: (payload: { name: SortFilterNames }) => void
    onPriceFilterChangeAction: (payload: { field: string; value: number; shouldTrackFilterChange: boolean }) => void
    onFilterResetAction: () => void
    onFilterResetExceptSectionsAction: () => void
    onFilterPriceResetAction: () => void
    onSectionSelectAction: (payload: SectionDefinition) => void
    onSectionHighlightAction: (payload: SectionDefinition) => void
    onShowAllInPriceChangeAction: () => void
    onResetSectionSelectionAction: () => void
    onShowSimilarSectionsAction: (payload?: { groupId: string; selected: boolean }) => void
    onSetSelectSimilarSnackbarVisibility: (payload: { showSnackbar: boolean }) => void
    onZoneHighlightAction: (payload: { groupId: string; highlighted: boolean }) => void
    onCurrencyUpdated: (payload: { showAip: boolean; currency: string }) => void
    onRowSelectAction: (payload: { rowId: string }) => void
}

export interface ContextValue {
    state: ProductionFilterState
    selectors: Selectors
    dispatch: Dispatch
}

export const enum FilterFields {
    Type = 'sortType',
    Quantity = 'quantity',
    PriceRange = 'priceRange',
    TicketType = 'ticketType',
    Seat = 'seat',
    AllInPrice = 'allInPrice',
}

interface Selectors {
    filteredListingsSelector: (ComposedListing | ListingsGroup)[]
    getCurrentSortByValueSelector: FilterSort
    getCurrentSortByButtonTextSelector: string
    filteredSections: string[]
    filteredSectionsWithPriceAndColor: SectionsPriceAndColor
    getViewResultsButtonTextSelector: string
    getHighlightedSections: string[]
    hasSuperSellerSelector: boolean
    hasInstantDeliverySelector: boolean
    hasGreatDealsSelector: boolean
    hasFrontOfSectionSelector: boolean
    hasClubSeatsSelector: boolean
    hasSuitesSelector: boolean
    hasFamilyNonAlcSelector: boolean
    hasStandingRoomSelector: boolean
    hasObstructedViewSelector: boolean
    hasUnlimitedFoodAndBeverageSelector: boolean
    hasParkingSelector: boolean
    hasFoodBeverageVoucherSelector: boolean
    hasClubAccessSelector: boolean
    hasVipSelector: boolean
    hasMemorabiliaSelector: boolean
    hasMeetAndGreetSelector: boolean
    getLastSelectedSectionSelector?: SectionDefinition
    getHighlightedRows: string[]
}
