import type { Level3MenuItem } from '@/context/performers-navigation/types'

const other: Level3MenuItem[] = [
    {
        level: 3,
        url: '/pga-golf',
        label: 'PGA Golf',
    },
    {
        level: 3,
        url: '/sports/horse-racing',
        label: 'Horse Racing',
    },
    {
        level: 3,
        url: '/sports/soccer',
        label: 'Soccer',
    },
    {
        level: 3,
        url: '/sports/tennis',
        label: 'Tennis',
    },
    {
        level: 3,
        url: '/wwe',
        label: 'WWE',
    },
]

export default other
