// Converted from https://github.com/fullstorydev/integration-examples/blob/master/dist/sampling.js
export function fullstorySample({ rate, daysValid }: { rate: number; daysValid?: number }): boolean {
    const cookieName = '_fs_sample_user'
    try {
        if (typeof document === 'undefined' || typeof window === 'undefined') return false
        if (document.cookie.indexOf(cookieName + '=true') > -1 || document.cookie.indexOf(cookieName + '=false') > -1) {
            return document.cookie.indexOf(cookieName + '=true') > -1
        } else {
            const shouldSample = Math.random() < rate / 100
            const days = daysValid !== undefined && daysValid > 0 ? daysValid : 30
            const date = new Date()
            date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000)
            document.cookie = `${cookieName}=${shouldSample}; expires=${date.toUTCString()}; path=/`
            return shouldSample
        }
    } catch (err) {
        console.error('FullStory unavailable, unable to sample user')
        return false
    }
}
