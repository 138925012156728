/**
 * See beacon doc for reference https://apiref.riskified.com/#beacon
 *
 * For testing, add `?optimizely=web_riskified_beacon` to forcefully enable the feature
 * Otherwise, got to Optimizely directly and disable `web_riskified_beacon` feature under Feature tab
 */

import { useCallback, useEffect, useRef, useState } from 'react'

import { ReactSDKClient } from '@optimizely/react-sdk'
import { Cookies } from 'react-cookie'

import { OPTIMIZELY_FEATURE } from '../../optimizely/constants'

import {
    RISKIFIED_BEACON_COOKIE_NAME,
    composeSessionId,
    generate24HoursFromNowDate,
    riskifiedBeaconLoad,
    removeQueryParamsFromUrlPath,
} from './utils'

const cookie = new Cookies()

const useRiskifiedBeacon = (nextRoutePathName: string, optimizelyClient: ReactSDKClient): void => {
    const [isFeatureEnabled, setIsFeatureEnabled] = useState(false)
    const [isBeaconLoaded, setIsBeaconLoaded] = useState(false)
    const riskifiedBeaconRef = useRef<HTMLScriptElement>()

    const isRiskifiedBeaconReady = isFeatureEnabled && isBeaconLoaded && typeof window !== 'undefined' && !!window.RISKX
    const currentBeaconSessionId = cookie.get(RISKIFIED_BEACON_COOKIE_NAME)
    const scriptOnloadHandler = () => {
        setIsBeaconLoaded(true)
    }

    // Needs to live inside component scope to reuse React values
    const initializeBeacon = useCallback(() => {
        const beaconSessionId = currentBeaconSessionId || composeSessionId()
        // always initialize cookie with expiration of 24 hours on entering website
        cookie.set('beaconSessionId', beaconSessionId, { path: '/', expires: generate24HoursFromNowDate() })
        riskifiedBeaconRef.current = riskifiedBeaconLoad(beaconSessionId, scriptOnloadHandler)
    }, [currentBeaconSessionId])

    // Sitting Riskified Beacon behind feature flag with Optimizely
    // TODO - to be removed once it has rolled into production and testing/verification is completed
    useEffect(() => {
        // for unknown reason, optimizelyClient is only fully ready on client-side render. So put it here instead of _app.tsx
        const isRiskifiedBeaconEnabled = optimizelyClient?.isFeatureEnabled(OPTIMIZELY_FEATURE.riskifiedBeacon)
        setIsFeatureEnabled(isRiskifiedBeaconEnabled)
    }, [optimizelyClient])

    // init after feature flag is ready (after Server-Side-Rendering).
    useEffect(() => {
        if (!isFeatureEnabled) return

        initializeBeacon()

        return () => {
            if (!riskifiedBeaconRef?.current) return
            document.head.removeChild(riskifiedBeaconRef.current) // clean up
        }
    }, [isFeatureEnabled, initializeBeacon])

    // SPA solution to run whenever the route changes
    useEffect(() => {
        if (!isRiskifiedBeaconReady) return

        const pathNameWithoutQueryParam = removeQueryParamsFromUrlPath(nextRoutePathName)

        // TODO - left here for verification because there is no way to verify the changes without Riskified's help.
        // TODO - To-be removed once they figured out any ways that we can verify changes ourselves
        console.info('Loading Riskified Beacon. Payload: ', { isRiskifiedBeaconReady, pathNameWithoutQueryParam })

        window.RISKX?.go(pathNameWithoutQueryParam)
    }, [nextRoutePathName, isRiskifiedBeaconReady])

    // Re-Generate cookie if user's beaconSessionId got cleared out (i.e. after checkout completed)
    useEffect(() => {
        if (isFeatureEnabled && isBeaconLoaded && !currentBeaconSessionId && riskifiedBeaconRef.current) {
            setIsBeaconLoaded(false)
            document.head.removeChild(riskifiedBeaconRef.current) // clean up
            initializeBeacon()
        }
    }, [currentBeaconSessionId, isBeaconLoaded, isFeatureEnabled, nextRoutePathName, initializeBeacon])
}

export default useRiskifiedBeacon
