import * as Sentry from '@sentry/nextjs'

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

export const initSentry = (): void => {
    const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN
    const SENTRY_ENV = process.env.SENTRY_ENV || process.env.NEXT_PUBLIC_SENTRY_ENV || 'production'
    const NODE_ENV = process.env.NODE_ENV

    if (SENTRY_DSN) {
        Sentry.init({
            dsn: SENTRY_DSN,
            // Note: If you want to override the automatic release value, do not set a `release` value here.
            // Use the environment variable `SENTRY_RELEASE`, so that it will also get attached to your source maps
            // 0.01 is 1% sampling. Change this during debugging session.
            tracesSampler: () => {
                return (typeof window !== 'undefined' && SENTRY_ENV === 'production') || SENTRY_ENV === 'staging'
                    ? 0.01
                    : 0
            },
            environment: SENTRY_ENV,
            enabled: NODE_ENV === 'production',
            beforeSend: (event: Sentry.Event) => {
                // Note: Issue with double entries during HTTP exceptions: https://github.com/getsentry/sentry-javascript/issues/2169
                // Note: Issue with a second entry not being set correctly (as a non-error): https://github.com/getsentry/sentry-javascript/issues/2292#issuecomment-554932519
                // Note: Issue with Object Not Found Matching Id https://github.com/getsentry/sentry-javascript/issues/3440
                const error = event.exception?.values?.[0]
                if (
                    error?.value?.startsWith('Non-Error exception captured') ||
                    error?.value?.startsWith('Non-Error promise rejection captured') ||
                    error?.value?.startsWith('SecurityError: The operation is insecure') // https://vividseats.atlassian.net/browse/BW-3290
                ) {
                    return null
                }

                return event
            },
        })
    }
}
