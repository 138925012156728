import { AnalyticsState } from '@/context/analytics'

export enum AnalyticsIds {
    ConcertsCategory = 'ConcertsCategory',
    Home = 'HomePage',
    Performer = 'Performer',
    Region = 'Region',
    RegionDetails = 'RegionDetails',
    SearchBar = 'SearchBar',
    SportsCategory = 'SportsCategory',
    TheaterCategory = 'TheaterCategory',
    Venue = 'Venue',
    Search = 'Search',
    Checkout = 'Checkout',
    ListingManager = 'ListingManager',
    Login = 'Login',
}

export enum AnalyticsSubIds {
    DrivingDistanceProductions = 'DrivingDistanceProductions',
    MainProductionList = 'MainProductionList',
    NearbyProductions = 'NearbyProductions',
    TopPicks = 'TopPicks',
    VariantProductionList = 'VariantProductionList',
    EventsUnder = 'EventsUnder',
}

/**
 * a function that gets the analytics ID
 * --> a full ID is a combination of ID + "-" + SUB_IDs
 * --> note: IDs come from state, while SUB_IDs will be passed from a specific list/component (ex: driving distance list)
 * --> note: IDs can be null if users are coming from a direct link. ex: null-DrivingDistanceProductions
 *
 * @param state
 * @returns string | null
 */
export const getAnalyticsID = ({ id }: AnalyticsState, ...analyticsSubIds: string[]): string | null => {
    if (analyticsSubIds.length) {
        return `${id}-${analyticsSubIds.join('-')}`
    }

    if (id) {
        return id
    }

    return null
}
