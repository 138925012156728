/*
 * Data points for all types of GA events
 */

import type { Production } from '@/types/app-types'
import {
    getCrmIDCookieValue,
    getGaUserIDCookieValue,
    getReturningUserCookieValue,
    getWsUserCookieValue,
    getUserLocationCookieValue,
} from '@/utils/cookies'
import { getMainPerformer } from '@/utils/production'

import type { GAItem, ServerInfo, UserInfo } from './types'

export const createServerInfo = (): ServerInfo => {
    return {
        affiliation: 'Vivid Seats Web',
        affiliation_detail: 'Web2.0',
        affiliation_id: getWsUserCookieValue(),
        build: process.env.NEXT_PUBLIC_GIT_COMMIT || 'no-build',
        environment: process.env.NEXT_PUBLIC_SENTRY_ENV || 'no-env',
    }
}

export const createUserInfo = (): UserInfo => {
    const user_id = getGaUserIDCookieValue()
    const crm_id = getCrmIDCookieValue()
    const is_returning_customer = getReturningUserCookieValue()
    const user_location = getUserLocationCookieValue()

    return { is_returning_customer, user_id, crm_id, user_location }
}

export const createGAItem = ({
    production,
    item_list_name = null,
}: {
    production: Production
    item_list_name: string | null
}): GAItem => {
    const performer = getMainPerformer(production)

    return {
        item_name: production.name,
        item_id: production.id.toString(),
        item_list_name,

        item_type: production.name.toLowerCase().endsWith('parking') ? 'Parking' : 'Tickets',
        item_category: performer.category.name.endsWith('s')
            ? performer.category.name.toUpperCase().slice(0, -1)
            : performer.category.name.toUpperCase(),
        item_category2: performer.category.subCategories?.find((c) => c)?.name,
        item_category3: performer.name,
        item_category4: production.venue.name,
        item_category5: production.utcDate,
    }
}
