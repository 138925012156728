import { createTheme } from '@mui/material/styles'

import colors from '@/design-system/tokens/json/colors.json'
import typography from '@/design-system/tokens/json/typography.json'

import components from './components'

const lightThemePalette = {
    primary: {
        lightest: colors.magenta['100'],
        main: colors.magenta['400'],
        light: colors.magenta['100'],
        dark: colors.magenta['600'],
        darkest: colors.magenta['800'],
    },
    neutral: {
        main: colors.gray['800'],
        dark: colors.gray['600'],
        light: colors.gray['200'],
        lighter: colors.gray['50'],
        contrastText: colors.gray['100'],
    },
    secondary: {
        main: colors.teal['400'],
        light: colors.teal['100'],
        dark: colors.teal['800'],
    },
    error: {
        main: colors.red['600'],
    },
}

export const lightTheme = createTheme({
    colors,
    palette: {
        ...colors,
        ...lightThemePalette,
    },
    typography: {
        ...typography,
        button: {
            // buttons are all caps by default
            textTransform: 'none',
        },
    },
    components: {
        ...components,
    },
})

// TODO: replace imports of this theme with use of useTheme
export const theme = lightTheme
