import type { Level3MenuItem } from '@/context/performers-navigation/types'

const ncaab: Level3MenuItem[] = [
    {
        level: 3,
        url: '/ncaa-mens-basketball-tournament-tickets--sports-ncaa-basketball/performer/2652',
        label: "NCAA Men's Basketball Tournament",
    },
    {
        level: 3,
        url: '/ncaa-mens-final-four-tickets--sports-ncaa-basketball/performer/580',
        label: 'Final Four',
    },
    {
        level: 3,
        url: '/ncaab',
        label: 'All NCAA Basketball',
        id: 'sports-ncaab-all',
    },
    {
        level: 3,
        url: '/arizona-wildcats-mens-basketball-tickets--sports-ncaa-basketball/performer/3098',
        label: 'Arizona Wildcats',
    },
    {
        level: 3,
        url: '/ncaa-conference-championship-tickets--sports-ncaa-basketball/custom/308',
        label: 'Conference Championships',
    },
    {
        level: 3,
        url: '/duke-blue-devils-mens-basketball-tickets--sports-ncaa-basketball/performer/255',
        label: 'Duke Blue Devils',
    },
    {
        level: 3,
        url: '/ncaa-mens-final-four-tickets--sports-ncaa-basketball/performer/580',
        label: 'Final Four ',
    },
    {
        level: 3,
        url: '/georgetown-hoyas-mens-basketball-tickets--sports-ncaa-basketball/performer/319',
        label: 'Georgetown Hoyas',
    },
    {
        level: 3,
        url: '/indiana-hoosiers-mens-basketball-tickets--sports-ncaa-basketball/performer/3204',
        label: 'Indiana Hoosiers ',
    },
    {
        level: 3,
        url: '/kansas-jayhawks-mens-basketball-tickets--sports-ncaa-basketball/performer/3214',
        label: 'Kansas Jayhawks ',
    },
    {
        level: 3,
        url: '/kentucky-wildcats-mens-basketball-tickets--sports-ncaa-basketball/performer/3224',
        label: 'Kentucky Wildcats',
    },
    {
        level: 3,
        url: '/louisville-cardinals-mens-basketball-tickets--sports-ncaa-basketball/performer/3233',
        label: 'Louisville Cardinals',
    },
    {
        level: 3,
        url: '/ncaa-mens-basketball-tournament-tickets--sports-ncaa-basketball/performer/2652',
        label: 'March Madness',
    },
    {
        level: 3,
        url: '/michigan-state-spartans-mens-basketball-tickets--sports-ncaa-basketball/performer/3248',
        label: 'Michigan State Spartans ',
    },
    {
        level: 3,
        url: '/michigan-wolverines-mens-basketball-tickets--sports-ncaa-basketball/performer/3252',
        label: 'Michigan Wolverines',
    },
    {
        level: 3,
        url: '/missouri-tigers-mens-basketball-tickets--sports-ncaa-basketball/performer/3262',
        label: 'Missouri Tigers',
    },
    {
        level: 3,
        url: '/ncaa-mens-basketball-tournament-tickets--sports-ncaa-basketball/performer/2652',
        label: 'NCAA Tournament',
    },
    {
        level: 3,
        url: '/north-carolina-tar-heels-mens-basketball-tickets--sports-ncaa-basketball/performer/3274',
        label: 'North Carolina Tar Heels',
    },
    {
        level: 3,
        url: '/notre-dame-fighting-irish-mens-basketball-tickets--sports-ncaa-basketball/performer/3281',
        label: 'Notre Dame Fighting Irish',
    },
    {
        level: 3,
        url: '/ohio-state-buckeyes-mens-basketball-tickets--sports-ncaa-basketball/performer/3285',
        label: 'Ohio State Buckeyes',
    },
    {
        level: 3,
        url: '/penn-state-nittany-lions-mens-basketball-tickets--sports-ncaa-basketball/performer/3293',
        label: 'Penn State Nittany Lions',
    },
    {
        level: 3,
        url: '/pittsburgh-panthers-mens-basketball-tickets--sports-ncaa-basketball/performer/3297',
        label: 'Pittsburgh Panthers',
    },
    {
        level: 3,
        url: '/st-johns-red-storm-mens-basketball-tickets--sports-ncaa-basketball/performer/3322',
        label: "St. John's Red Storm",
    },
    {
        level: 3,
        url: '/syracuse-orange-mens-basketball-tickets--sports-ncaa-basketball/performer/3333',
        label: 'Syracuse Orange',
    },
    {
        level: 3,
        url: '/tennessee-vols-mens-basketball-tickets--sports-ncaa-basketball/performer/3339',
        label: 'Tennessee Vols',
    },
    {
        level: 3,
        url: '/wisconsin-badgers-mens-basketball-tickets--sports-ncaa-basketball/performer/3396',
        label: 'Wisconsin Badgers',
    },
]

export default ncaab
