// please also see https://developer.mozilla.org/en-US/docs/Web/API/Geolocation/getCurrentPosition#options
export const GET_LOCATION_CONFIG = { enableHighAccuracy: true }

export type LocationData = {
    latitude: number
    longitude: number
    countryCode: string
    region: string
    city: string
    countryName: string
    method?: string
}

export type CurrentLocation = {
    latitude: number
    longitude: number
}

export type LocationApiResponse = {
    countryCode: string
    regionCode: string
    regionName: string
    city: string
    countryName: string
}

export type AddressComponent = {
    types: ['country', 'administrative_area_level_1', 'locality']
    short_name: string
    long_name: string
}
