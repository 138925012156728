import { GTMConsentSettings } from '../types'

// Page view
export const BRAZE_EVENT_NAME = 'braze'
export const OPTIMIZELY_EVENT_NAME = 'optimizely'
export const MARKETING_VISIT_EVENT_NAME = 'marketing_visit'
export const PAGE_VIEW_EVENT_NAME = 'pageview'
export const LANDING_PAGE_VIEW_EVENT_NAME = 'landing-page-view'

// Interactions
export const GEOLOCATION_EVENT_NAME = 'geolocation'
export const NAVIGATION_EVENT_NAME = 'navigation'
export const SEARCH_EVENT_NAME = 'search'
export const FILTER_EVENT_NAME = 'filter'
export const ERROR_EVENT_NAME = 'error'
export const DISPLAY_CURRENCY_EVENT_NAME = 'display_currency'

// Home page interactions
export const HOME_PAGE_INTERACTION = 'select_content'
export const HOME_PAGE_INTERACTION_CONTENT_TYPE = 'homepage'

// E-commerce
export const VIEW_PRODUCTION_LIST_EVENT_NAME = 'view_item_list'
export const SELECT_PRODUCTION_EVENT_NAME = 'select_item'
export const SELECT_SEARCH_SUGGESTION_EVENT_NAME = 'select_item'
export const VIEW_LISTINGS_EVENT_NAME = 'view_item'
export const VIEW_PRODUCTION_CONTENT_TYPE = 'view_production'
export const SELECT_LISTINGS_EVENT_NAME = 'select_ticket'
export const SELECT_LISTING_CONTENT_TYPE = 'select_ticket'
export const VIEW_PROMOTION_EVENT_NAME = 'view_promotion'

// Consent

export const initialGTMConsentSettings: GTMConsentSettings = {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
    personalization_storage: 'granted',
    wait_for_update: '500',
    region: ['US', 'CA'],
}
