import React, { $FC, createContext, useMemo } from 'react'

import { serverSideQuery, useQuery } from '@/api'
import { TRENDINGS_ENDPOINT } from '@/constants'
import type { Trending } from '@/types/app-types'

import type { TrendingsContextType, TrendingsSelectors, TrendingsProviderProps, TrendingsState } from './types'

const defaultContextValue: TrendingsContextType = {
    state: {},
    selectors: {
        trendings: [],
    },
}

const TrendingsContext = createContext<TrendingsContextType>(defaultContextValue)
const { Provider } = TrendingsContext

export const TrendingsProvider: $FC<TrendingsProviderProps> = ({ initialProps, children }) => {
    const { fetching, data, error } = useQuery<Trending[]>({
        endpoint: TRENDINGS_ENDPOINT,
        initialData: initialProps?.data,
    })

    const contextValue: TrendingsContextType = useMemo(() => {
        const state: TrendingsState = { fetching, data, error }

        const selectors: TrendingsSelectors = {
            trendings: data || [],
        }

        return { state, selectors }
    }, [fetching, data, error])

    return <Provider value={contextValue}>{children}</Provider>
}

export const serverSideRead = async (maxAgeMs?: number): Promise<Trending[]> => {
    const queryInstance = serverSideQuery<Trending[]>(TRENDINGS_ENDPOINT)
    return await queryInstance(undefined, maxAgeMs)
}

export default TrendingsContext
