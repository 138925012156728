import type { AxiosInstance, AxiosRequestConfig } from 'axios'
import isEmpty from 'lodash/isEmpty'
import type { GetServerSidePropsContext } from 'next'
import { useQuery } from 'react-query'
import type { RetryValue } from 'react-query/types/core/retryer'

import { hermesService } from '@/api'
import { COOKIES } from '@/constants'
import { getCognitoFeature } from '@/optimizely/features/cognito-authentication'
import type { AuthCookie } from '@/types/app-types'

import { hermesBaseUrl } from '../factory'
import { serverSideRefresh } from '../refresh-token'
import { buildAuthHeaders, isCrossOriginRequest } from '../utils'

type AxiosConfig = Pick<AxiosRequestConfig, 'method' | 'params' | 'headers'>

export interface UseQueryHookParams<T = unknown> extends AxiosConfig {
    body?: AxiosRequestConfig['data']
    cacheKey?: string | ReadonlyArray<unknown>
    enabled?: boolean
    endpoint: string
    initialData?: T
    initialFetchDisabled?: boolean
    refetchInterval?: number | false
    refetchIntervalInBackground?: boolean
    refetchOnMount?: boolean
    refetchOnReconnect?: boolean
    refetchOnWindowFocus?: boolean
    retry?: RetryValue<string & Error>
    staleTime?: number
    timeout?: number
    onError?: (error: unknown) => void
    onSuccess?: (data: T) => void
    retryDelay?: (attemptIndex: number) => number
    axiosInstance?: AxiosInstance
}

/**
 * @typeParam `T` - The type of data that will be returned if the query is successful. Defaults to `unknown`.
 *
 * @param input - {@link UseQueryHookParams}
 *
 * @returns Data from react-query
 */
const useQueryHook = <T = unknown>({
    endpoint,
    params,
    cacheKey,
    initialData,
    headers,
    enabled = true,
    method = 'GET',
    body,
    onSuccess,
    onError,
    refetchInterval = false,
    refetchOnWindowFocus = false,
    refetchOnReconnect = false,
    refetchIntervalInBackground = false,
    refetchOnMount = false,
    // retries default to 3 in react-query
    retry = false,
    staleTime, // time after which data will be considered stale - defaults to 0
    timeout,
    // The default retryDelay is set to double (starting at 1000ms) with each attempt, but not exceed 30 seconds
    retryDelay = (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30000),
    initialFetchDisabled,
    axiosInstance,
}: UseQueryHookParams<T>): {
    error?: string | null
    data?: T | undefined
    fetching: boolean
    loading: boolean
    refetch: () => void
} => {
    const queryInstance = axiosInstance || hermesService
    const { data, isLoading, isFetching, error, refetch } = useQuery<T, string & Error>(
        // The cache key should be an array. If it is not set, we generate it from the endpoint and params
        cacheKey || [endpoint, method, body, params, initialFetchDisabled],
        async (): Promise<T> => {
            const response =
                method === 'GET'
                    ? await queryInstance.get(endpoint, { params, data: body, headers, timeout })
                    : await queryInstance.request({ method, url: endpoint, params, data: body, headers, timeout })

            return response?.data
        },
        {
            enabled,
            initialData,
            staleTime,
            refetchOnMount,
            refetchOnWindowFocus,
            refetchIntervalInBackground,
            refetchOnReconnect,
            retryDelay,
            retry,
            keepPreviousData: true,
            onSuccess,
            onError,
            refetchInterval,
        },
    )

    return {
        data,
        error: error?.message || error,
        loading: isLoading,
        fetching: isLoading || isFetching,
        refetch,
    }
}

/**
 * Perform server side requests, refreshing the auth token if necessary, and attaching auth headers to
 * the requests
 */
export const serverSideQuery =
    <DataType, ParamType = AxiosRequestConfig['params']>(
        endpoint: string,
        context?: GetServerSidePropsContext,
        headers?: { [key: string]: unknown },
        axiosInstance?: AxiosInstance,
    ) =>
    async (params: ParamType, maxAgeMs?: number): Promise<DataType> => {
        try {
            const queryInstance = axiosInstance || hermesService
            const isCrossOrigin = isCrossOriginRequest(endpoint, hermesBaseUrl || '')

            const cognitoDecision = getCognitoFeature(context)
            const authCookie = context?.req.cookies[COOKIES.AUTH_TOKEN_COOKIE]
            const parsedAuthCookie = authCookie ? (JSON.parse(authCookie) as AuthCookie) : undefined

            // Refresh auth token and attach auth headers to client side requests
            // Auth headers for client side requests are attached on requestInterceptor (src/api/factory.ts)
            const shouldRefreshToken = !!(cognitoDecision?.enabled && context && !isCrossOrigin)
            const authHeaders = !isCrossOrigin && parsedAuthCookie ? buildAuthHeaders(parsedAuthCookie) : {}
            const newAuthHeaders = shouldRefreshToken ? await serverSideRefresh(context) : {}
            const combinedHeaders = { ...headers, ...authHeaders, ...newAuthHeaders }
            const reqHeaders = isEmpty(combinedHeaders) ? undefined : combinedHeaders

            const options = { params, headers: reqHeaders, cache: maxAgeMs ? { maxAge: maxAgeMs } : undefined }
            const { data } = (await queryInstance.get(endpoint, options)) || { data: undefined }
            return data
        } catch (e) {
            return Promise.reject(e)
        }
    }

export default useQueryHook
