import type { Level3MenuItem } from '@/context/performers-navigation/types'

const cities: Level3MenuItem[] = [
    {
        level: 3,
        url: '/region/usa/ga/atlanta-tickets/80/concerts',
        label: 'Atlanta Concerts',
    },
    {
        level: 3,
        url: '/region/usa/tx/austin-tickets/114/concerts',
        label: 'Austin Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ma/boston-area-tickets/74/concerts',
        label: 'Boston Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ny/buffalo-tickets/109/concerts',
        label: 'Buffalo Concerts',
    },
    {
        level: 3,
        url: '/region/usa/il/chicago-area-tickets/57/concerts',
        label: 'Chicago Concerts',
    },
    {
        level: 3,
        url: '/region/usa/oh/cleveland-tickets/16/concerts',
        label: 'Cleveland Concerts',
    },
    {
        level: 3,
        url: '/region/usa/oh/columbus-tickets/15/concerts',
        label: 'Columbus Concerts',
    },
    {
        level: 3,
        url: '/region/usa/tx/dallas-tickets/19/concerts',
        label: 'Dallas Concerts',
    },
    {
        level: 3,
        url: '/region/usa/co/denver-tickets/40/concerts',
        label: 'Denver Concerts',
    },
    {
        level: 3,
        url: '/region/usa/mi/michigan-tickets/61/concerts',
        label: 'Detroit Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ct/hartford-tickets/73/concerts',
        label: 'Hartford Concerts',
    },
    {
        level: 3,
        url: '/region/usa/tx/houston-tickets/18/concerts',
        label: 'Houston Concerts',
    },
    {
        level: 3,
        url: '/region/usa/in/indianapolis-tickets/58/concerts',
        label: 'Indianapolis Concerts',
    },
    {
        level: 3,
        url: '/region/usa/nv/las-vegas-tickets/33/concerts',
        label: 'Las Vegas Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ca/los-angeles-tickets/29/concerts',
        label: 'Los Angeles Concerts',
    },
    {
        level: 3,
        url: '/region/usa/tn/nashville-tickets/56/concerts',
        label: 'Nashville Concerts',
    },
    {
        level: 3,
        url: '/region/usa/la/louisiana-and-mississippi-tickets/52/concerts',
        label: 'New Orleans Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ny/new-york-tickets/7/concerts',
        label: 'New York City Concerts',
    },
    {
        level: 3,
        url: '/region/usa/fl/orlando-tickets/78/concerts',
        label: 'Orlando Concerts',
    },
    {
        level: 3,
        url: '/region/usa/pa/philadelphia-tickets/72/concerts',
        label: 'Philadelphia Concerts',
    },
    {
        level: 3,
        url: '/region/usa/or/portland-tickets/27/concerts',
        label: 'Portland Concerts',
    },
    {
        level: 3,
        url: '/region/usa/tx/san-antonio-tickets/20/concerts',
        label: 'San Antonio Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ca/san-diego-tickets/85/concerts',
        label: 'San Diego Concerts',
    },
    {
        level: 3,
        url: '/region/usa/ca/bay-area-tickets/28/concerts',
        label: 'San Francisco Concerts',
    },
    {
        level: 3,
        url: '/region/usa/wa/seattle-tickets/26/concerts',
        label: 'Seattle Concerts',
    },
    {
        level: 3,
        url: '/region/can/on/toronto-tickets/86/concerts',
        label: 'Toronto Concerts',
    },
    {
        level: 3,
        url: '/region/can/bc/vancouver-tickets/91/concerts',
        label: 'Vancouver Concerts',
    },
    {
        level: 3,
        url: '/region/usa/dc/washington-dc-and-baltimore-tickets/70/concerts',
        label: 'Washington DC Concerts',
    },
]

export default cities
