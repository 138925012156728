import type { Level3MenuItem } from '@/context/performers-navigation/types'

const trending: Level3MenuItem[] = [
    {
        level: 3,
        url: '/boston-celtics-tickets--sports-nba-basketball/performer/105',
        label: 'Boston Celtics',
    },
    {
        level: 3,
        url: '/boston-red-sox-tickets--sports-mlb-baseball/performer/107',
        label: 'Boston Red Sox',
    },
    {
        level: 3,
        url: '/brooklyn-nets-tickets--sports-nba-basketball/performer/29773',
        label: 'Brooklyn Nets',
    },
    {
        level: 3,
        url: '/los-angeles-lakers-tickets--sports-nba-basketball/performer/483',
        label: 'Los Angeles Lakers',
    },
    {
        level: 3,
        url: '/miami-heat-tickets--sports-nba-basketball/performer/533',
        label: 'Miami Heat',
    },
    {
        level: 3,
        url: '/monster-jam-tickets--sports-extreme-sports/performer/2186',
        label: 'Monster Jam',
    },
    {
        level: 3,
        url: '/new-york-knicks-tickets--sports-nba-basketball/performer/602',
        label: 'New York Knicks',
    },
    {
        level: 3,
        url: '/new-york-yankees-tickets--sports-mlb-baseball/performer/607',
        label: 'New York Yankees',
    },
    {
        level: 3,
        url: '/philadelphia-76ers-tickets--sports-nba-basketball/performer/668',
        label: 'Philadelphia 76ers',
    },
    {
        level: 3,
        url: '/super-bowl-tickets--sports-nfl-football/performer/1124',
        label: 'Super Bowl',
    },
]

export default trending
