/* istanbul ignore next */
import { datadogRum } from '@datadog/browser-rum'

import { COOKIES } from '@/constants'
import { getCookie } from '@/utils/cookies'

import { postTrustarcGetConsentMesssageToWindow, registerForReloadIfConsentIsDenied } from '../helpers'
import { CONSENT } from '../types'

export const initializeRUMWithPii = async () => {
    registerForReloadIfConsentIsDenied()
    postTrustarcGetConsentMesssageToWindow()

    const consentDecision = getCookie(document.cookie, COOKIES.PRIVACY_COOKIE)

    if (
        !consentDecision ||
        consentDecision == '' ||
        consentDecision.includes(CONSENT.PERMIT_ALL) ||
        consentDecision.includes(CONSENT.YES_TRACKING_NO_ADS)
    ) {
        datadogRum.setTrackingConsent('granted')
        return
    }

    datadogRum.setTrackingConsent('not-granted')
}
