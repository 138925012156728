import { GetServerSidePropsContext } from 'next'

import {
    COGNITO_REFRESH_TOKEN_ENDPOINT,
    COOKIES,
    PERMANENT_COOKIE_SECONDS,
    REFRESH_TOKEN_IN_ADVANCE_SECONDS,
} from '@/constants'
import { AuthCookie } from '@/types/app-types'
import {
    deleteClientCookie,
    getCookie,
    getCookieFromResponseHeaders,
    setClientCookie,
    setCookie,
} from '@/utils/cookies'

import { hermesService } from '../factory'

import { RefreshTokenPayload, RefreshTokenSuccessResponse } from './types'

export const clientSideGetAuthCookie = (): AuthCookie | undefined => {
    return getCookie(document.cookie, COOKIES.AUTH_TOKEN_COOKIE) as AuthCookie
}

export const clientSideSetAuthCookie = (newAuthCookie?: AuthCookie) => {
    if (newAuthCookie) {
        setClientCookie({
            key: COOKIES.AUTH_TOKEN_COOKIE,
            value: JSON.stringify(newAuthCookie),
            path: '/',
            maxAge: PERMANENT_COOKIE_SECONDS,
            isSecure: process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'stage',
        })
    }
}

export const clientSideRemoveAuthCookie = () => {
    deleteClientCookie(COOKIES.AUTH_TOKEN_COOKIE, '/')
}

export const serverSideGetAuthCookie = (context?: GetServerSidePropsContext): AuthCookie | undefined => {
    // check if an auth cookie is available in the Set-Cookie header; if so, a refresh token
    // has already completed and we should get the new auth cookie from the Set-Cookie header
    const responseAuthCookie = getCookieFromResponseHeaders(COOKIES.AUTH_TOKEN_COOKIE, context) as
        | AuthCookie
        | undefined
    if (responseAuthCookie) {
        return responseAuthCookie
    }

    // otherwise, we should get the current auth cookie from the request cookies
    try {
        const authCookie = context?.req.cookies[COOKIES.AUTH_TOKEN_COOKIE]
        const parsedAuthCookie = authCookie ? (JSON.parse(authCookie) as AuthCookie) : undefined
        return parsedAuthCookie
    } catch {
        return undefined
    }
}

export const serverSideSetAuthCookie = (context?: GetServerSidePropsContext, newAuthCookie?: AuthCookie) => {
    if (context && newAuthCookie) {
        const expirationDate = new Date(Date.now() + 1000 * PERMANENT_COOKIE_SECONDS)
        setCookie(context.res, {
            key: COOKIES.AUTH_TOKEN_COOKIE,
            value: encodeURIComponent(JSON.stringify(newAuthCookie)),
            path: '/',
            expirationDate,
            isSecure: process.env.ENVIRONMENT === 'production' || process.env.ENVIRONMENT === 'stage',
        })
    }
}

export const serverSideRemoveAuthCookie = (context?: GetServerSidePropsContext) => {
    context?.res.setHeader('Set-Cookie', `${COOKIES.AUTH_TOKEN_COOKIE}=; Max-Age=0`)
}

export const shouldRefreshAuthToken = (tokenExpiresAt: number) => {
    const currentDate = Date.now()
    const dateDiff = tokenExpiresAt - currentDate
    return dateDiff < REFRESH_TOKEN_IN_ADVANCE_SECONDS
}

export const refreshCognitoToken = async (
    requestPayload: RefreshTokenPayload,
): Promise<RefreshTokenSuccessResponse> => {
    try {
        const { data } = await hermesService.post<RefreshTokenSuccessResponse>(
            COGNITO_REFRESH_TOKEN_ENDPOINT,
            requestPayload,
        )
        return data
    } catch (e) {
        console.error((e as Error).message)
        return Promise.reject(e)
    }
}
