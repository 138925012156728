import type { CookieValue } from '@/types/app-types'

import appLogger from '../app-logger'

import { initialGTMConsentSettings } from './ga4/constants'
import { CONSENT, GAConsent, PRIVACY_MODAL_WINDOW_EVENT_SOURCE } from './types'

// analytics should never prevent real code from running, so we need to trap and log the exceptions
// be sure to wrap all new analytics calls in this method
export function safelyTrack<T extends (...args: any) => void>(f: T): T {
    return <T>((...args: any) => {
        try {
            f(...args)
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : JSON.stringify(err)
            appLogger.logError(new Error(`Analytics failed due to: ${errorMessage}`))
        }
    })
}

export const generateSHA1Hash = async (text: string): Promise<string> => {
    const msgUint8 = new TextEncoder().encode(text) // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-1', msgUint8) // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)) // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('') // convert bytes to hex string
    return hashHex
}

export const generateSHA1EmailHash = async (email: string): Promise<string> => {
    const hash = await generateSHA1Hash(email.trim().toLowerCase())
    return hash.toUpperCase()
}

// Braze and Fluent
// Fluent will automatically be called after user closes Braze modal
let brazeOpened = false
export const brazeEventListener = (event: MessageEvent): void => {
    if (event?.origin !== window.location.origin) return

    if (event?.data === 'braze_modal_open') {
        brazeOpened = true
    } else if (event?.data === 'braze_modal_close') {
        window.removeEventListener('message', brazeEventListener)
        window.dataLayer?.push({ event: 'sa-checkout-complete' })
    }
}

export const sendFluentTriggerEvent = (): void => {
    window.addEventListener('message', brazeEventListener)

    // If we don't see Braze within 2.5 seconds, send Fluent trigger
    setTimeout(() => {
        if (!brazeOpened) {
            window.removeEventListener('message', brazeEventListener)
            window.dataLayer?.push({ event: 'sa-checkout-complete' })
        }
    }, 2500)
}
// End of -- Braze and Fluent

const DOMAIN_NAME = 'vividseats.com'

export const postTrustarcGetConsentMesssageToWindow = () => {
    // instructs trustarc to notify on changes to consent
    // it will fire an event to the event listener below
    window.top?.postMessage(
        JSON.stringify({
            PrivacyManagerAPI: {
                action: 'getConsent',
                timestamp: new Date().getTime(),
                self: DOMAIN_NAME,
            },
        }),
        '*',
    )
}

export const registerForReloadIfConsentIsDenied = () => {
    // registers listener for consent changes.
    // when consent changes, it will force a window reload
    // some care has been taken here to handle messages safely.
    // will reload window if they've "denied"... to reset tracking.
    window.addEventListener(
        'message',
        function reload(e) {
            let data = e.data
            if (typeof data === 'string') {
                try {
                    data = JSON.parse(data)
                } catch (e) {
                    /* weird message, bail */
                }
            }
            if (data && data.source === PRIVACY_MODAL_WINDOW_EVENT_SOURCE && data.data === CONSENT.NOT_SPECIFIED) {
                // Consent was withdrawn so we refresh page to stop tracking
                // The timeout has been added to allow for consent settings to be properly persisted to the cookie
                return setTimeout(() => window.location.reload())
            }
            // otherwise approved... carry on!
        },
        false,
    )
}

export const getGA4SetupScript = (privacyCookie?: CookieValue) => {
    const GTMConsentSettings = { ...initialGTMConsentSettings }
    let consentType: GAConsent = GAConsent.DEFAULT
    if (privacyCookie) {
        consentType = GAConsent.UPDATE
        delete GTMConsentSettings.region
        if (!privacyCookie.includes(CONSENT.YES_TRACKING_NO_ADS)) {
            GTMConsentSettings.analytics_storage = 'denied'
            GTMConsentSettings.personalization_storage = 'denied'
        }
        if (!privacyCookie.includes(CONSENT.PERMIT_ALL)) {
            GTMConsentSettings.ad_storage = 'denied'
            GTMConsentSettings.ad_user_data = 'denied'
            GTMConsentSettings.ad_personalization = 'denied'
        }
    }
    return `
        window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};ga.l=+new Date;
        ga('create', {
            trackingId: '${process.env.NEXT_PUBLIC_GA_ACCOUNT_ID}',
            cookieDomain: 'auto',
        })
        window.dataLayer=window.dataLayer||[];
        function gtag(){dataLayer.push(arguments);};
        ${
            consentType === GAConsent.UPDATE
                ? `gtag('consent', 'default', ${JSON.stringify(initialGTMConsentSettings)});`
                : ''
        }
        gtag('consent', '${consentType}', ${JSON.stringify(GTMConsentSettings)});
    `
}
