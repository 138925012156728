import { PromoAmountTypes, PromoResponse } from '@/context/promo/types'
import { PROMO_CHARACTERS_THRESHOLD } from '@/types/app-types'

import {
    CalcDiscountedPriceInput,
    CalcFixedDiscountedPriceInput,
    CalcLoyaltyCashBackInput,
    CalcRelativeDiscountedPriceInput,
} from './types'

export const isPercentagePromo = (amountType: PromoResponse['amountType'] | undefined) =>
    amountType === PromoAmountTypes.PERCENT

export const isDollarPromo = (amountType: PromoResponse['amountType'] | undefined) =>
    amountType === PromoAmountTypes.DOLLAR

export const isLoyaltyPromo = (amountType: PromoResponse['amountType'] | undefined) =>
    amountType === PromoAmountTypes.LOYALTY_MULTIPLIER

export const calcFixedDiscountedPrice = ({ price, promoAmount }: CalcFixedDiscountedPriceInput) => {
    const result = price - promoAmount

    return result > 0 ? result : 0
}

export const calcPercentageDiscount = ({
    max: maxAmount = 0,
    price,
    promoAmount,
    quantity = 0,
}: CalcRelativeDiscountedPriceInput) => {
    let discount = (price * promoAmount) / 100

    if (quantity * discount > maxAmount) {
        discount = maxAmount / quantity
    }

    return discount
}

export const calcDollarDiscount = ({
    price,
    promoAmount,
    quantity = 1,
    minTotal = -1,
}: CalcRelativeDiscountedPriceInput) => {
    let discount = 0
    if (price * quantity >= minTotal) {
        discount = promoAmount / quantity
    }
    return discount
}

export const calcLoyaltyCashBack = ({ max: maxAmount = 0, price, promoAmount }: CalcLoyaltyCashBackInput) => {
    const cashBack = (price * promoAmount) / 100

    return cashBack > maxAmount ? maxAmount : cashBack
}

export const calcDiscount = ({ promoAmount, max, price, quantity, type, minTotal }: CalcDiscountedPriceInput) => {
    if (isDollarPromo(type)) {
        return calcDollarDiscount({ price, promoAmount, quantity, minTotal })
    }

    if (isPercentagePromo(type)) {
        return calcPercentageDiscount({ max, price, promoAmount, quantity })
    }

    if (isLoyaltyPromo(type)) {
        return calcLoyaltyCashBack({ max, price, promoAmount })
    }

    return 0
}

export const calcDiscountedPrice = ({
    promoAmount,
    max,
    price,
    quantity,
    type,
    minTotal,
}: CalcDiscountedPriceInput) => {
    const discount = calcDiscount({ promoAmount, price, max, quantity, type, minTotal })

    if (discount === price || !Boolean(discount)) return 0
    return type === PromoAmountTypes.LOYALTY_MULTIPLIER
        ? discount
        : calcFixedDiscountedPrice({ price, promoAmount: discount })
}

export const isPromoValid = (promo: PromoResponse | undefined) => {
    return (
        promo &&
        !promo.expired &&
        !(promo.unique && promo.used) &&
        !promo.nativeAppOnly &&
        promo.promoCode.length < PROMO_CHARACTERS_THRESHOLD
    )
}
