import type { Level3MenuItem } from '@/context/performers-navigation/types'

const trending: Level3MenuItem[] = [
    {
        level: 3,
        url: '/billie-eilish-tickets/performer/61742',
        label: 'Billie Eilish',
    },
    {
        level: 3,
        url: '/billy-joel-tickets/performer/86',
        label: 'Billy Joel',
    },
    {
        level: 3,
        url: '/celine-dion-tickets/performer/149',
        label: 'Celine Dion',
    },
    {
        level: 3,
        url: '/elton-john-tickets/performer/272',
        label: 'Elton John',
    },
    {
        level: 3,
        url: '/garth-brooks-tickets/performer/309',
        label: 'Garth Brooks',
    },
    {
        level: 3,
        url: '/harry-styles-tickets/performer/58299',
        label: 'Harry Styles',
    },
    {
        level: 3,
        url: '/kenny-chesney-tickets/performer/1440',
        label: 'Kenny Chesney',
    },
    {
        level: 3,
        url: '/motley-crue-tickets/performer/565',
        label: 'Mötley Crüe',
    },
    {
        level: 3,
        url: '/post-malone-tickets/performer/44059',
        label: 'Post Malone',
    },
    {
        level: 3,
        url: '/the-eagles-tickets/performer/2533',
        label: 'The Eagles',
    },
]

export default trending
