import type { Level3MenuItem } from '@/context/performers-navigation/types'

const other: Level3MenuItem[] = [
    {
        level: 3,
        url: '/concerts/alternative',
        label: 'Alternative',
    },
    {
        level: 3,
        url: '/concerts/country-folk',
        label: 'Country & Folk',
    },
    {
        level: 3,
        url: '/concerts/hard-rock',
        label: 'Hard Rock',
    },
    {
        level: 3,
        url: '/concerts/pop',
        label: 'Pop',
    },
    {
        level: 3,
        url: '/concerts/rap-hiphop',
        label: 'Rap/Hip Hop',
    },
]

export default other
