import { datadogRum } from '@datadog/browser-rum'

import { initializeRUMWithPii } from '@/utils/analytics/RUM/pii'

export const rumIntialization = async () => {
    if (process.env.NODE_ENV === 'production' && typeof window !== 'undefined') {
        const sessionSampleRate = process.env.NEXT_PUBLIC_DD_RUM_SESSION_SAMPLE_RATE || 0
        datadogRum.init({
            applicationId: process.env.NEXT_PUBLIC_DD_RUM_APPLICATION_ID || '',
            clientToken: process.env.NEXT_PUBLIC_DD_RUM_CLIENT_TOKEN || '',
            site: 'datadoghq.com',
            service: 'vivid-web-athena',
            // env: '<ENV_NAME>',
            // version: '1.0.0',
            sessionSampleRate: Number(sessionSampleRate),
            sessionReplaySampleRate: 0,
            trackUserInteractions: true,
            trackResources: true,
            trackLongTasks: true,
            defaultPrivacyLevel: 'mask-user-input',
            telemetrySampleRate: 0,
            useSecureSessionCookie: true,
            trackingConsent: 'not-granted',
            enableExperimentalFeatures: ['feature_flags'], // [OPTIONAL] Feature flags for variations and experimentation
            beforeSend: (event) => {
                event.view.name = event.view.url?.includes('/performer/') ? '/?/performer/?' : event.view.name
                event.view.name = event.view.url?.includes('/venue/') ? '/?/venue/?' : event.view.name
                return true
            },
        })

        initializeRUMWithPii()
    }
}
