import { Cookies } from 'react-cookie'

import type { AlgoliaCookie, AlgoliaQueryIdSelector, SuggestionCursorState, SuggestionSelectorType } from './types'
import { AlgoliaIndexTypes } from './types'

export const initialAlgoliaQueryIdsSelector: AlgoliaQueryIdSelector = {
    performer: '',
    venue: '',
    production: '',
}

export const ALGOLIA_COOKIE_NAME = 'algoliaQueryId'
const cookies = new Cookies()

export const getAlgoliaQueryIdCookie = (algoliaIndex: AlgoliaIndexTypes): AlgoliaCookie =>
    cookies.get(`${ALGOLIA_COOKIE_NAME}_${algoliaIndex}`)

export const updateAlgoliaQueryIdCookie = (
    algoliaIndexType: AlgoliaIndexTypes,
    queryID: string,
    indexPosition: number,
): void => {
    const payload = {
        queryID,
        positions: [indexPosition + 1],
    }
    cookies.set(`${ALGOLIA_COOKIE_NAME}_${algoliaIndexType}`, JSON.stringify(payload))
}

export const clearAlgoliaQueryIdCookie = (): void => {
    Object.keys(AlgoliaIndexTypes).forEach((algoliaIndexType) =>
        cookies.remove(`${ALGOLIA_COOKIE_NAME}_${algoliaIndexType}`),
    )
}

export function getSuggestionCursorState(selectors: SuggestionSelectorType, pos: number): SuggestionCursorState {
    const performerCount = selectors.performers.length
    const productionsCount = selectors.productions.length
    const venuesCount = selectors.venues.length
    const totalSuggestionCount = performerCount + productionsCount + venuesCount
    const resultCursorPosition = pos > totalSuggestionCount ? 0 : pos < 0 ? totalSuggestionCount : pos

    const performerAndProductionCount = performerCount + productionsCount
    // Performer -> Production -> Venue
    const performerSelectState =
        resultCursorPosition > 0 && resultCursorPosition <= performerCount ? resultCursorPosition : null
    const productionSelectState =
        resultCursorPosition > performerCount && resultCursorPosition <= performerAndProductionCount
            ? resultCursorPosition - performerCount
            : null
    const venueSelectState =
        resultCursorPosition > performerAndProductionCount && resultCursorPosition <= totalSuggestionCount
            ? resultCursorPosition - performerAndProductionCount
            : null
    const resultCursorPositionByCategory = { performerSelectState, productionSelectState, venueSelectState }

    return { resultCursorPosition, resultCursorPositionByCategory }
}
