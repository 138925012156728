import { TO_BE_DETERMINED } from '@/components/pages/production/constants'
import { CATEGORY_IDS } from '@/constants'
import { ProductionVenueInfo } from '@/context/production-details/types'
import type { Performer, Production, ProductionListSelectorType, Venue } from '@/types/app-types'
import {
    formatDateToDDD,
    formatDateToHMMA,
    formatDateWithConditionalYear,
    formatDateWithDayWithConditionalYear,
    fromLocalDateString,
    shortDateFromLocalString,
} from '@/utils/dates'

import type { FormattedProductionDates, FormattedVenueLocation } from './types'
import { RelatedProduction } from '@/context/related-productions/types'

export const formatProductionDates = (production: Production): FormattedProductionDates => {
    const dateObject = fromLocalDateString(production.localDate)
    const onsaleDateObject = production.onsaleDate ? fromLocalDateString(production.onsaleDate) : undefined
    const presale1DateObject = production.presale1Date ? fromLocalDateString(production.presale1Date) : undefined
    const presale2DateObject = production.presale2Date ? fromLocalDateString(production.presale2Date) : undefined
    const date = production.dateTbd ? 'TBD' : formatDateWithConditionalYear(dateObject)
    const shortLocalDate = shortDateFromLocalString(production.localDate)
    const day = production.dateTbd ? 'TBD' : formatDateToDDD(dateObject)
    const time = production.timeTbd ? 'TBD' : formatDateToHMMA(dateObject)
    const onsaleDate = onsaleDateObject ? onsaleDateObject.toISOString() : undefined
    const presale1Date = presale1DateObject ? presale1DateObject.toISOString() : undefined
    const presale2Date = presale2DateObject ? presale2DateObject.toISOString() : undefined
    return {
        date,
        shortLocalDate,
        day,
        time,
        onsaleDate,
        presale1Date,
        presale2Date,
        dateWithFormat: formatDateWithDayWithConditionalYear(dateObject).concat(' at ').concat(time),
    }
}

export const formatRelatedProductionDates = (production: Omit<RelatedProduction, 'formattedDate'>): FormattedProductionDates => {
    const dateObject = fromLocalDateString(production.localDate)
    const date = production.dateTbd ? 'TBD' : formatDateWithConditionalYear(dateObject)
    const shortLocalDate = shortDateFromLocalString(production.localDate)
    const day = production.dateTbd ? 'TBD' : formatDateToDDD(dateObject)
    const time = production.timeTbd ? 'TBD' : formatDateToHMMA(dateObject)
    return {
        date,
        shortLocalDate,
        day,
        time,
        dateWithFormat: formatDateWithDayWithConditionalYear(dateObject).concat(' at ').concat(time),
    }
}

export const getProductionPath = ({ webPath, organicUrl }: Production): string => webPath || organicUrl || ''

// Venue Helpers
export const formatVenueLocation = (venue: Venue): FormattedVenueLocation => {
    const location = `${venue.city}${!!venue.state ? `, ${venue.state}` : ''}${
        venue.countryCode.toUpperCase() !== 'US' ? `, ${venue.countryCode.toUpperCase()}` : ''
    }`
    return { location }
}

export const getVenueLocation = (productionVenueInfo: ProductionVenueInfo | undefined) => {
    if (!productionVenueInfo) return ''
    const ending = productionVenueInfo.venueState || productionVenueInfo.countryCode
    if (!ending) return ''
    return `${productionVenueInfo.venueCity || TO_BE_DETERMINED}, ${ending}`
}

// Performer Helpers
export const getPerformerUrl = ({ name, category, webPath, organicUrl }: Performer): string => {
    let url = ''
    const categoryId = category.id

    // legacy
    const isSportsOrganicUrlOk =
        process.env.NEXT_PUBLIC_USE_WEBPATH_SPORTS !== 'true' && categoryId === CATEGORY_IDS.SPORTS && organicUrl
    if (isSportsOrganicUrlOk) url = organicUrl

    // legacy
    const isTheaterOrganicUrlOk =
        process.env.NEXT_PUBLIC_USE_WEBPATH_THEATER !== 'true' && categoryId === CATEGORY_IDS.THEATER && organicUrl
    if (isTheaterOrganicUrlOk && !url) url = organicUrl

    // athena
    const isTheaterWebPathUrlOk =
        process.env.NEXT_PUBLIC_USE_WEBPATH_THEATER === 'true' && categoryId === CATEGORY_IDS.THEATER && webPath
    if (isTheaterWebPathUrlOk && !url) url = webPath

    // athena
    const isSportsWebPathUrlOk =
        process.env.NEXT_PUBLIC_USE_WEBPATH_SPORTS === 'true' && categoryId === CATEGORY_IDS.SPORTS && webPath
    if (isSportsWebPathUrlOk && !url) url = webPath

    // athena
    const isConcertWebPathOk = categoryId === CATEGORY_IDS.CONCERTS && webPath
    if (isConcertWebPathOk && !url) url = webPath

    // legacy
    const isParkingOrganicUrlOk = name.toLowerCase().includes('parking') && organicUrl
    if (isParkingOrganicUrlOk) url = organicUrl

    return url || organicUrl || ''
}

export const getMainPerformer = ({
    performers,
}: Pick<Production, 'performers'> | ProductionListSelectorType): Performer => {
    if (!performers) return {} as Performer

    const primaryPerformer = performers.find((performer) => !!performer.master)
    return primaryPerformer || performers[0]
}

export const getMainPerformerId = (production: Production): number => {
    return getMainPerformer(production)?.id
}

export const getMainPerformerName = (production: Production): string => {
    return getMainPerformer(production)?.name
}
