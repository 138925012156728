import type { Level3MenuItem } from '@/context/performers-navigation/types'

const musicFestivals: Level3MenuItem[] = [
    {
        level: 3,
        url: '/coachella-music-festival-tickets/performer/1796',
        label: 'Coachella Music Festival',
    },
    {
        level: 3,
        url: '/lollapalooza-tickets/performer/473',
        label: 'Lollapalooza',
    },
    {
        level: 3,
        url: '/cma-music-festival-tickets/performer/1989',
        label: 'CMA Music Festival',
    },
    {
        level: 3,
        url: '/austin-city-limits-festival-tickets/performer/4605',
        label: 'Austin City Limits',
    },
    {
        level: 3,
        url: '/cincinnati-music-festival-tickets/performer/7461',
        label: 'Cincinnati Music Festival',
    },
    {
        level: 3,
        url: '/bottlerock-festival-tickets/performer/31619',
        label: 'Bottlerock Festival',
    },
    {
        level: 3,
        url: '/burning-man-tickets/performer/6973',
        label: 'Burning Man',
    },
    {
        level: 3,
        url: '/bayou-country-superfest-tickets/performer/22455',
        label: 'Bayou Country Superfest',
    },
    {
        level: 3,
        url: '/ultra-music-festival-tickets/performer/6594',
        label: 'Ultra Music Festival',
    },
    {
        level: 3,
        url: '/stagecoach-country-music-festival-tickets/performer/12425',
        label: 'Stagecoach',
    },
]

export default musicFestivals
