import React, { $FC, createContext, useState } from 'react'

import { createSelector } from 'reselect'

import type { UserData } from '@/types/app-types'

interface ContextValue {
    state: UserData
    selectors: Selectors
    dispatch: Dispatch
}
interface Selectors {
    isFromAdWordsSelector: boolean
}
interface Dispatch {
    updatePriceGroupId: (priceGroupId: string | undefined) => void
}

interface UserDataProviderProps {
    initialProps?: UserData
}

const defaultContextValue: ContextValue = {
    state: { isAuthenticated: false, regionId: 0 },
    selectors: {
        isFromAdWordsSelector: false,
    },
    dispatch: {
        updatePriceGroupId: () => undefined,
    },
}

const context = createContext(defaultContextValue)

export const isFromAdWordsSelector = createSelector(
    (state: UserData) => state as unknown as UserData,
    (data: UserData) => {
        return data.utmMedium === 'cpc' && data.utmSource === 'google'
    },
)

export const UserDataProvider: $FC<UserDataProviderProps> = ({ children, initialProps }) => {
    const [priceGroupId, setPriceGroupId] = useState<string | undefined>(undefined)
    const state = { priceGroupId, ...initialProps }

    return (
        <context.Provider
            value={{
                state,
                selectors: {
                    isFromAdWordsSelector: isFromAdWordsSelector(state), // check if user comes from google ad
                },
                dispatch: {
                    updatePriceGroupId: setPriceGroupId,
                },
            }}
        >
            {children}
        </context.Provider>
    )
}

export default context
