import { useMediaQuery } from 'react-responsive'

// Device specific

export const useSmallMediaQuery = () => useMediaQuery({ maxWidth: 405 })

export const useMobileMediaQuery = () => useMediaQuery({ maxWidth: 767 })
export const useTabletMediaQuery = () => useMediaQuery({ minWidth: 768, maxWidth: 991 })
export const useDesktopMediaQuery = () => useMediaQuery({ minWidth: 1024 })

// Loosely covered. Add more as needed.
export const useTabletAndBelowMediaQuery = () => useMediaQuery({ maxWidth: 1023 })
