import { GetServerSidePropsContext } from 'next'

import { OPTIMIZELY_DECISION_COOKIES, OPTIMIZELY_FEATURE } from '@/optimizely/constants'
import type { OptimizelyHookResult } from '@/optimizely/types'
import { useOptimizelyFeature } from '@/optimizely/utils'
import { getCookie, getCookieFromResponseHeaders } from '@/utils/cookies'
import { OptimizelyDecisionCookieResult } from '@/utils/optimizely/utils'

export const useCognitoAuthentication = (): OptimizelyHookResult => {
    return useOptimizelyFeature(OPTIMIZELY_FEATURE.cognitoAuthentication)
}

export const getCognitoFeature = (context?: GetServerSidePropsContext): OptimizelyDecisionCookieResult => {
    const isServer = typeof window === 'undefined'
    if (!isServer) {
        return getCookie(document.cookie, OPTIMIZELY_DECISION_COOKIES.COGNITO_AUTH) as OptimizelyDecisionCookieResult
    }

    return getCookieFromResponseHeaders(
        OPTIMIZELY_DECISION_COOKIES.COGNITO_AUTH,
        context,
    ) as OptimizelyDecisionCookieResult
}
